import * as React from 'react'
import { Animated, Easing } from 'react-native';

export default function AnimationRotate(props: { rotating: boolean, children: JSX.Element }) {

    const spinValue = new Animated.Value(0);

    const spin = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg']
    })

    let loop: Animated.CompositeAnimation;
    loop = Animated.loop(Animated.timing(
        spinValue,
        {
            toValue: 1,
            duration: 1000,
            easing: Easing.linear, // Easing is an additional import from react-native
            useNativeDriver: true  // To make use of native driver for performance
        }
    ))
    loop.start();

    React.useEffect(() => {
        if (props.rotating)
            loop.start();
        else
            loop.stop();
    }, [props.rotating])

    return (
        <Animated.View style={{ transform: [{ rotate: spin }] }} >
            {props.children}
        </Animated.View>
    )

}
