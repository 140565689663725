import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Client, ConstructionLot, ConstructionSite, Destination, ErgoTask, HeaderOC, ObjectInfo } from '../../../types'
import useApi from '../../../apis/useApi'
import SelectionCard from '../../../cards/SelectionCard';
import { Button, InputDateTime, Label, LanguageContext, Note } from "@infominds/react-native-components";
import Section from "../../../components/Section";
import SelectionBox from "../../../cards/SelectionBox";
import Selection from "../../../cards/Selection";
import ErrorMessage from "../../../components/ErrorMessage";
import NewDocumentStorage from "../../../utils/NewDocumentStorage";


export default function NewHeaderOC(props: { constructionSite?: ObjectInfo, companyId?: number, task: ErgoTask, onSave: any, onSavedDocumentChanged: any, savedDocumentId: string, savedHeader: any }) {
    const lang = useContext(LanguageContext);

    const [selectedConstructionSite] = useState(props.constructionSite);

    const [errorMessage, setErrorMessage] = useState<string>();

    const [date, setDate] = useState<Date | undefined>((props.savedHeader && new Date(props.savedHeader.date)) || new Date());
    const [note, setNote] = useState<string>((props.savedHeader && props.savedHeader.note) || '');

    const [client, setClient] = useState<Client | undefined>(undefined);

    const [objectLots, setObjectLots] = useState<ConstructionLot[]>([]);
    const [objectLot, setObjectLot] = useState<ConstructionLot | undefined>((props.savedHeader && props.savedHeader.objectLot) || undefined);

    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [destination, setDestination] = useState<Destination | undefined>((props.savedHeader && props.savedHeader.destination) || undefined);

    useEffect(() => {
        // console.log('request param', props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId)
        useApi.getCompany(props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId).then((res: Client) => { if (res) setClient(res) }).catch(console.error)

        if (props.constructionSite?.object_id) {
            useApi.getOpenObjectLots(props.constructionSite?.object_id).then((res: ConstructionLot[]) => { setObjectLots(res) });
        }
        if ((props.constructionSite && props.constructionSite?.object_id && props.constructionSite?.company?.company_id !== undefined) || props.companyId !== undefined)
            useApi.getDestinations(props.constructionSite?.company?.company_id ? props.constructionSite?.company?.company_id : (props?.companyId ? props?.companyId : -1)).then((res: Destination[]) => {
                setDestinations(res);

                props.constructionSite?.destination_id && setDestination(res.find(item => {
                    return item.destination_id === props.constructionSite?.destination_id
                }))
            });
    }, [])

    //save on every change
    useEffect(() => {
        const newDoc = {
            date: props.savedDocumentId,
            docType: Number(props.task.param_list.DOCTYPE),
            header: {
                client: client,
                selectedConstructionSite: selectedConstructionSite,
                objectLot: objectLot,
                destination: destination,
                date: date,
                note: note,
            }
        }

        NewDocumentStorage.save(props.constructionSite?.object_id || props.companyId, props.task?.task_id, newDoc)
        props.onSavedDocumentChanged(newDoc)
    }, [client, selectedConstructionSite, objectLot, destination, date, note])

    function save() {
        if (client && (props.companyId === undefined ? selectedConstructionSite : true)) { // objectLot &&
            let data: HeaderOC = {
                client: client,
                object: selectedConstructionSite,
                objectLot: objectLot,
                destination: destination,
                deliveryDate: date,
                note: note,
            }
            props.onSave(data)
            setErrorMessage(undefined)
        } else {
            setErrorMessage(lang.REQUIRED_FIELDS);
        }
    }

    return (
        <View>

            {selectedConstructionSite &&
                <SelectionBox title={lang.CANTIERE}>
                    <Selection
                        // title={lang.CANTIERE}
                        icon={'hardHat'}
                        selected={selectedConstructionSite}
                        getId={(item: ConstructionSite) => item.object_id}
                        itemTitle={(item: ConstructionSite) => item.description}
                        searchString={(item: ConstructionSite) => item.description}
                    />

                    <Selection
                        title={lang.LOTTO}
                        icon={'pencilRuler'}
                        hidden={objectLots?.length === 0}
                        selected={objectLot}
                        value={objectLots}
                        getId={(item: ConstructionLot) => item.object_lot_id}
                        itemTitle={(item: ConstructionLot) => item.description}
                        searchString={(item: ConstructionLot) => item.description}
                        onChange={(item: ConstructionLot) => setObjectLot(item)}
                    />


                </SelectionBox>}
            {/* <SelectionCard
                title={lang.CANTIERE}
                icon={faHardHat}
                selected={selectedConstructionSite}
                itemTitle={(item: ConstructionSite) => item.description}
                searchString={(item: ConstructionSite) => item.description}
            /> */}

            {/* <SelectionCard
                title={lang.CUSTOMER}
                icon={faBuilding}
                selected={client}
                itemTitle={(item: Client) => item.description}
                searchString={(item: Client) => item.description}
            /> */}


            <Section>
                <SelectionCard
                    title={lang.CUSTOMER}
                    icon={'building'}
                    selected={client}
                    getId={(item: Client) => item.id}
                    itemTitle={(item: Client) => item.description}
                    searchString={(item: Client) => item.description}
                />

                <SelectionCard
                    title={lang.DESTINATIONS}
                    icon={'mapMarker'}
                    selected={destination}
                    value={destinations}
                    hidden={(props.constructionSite && props.constructionSite.destination_id === undefined) || destinations.length === 0}
                    disabled={props.constructionSite?.destination_id !== undefined}
                    getId={(item: Destination) => item.destination_id}
                    itemTitle={(item: Destination) => item.description}
                    searchString={(item: Destination) => item.description}
                    onChange={(item: Destination) => setDestination(item)}
                />
            </Section>

            <View>
                <Label text={lang.DUE_DATE} />
                <InputDateTime
                    value={date}
                    onDelete={() => setDate(undefined)}
                    onConfirm={date => { setDate(date); }}
                    text={''}
                    mode='date' />
            </View>


            <View>
                <Label text={lang.NOTE} />
                <Note value={note} onChangeText={(text: string) => setNote(text)} />
            </View>

            <ErrorMessage errorMessage={errorMessage} />

            <Button style={{ marginTop: 20 }} title={lang.NEXT} onPress={() => { save() }} />

        </View>
    )
}