import AsyncStorage from '@react-native-community/async-storage';
import { useState } from 'react';
import { Serialnumber } from '../types';
import useApi from './useApi';
import useArticlesApi from './useArticlesApi';

const objectsEqual = (o1: any, o2: any) =>
    Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => o1[p] === o2[p]);

function arraysEqual(a: any, b: any) { return !!a && !!b && !(a < b || b < a); }

function PersistenceApiUtils(apiCall: Promise<any>, storageKey: string, callback: any, reject: any, forceOnline: boolean = false) {
    AsyncStorage.getItem(storageKey)
        .then((res: any) => {
            if (res && !forceOnline) {
                callback(JSON.parse(res));
                console.debug('CACHE:', storageKey, 'from local');
            }
            return JSON.parse(res);
        })
        .then((cacheRes: any) => {
            console.debug(apiCall)
            apiCall
                .then((apiRes: any) => {
                    var isEquals = false;

                    if (!forceOnline)
                        if (Array.isArray(cacheRes)) {
                            if (cacheRes != null && cacheRes != [] && arraysEqual(cacheRes, apiRes)) {
                                isEquals = true;
                            }
                        } else {
                            if (cacheRes != null && cacheRes != {} && objectsEqual(cacheRes, apiRes)) {
                                isEquals = true;
                            }
                        }


                    if (isEquals) {
                        console.debug('CACHE:', storageKey, 'local and remote are the same')
                    } else {
                        callback(apiRes);
                        AsyncStorage.setItem(storageKey, JSON.stringify(apiRes)).then(() => {
                            console.debug('CACHE:', storageKey, ' get online and save local');
                        });
                    }
                })
                .catch(reject);
        })
        .catch((error: any) => {
            console.error('CACHE:', storageKey, error)
            apiCall.then((apiRes: any) => { callback(apiRes); });
        })
}

const usePersistenceApi = {

    getSelectionList(callback: any, reject: any, forceOnline: boolean = false) {
        PersistenceApiUtils(useApi.getSelectionList(), 'getSelectionList', callback, reject, forceOnline);
    },

    // getAllArticles(callback: any, reject: any, forceOnline: boolean = false) {
    //     PersistenceApiUtils(useArticlesApi.searchArticleFullText(''), 'getAllArticlesFullText', callback, reject, forceOnline);
    //     //useArticlesApi.searchArticleFullText('').then(callback).catch(reject)
    // },

    getSerialnumbers(callback: (res: Serialnumber[]) => void, reject: any) {
        PersistenceApiUtils(useApi.getSerialnumbers(), 'getSerialnumbers', callback, reject, false);
    },

    removeAll() {
        AsyncStorage.multiRemove(['getSelectionList', 'getAllArticlesFullText', 'getSerialnumbers'])
    }
}

// TODO use hooks
// export let usePersistenceApi2 = {

//     getSelectionList() {
//         // const [data, setData] = useState([]);
//         // const [isLoading, setIsLoading] = useState(true);

//         // function finishLoading(res: any) {
//         //     setIsLoading(false)
//         //     setData(res);
//         // }

//         // function onError(error: any) {

//         // }

//         // PersistenceApiUtils(useApi.getSelectionList(), 'getSelectionList', finishLoading, onError, false);

//         // return [data, isLoading]
//     },

//     getAllArticles(callback: any, reject: any, forceOnline: boolean = false) {
//         PersistenceApiUtils(useArticlesApi.searchArticleFullText(''), 'getAllArticlesFullText', callback, reject, forceOnline);
//     },

//     getSerialnumbers(callback: (res: Serialnumber[]) => void, reject: any) {
//         PersistenceApiUtils(useApi.getSerialnumbers(), 'getSerialnumbers', callback, reject, false);
//     }
// }

export default usePersistenceApi;
