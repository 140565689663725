import * as React from 'react'
import { Colors, View, Text, CardBasic, ThemeContext } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';
import IMIcon from '../components/IMIcon';
import { IMIconName } from '../components/IMIconNames';

export default function SelectConstructionSiteItem(props: {
    title: string,
    description?: string,
    description2?: string,
    id?: string,
    icon: IMIconName
    style?: any,
    color?: string,
    onPress?: () => void,
    count?: number,
    isSelected?: boolean
}) {

    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <CardBasic style={[{
            flex: 1,
            backgroundColor: props.color,
            padding: 0,
            margin: 0,
            elevation: 3,
        },
        props.isSelected && { borderColor: Colors.grey, borderWidth: 2 }
        ]}>
            <TouchableOpacity
                style={[{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: props.color,
                    borderRadius: 10,
                }, props.style]}
                onPress={() => { if (props.onPress) props.onPress(); }} >
                <View style={{
                    backgroundColor: Colors.grey,
                    marginRight: 10,
                    borderRadius: 10,
                    margin: 10,
                    width: 60,
                    height: 60,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <IMIcon
                        icon={props.icon}
                        size={40}
                        color={Colors.white} />
                </View>
                <View style={{ flex: 1, padding: 12 }}>
                    {(props.id && props.title) ? <Text style={{ flexWrap: 'wrap' }}>({props.id}) - {props.title}</Text> : <></>}
                    {((props.id === undefined || props.id === '') && props.title) ? <Text style={{ flexWrap: 'wrap' }}>{props.title}</Text>: <></>}
                    <View style={{ flexDirection: 'row' }}>
                        {!!props.description && <Text style={{ marginBottom: 0, paddingBottom: 0, flexWrap: 'wrap', color: theme.textDetail }}>{props.description}</Text>}
                        {!!props.description2 && <Text style={{ marginBottom: 0, paddingBottom: 0, flexWrap: 'wrap', color: theme.textDetail }}>{props.description2}</Text>}
                    </View>

                </View>

                {(props.count !== undefined && props.count !== 0) &&
                    <View style={{ backgroundColor: Colors.grey, borderRadius: 30, marginRight: 8, margin: 5, padding: 5 }}>
                        <Text style={{ color: Colors.white, margin: 0, paddingTop: 0, paddingBottom: 0, padding: 4, minWidth: 16 }}>{props.count}</Text>
                    </View>
                }
            </TouchableOpacity>
        </CardBasic >
    )
}
