import AsyncStorage from "@react-native-community/async-storage";
import useApi from "../apis/useApi";
import { DocumentCreateResult, DocumentCreateResultItem, DocumentPost, ErgoTask } from '../types'

const KEY = 'CreateDocumentStorage';

type CreatedDocument = {
    id: number,
    createDocumentResult: string;
    taskId: number;
    documentRequest: DocumentPost;
    ediInfo?: DocumentCreateResultItem;
}

type StorageDataType = {
    taskId: number;
    documents: CreatedDocument[];
}

export type { StorageDataType, CreatedDocument }

const removeAllImportedDocuments = async () => {
    let stringJson = await AsyncStorage.getItem(KEY)
    if (stringJson) {
        let data: StorageDataType[] = JSON.parse(stringJson);

        data.forEach((item: StorageDataType) => {
            item.documents = item.documents.filter((element: CreatedDocument) => element.ediInfo?.document_status === 'Imported')
        })
    }
}

export default {
    // delete all items with a empty result key, thoese once have not been created
    clean: async () => {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: StorageDataType[] = JSON.parse(stringJson);
            data.forEach((item: StorageDataType) => {
                item.documents = item.documents.filter((item: CreatedDocument) => {
                    return item.createDocumentResult !== ''
                })
            });
            AsyncStorage.setItem(KEY, JSON.stringify(data))
        }
    },

    remove: async (createdDocumentResult: string) => {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: StorageDataType[] = JSON.parse(stringJson);
            data.forEach((storedData: StorageDataType) => {
                let index = storedData.documents.findIndex((item: CreatedDocument) => {
                    return item.createDocumentResult === createdDocumentResult
                })
                if (index !== -1) {
                    storedData.documents.splice(index, 1)
                }
            });
            return AsyncStorage.setItem(KEY, JSON.stringify(data))
        }
        return
    },

    save: async (taskId: number, createDocumentResult: string, documentRequest: DocumentPost, id: number) => {
        console.debug('created doc storage', createDocumentResult)
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: StorageDataType[] = JSON.parse(stringJson);

            let item: StorageDataType | undefined = data.find((storage: StorageDataType) => {
                return storage.taskId === taskId;
            })

            // remove media from reqest
            documentRequest.position.forEach((postion) => {
                postion.media_list = [];
            })
            documentRequest.signature = ''

            if (item) {
                item.documents.push({ id: id, createDocumentResult: createDocumentResult, documentRequest: documentRequest, taskId: taskId })
            } else {
                let newData: StorageDataType = { taskId: taskId, documents: [{ id: id, createDocumentResult: createDocumentResult, documentRequest: documentRequest, taskId: taskId }] }
                data.push(newData)
            }
            return AsyncStorage.setItem(KEY, JSON.stringify(data))
        } else {
            let data: StorageDataType[] = [];

            let newData: StorageDataType = { taskId: taskId, documents: [{ id: id, createDocumentResult: createDocumentResult, documentRequest: documentRequest, taskId: taskId }] }
            data.push(newData)
            return AsyncStorage.setItem(KEY, JSON.stringify(data))
        }
    },

    verifyAll: async (tasks: ErgoTask[], object_id?: number, customer_id?: number): Promise<CreatedDocument[] | undefined> => {

        console.log(tasks, object_id, customer_id)

        let stringJson = await AsyncStorage.getItem(KEY)
        removeAllImportedDocuments();

        if (stringJson) {
            let data: StorageDataType[] = JSON.parse(stringJson);

            // get documents by taskIDs
            let taskIds: number[] = [];
            taskIds = tasks.map((task: ErgoTask) => task.task_id)

            let documentsByTask: StorageDataType[] = data.filter((storage: StorageDataType) => {
                return taskIds.includes(storage.taskId);
            })

            // get documents filterd by objectID
            if (documentsByTask.length > 0) {
                let documents: CreatedDocument[] = [];
                documentsByTask.forEach((item: StorageDataType) => {
                    let filterResult = [];
                    if (object_id)
                        filterResult = item.documents.filter((element: CreatedDocument) => element.id === object_id)
                    else
                        filterResult = item.documents.filter((element: CreatedDocument) => element.id === customer_id)
                    documents = [...documents, ...filterResult];
                })

                if (documents.length === 0) {
                    return undefined;
                }

                let requestIds: string[] = [];
                documents.forEach((cd: CreatedDocument) => {
                    requestIds.push(cd.createDocumentResult)
                })
                if (requestIds && requestIds.length > 0) {
                    let documentCreateResult: DocumentCreateResult = await useApi.verifyDocuments({ document_keys: requestIds })
                    documents.forEach((element: CreatedDocument) => {
                        const resID = element.createDocumentResult;
                        element.ediInfo = documentCreateResult[resID];
                    })

                    // remove correct imported files
                    documents = documents.filter((element: CreatedDocument) => {
                        return element.ediInfo?.document_status !== 'Imported'
                    })

                    // TODO remove all documents from the AsyncStorage that have been imported

                    // console.log(JSON.stringify(documentCreateResult))
                    // .catch(console.error)
                }
                return documents
            }
        }
        return undefined;
    },

    removeAll: () => {
        AsyncStorage.removeItem(KEY);
    }
}