import * as React from 'react'
import { Icon } from '@infominds/react-native-components'
import { IMIconName } from './IMIconNames'


function getIcon(name: IMIconName): string {
    if (name === 'plus') return 'plus';
    else if (name === 'minus') return 'minus';
    else if (name === 'user') return 'user';
    else if (name === 'play') return 'play';
    else if (name === 'pause') return 'pause';
    else if (name === 'fileAudio') return 'file';
    else if (name === 'fileEdit') return 'file';
    else if (name === 'fileImport') return 'file';
    else if (name === 'trash') return 'trash';
    else if (name === 'exclamationTriangle') return 'alert-triangle';
    else if (name === 'chevronDown') return 'chevron-down';
    else if (name === 'chevronUp') return 'chevron-up';
    else if (name === 'ellipsisV') return 'more-vertical';
    else if (name === 'barcodeRead') return 'camera';
    else if (name === 'cog') return 'settings';
    else if (name === 'infoCircle') return 'info';
    else if (name === 'warehouse') return 'home';
    else if (name === 'check') return 'check';
    else if (name === 'times') return 'x';
    else if (name === 'sync') return 'refresh-cw';
    else if (name === 'addressBook') return 'book';
    else if (name === 'archive') return 'archive';
    else if (name === 'homeLg') return 'home';
    else if (name === 'building') return 'globe';
    else if (name === 'hardHat') return 'briefcase';
    else if (name === 'search') return 'search';
    else if (name === 'shoppingCart') return 'shopping-cart';
    else if (name === 'truck') return 'truck';
    else if (name === 'truckLoading') return 'truck';
    else if (name === 'fileInvoice') return 'file';
    else if (name === 'map') return 'map';
    else if (name === 'phone') return 'phone';
    else if (name === 'at') return 'at-sign';
    else if (name === 'calendar') return 'calendar';
    else if (name === 'pencilRuler') return 'edit-2';
    else if (name === 'mapMarker') return 'map-pin';
    else if (name === 'truckContainer') return 'truck';
    else if (name === 'shippingFast') return 'truck';
    else if (name === 'filter') return 'filter';
    else if (name === 'logout') return 'log-out';
    else return 'info';
}

export default function IMIcon(props: {
    icon: IMIconName
    style?: any,
    size?: any,
    color?: string
}) {
    return (
        <Icon family={'Feather'} color={props.color} size={props.size} name={getIcon(props.icon)} style={props.style} />
    )
}
