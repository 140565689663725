import AsyncStorage from "@react-native-community/async-storage";
import { Platform } from "react-native";
import MediaUtils from "../components/media/MediaUtils";
import { StoredNewDocument, StoredNewDocumentPosition, StoredNewDocumentPositionStore } from '../types'

const KEY = 'CreateNewDocumentStorage';
const KEY_POSITION = 'CreateNewDocumentStoragePOSITION';

type NewDocumentItem = {
    objectId: number;
    taskId: number;
    documents: StoredNewDocument[];
}
type CountItems = {
}
type CountItem = {
    objectId: number;
    count: number;
}

export type { NewDocumentItem, CountItem, CountItems }

const print = async () => {
    // let stringJson = await AsyncStorage.getItem(KEY)
    // console.log('NewDocumentItem', stringJson)
}

async function isSaveInMediaSettingsAktive() {
    //  check if save to media aktiv in settings
    let saveImagesInMedia = true;
    let saveImagesInMediaResult = await AsyncStorage.getItem('saveImagesInMedia')
    if (saveImagesInMediaResult) {
        saveImagesInMedia = saveImagesInMediaResult === "true"
    }
    return saveImagesInMedia;
}

const saveMediaToPhoneAlbum = async (position?: StoredNewDocumentPosition) => {
    if (await isSaveInMediaSettingsAktive()) {
        // if has media save to phone not in db and load after from it still present on phone, base64 will not be saved
        if (position === undefined)
            return undefined

        for (let i = 0; i < position?.media.length; i++) {
            let item = position?.media[i];

            for (let j = 0; j < item.media.length; j++) {
                // copy to make the object mutable
                let element = item.media[j]
                if (Platform.OS === 'ios')
                    element = JSON.parse(JSON.stringify(item.media[j]));

                const uri = (element.uri) as string;
                if (uri && uri !== '' && element.type !== 'audio') {
                    // console.log('uri', uri)
                    // console.log('galleryUri', element.galleryUri)
                    if (uri.startsWith('content://media') || uri.startsWith('ph://') || element.galleryUri !== undefined) {
                        // ignore
                    } else {
                        // console.log('currentUri', element)
                        let newUri = await MediaUtils.saveToPhoneMedia(element.uri, "ErgoMobileWork")
                        // console.log('newUri', newUri)
                        element.galleryUri = newUri;
                    }
                }
            }
        }
    }
    return position

}

const removePosition = async (docId: string) => {
    if (docId) {
        let stringJson = await AsyncStorage.getItem(KEY_POSITION)
        // console.log(stringJson)
        if (stringJson) {
            let data: StoredNewDocumentPositionStore[] = JSON.parse(stringJson);
            if (data === undefined)
                data = [];

            let foundDocumentIndex: number | undefined = data.findIndex((storage: StoredNewDocumentPositionStore) => {
                return storage.date === docId;
            })

            if (foundDocumentIndex !== -1) {

                // TODO
                try {
                    // delete media after successfull send
                    if (await isSaveInMediaSettingsAktive()) {
                        data[foundDocumentIndex].position.media.forEach((media) => {
                            MediaUtils.removePhoneMedia(media.media.uri)
                        })
                    }
                } catch (error) {
                    console.error('error deleting files from media', error)
                }
                data.splice(foundDocumentIndex, 1)
                return await AsyncStorage.setItem(KEY_POSITION, JSON.stringify(data))
            }
        } else {
            return
        }
    }
}

const loadPosition = async (docId?: string): Promise<StoredNewDocumentPosition | undefined> => {

    if (docId) {
        let stringJson = await AsyncStorage.getItem(KEY_POSITION)
        if (stringJson) {
            let data: StoredNewDocumentPositionStore[] = JSON.parse(stringJson);
            if (data === undefined)
                data = [];

            let itemIndex: number | undefined = data.findIndex((storage: StoredNewDocumentPositionStore) => {
                return storage.date === docId;
            })
            // console.log('load docId', docId)

            // TODO media should be saved in gallery and only path to this file will be saved in asyncStorage.
            // https://github.com/react-native-cameraroll/react-native-cameraroll#save
            // gradle.properties have a temporary fix for large files

            if (itemIndex !== -1) {

                // MediaUtils.readPhoneMedia('')
                //     // load images from phone media into base64 if they still exist
                //     try {
                //         data[itemIndex].position.media.forEach(async (element: any) => {
                //             console.log('element', element)
                //             if (element.base64 === undefined || element.base64 === '')
                //                 if (Platform.OS !== 'web') {
                //                     element.base64 = await MediaUtils.readPhoneMedia(element.uri)
                //                 }
                //         })
                //     } catch (error) {
                //         console.error(error)
                //     }

                return data[itemIndex].position
            } else {
                return undefined
            }
        } else {
            return undefined
        }
    }
}

const checkIfAtleastOnePosition = async (docid?: string) => {
    let positions = await loadPosition(docid)
    // console.log('----', positions?.material !== undefined && positions?.material.length > 0)
    if ((positions?.media !== undefined && positions?.media.length > 0) || (positions?.material !== undefined && positions?.material.length > 0))
        return true
    else
        return false
}

const remove = async (objectId?: number, taskId?: number, document?: StoredNewDocument) => {
    if (objectId && taskId && document) {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: NewDocumentItem[] = JSON.parse(stringJson);

            let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                return storage.objectId === objectId && storage.taskId === taskId;
            })

            if (item) {
                let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === document.date.toString())
                // console.log('foundDocumentIndex', foundDocumentIndex)
                if (foundDocumentIndex !== -1) {
                    // console.log('remoceposition')
                    removePosition(document.date)

                    item.documents.splice(foundDocumentIndex, 1)
                    return await AsyncStorage.setItem(KEY, JSON.stringify(data))
                }
            }
        }
    }
}
const removeByDocId = async (objectId: number, taskId: number, documentId: string) => {
    console.debug(objectId, taskId, documentId)
    if (objectId && taskId && documentId) {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: NewDocumentItem[] = JSON.parse(stringJson);

            let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                return storage.objectId === objectId && storage.taskId === taskId;
            })

            if (item) {
                let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === documentId)
                // console.log('foundDocumentIndex', foundDocumentIndex)
                if (foundDocumentIndex !== -1) {
                    // console.log('remoceposition')
                    removePosition(documentId)

                    item.documents.splice(foundDocumentIndex, 1)
                    return await AsyncStorage.setItem(KEY, JSON.stringify(data))
                }
            }
        }
    }
}

export default {

    remove: async (objectId?: number, taskId?: number, document?: StoredNewDocument) => {
        return remove(objectId, taskId, document)
    },

    removeIfNoPosition: async (objectId?: number, taskId?: number, documentId?: string) => {
        let hasAtLeaseOnePisition = await checkIfAtleastOnePosition(documentId)
        // console.log('remove document because has no position ', !hasAtLeaseOnePisition)
        if (!hasAtLeaseOnePisition && objectId && taskId && documentId)
            return removeByDocId(objectId, taskId, documentId)
    },

    save: async (objectId?: number, taskId?: number, document?: StoredNewDocument) => {

        // AC: save only when at least one position is set
        // var hasAtLeastOnePossition = await checkIfAtleastOnePosition(document?.date)

        if (objectId && taskId && document) {

            let stringJson = await AsyncStorage.getItem(KEY)
            if (stringJson) {
                let data: NewDocumentItem[] = JSON.parse(stringJson);

                let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                    return storage.objectId === objectId && storage.taskId === taskId;
                })

                if (item) {
                    let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === document.date.toString())
                    if (foundDocumentIndex !== -1) {
                        item.documents[foundDocumentIndex].date = document.date;
                        item.documents[foundDocumentIndex].docType = document.docType;
                        item.documents[foundDocumentIndex].header = document.header;
                        // item.documents[foundDocumentIndex].positions = document.positions;
                    } else
                        item.documents.push(document)
                } else {
                    let newData: NewDocumentItem = {
                        objectId: objectId,
                        taskId: taskId,
                        documents: [document]
                    }
                    data.push(newData)
                }
                // print();
                // console.log("🚀 ~ file: NewDocumentStorage.tsx ~ line 153 ~ save: ~ JSON.stringify(data)", JSON.stringify(data))
                return await AsyncStorage.setItem(KEY, JSON.stringify(data))
            } else {
                let data: NewDocumentItem[] = [];
                let newData: NewDocumentItem = { objectId: objectId, documents: [document], taskId: taskId }
                data.push(newData)
                // print();
                // console.log("🚀 ~ file: NewDocumentStorage.tsx ~ line 160 ~ save: ~ JSON.stringify(data)", JSON.stringify(data))
                return await AsyncStorage.setItem(KEY, JSON.stringify(data))

            }
        }
    },

    savePosition: async (objectId?: number, taskId?: number, document?: StoredNewDocument) => {
        if (objectId && taskId && document) {
            // document.positions = await saveMediaToPhoneAlbum(document.positions)
            // await saveMediaToPhoneAlbum(document.positions)

            let stringJson = await AsyncStorage.getItem(KEY)
            if (stringJson) {
                let data: NewDocumentItem[] = JSON.parse(stringJson);

                let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                    return storage.objectId === objectId && storage.taskId === taskId;
                })

                if (item) {
                    let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === document.date.toString())
                    if (foundDocumentIndex !== -1) {
                        item.documents[foundDocumentIndex].positions = document.positions;
                    } else {
                        item.documents.push(document)
                    }
                } else {
                    let newData: NewDocumentItem = {
                        objectId: objectId,
                        taskId: taskId,
                        documents: [document]
                    }
                    data.push(newData)
                }
                // print();
                // console.log("🚀 ~ file: NewDocumentStorage.tsx ~ line 240 ~ savePosition: ~ JSON.stringify(data)", JSON.stringify(data))
                // console.log('data', JSON.stringify(data));
                return await AsyncStorage.setItem(KEY, JSON.stringify(data))

            } else {
                let data: NewDocumentItem[] = [];
                let newData: NewDocumentItem = { objectId: objectId, documents: [document], taskId: taskId }
                data.push(newData)
                // print();
                // console.log("🚀 ~ file: NewDocumentStorage.tsx ~ line 248 ~ savePosition: ~ JSON.stringify(data)", JSON.stringify(data))
                return await AsyncStorage.setItem(KEY, JSON.stringify(data))

            }
        }
    },

    savePositionNew: async (docId?: string, position?: StoredNewDocumentPosition) => {
        if (docId && position) {
            let stringJson = await AsyncStorage.getItem(KEY_POSITION)

            await saveMediaToPhoneAlbum(position)

            if (stringJson) {
                let data: StoredNewDocumentPositionStore[] = JSON.parse(stringJson);
                if (data === undefined)
                    data = [];

                let itemIndex: number | undefined = data.findIndex((storage: StoredNewDocumentPositionStore) => {
                    return storage.date === docId;
                })

                if (itemIndex !== -1) {
                    data[itemIndex].position = position
                } else {
                    data.push({ date: docId, position: position })
                }
                print();
                return await AsyncStorage.setItem(KEY_POSITION, JSON.stringify(data))
            } else {
                let data: StoredNewDocumentPositionStore[] = [];
                data.push({ date: docId, position: position })
                print();
                return await AsyncStorage.setItem(KEY_POSITION, JSON.stringify(data))
            }
        }
    },

    loadPossition: async (docId?: string): Promise<StoredNewDocumentPosition | undefined> => {
        return loadPosition(docId)
    },


    removePossition: async (docId: string) => {
        removePosition(docId)
    },


    load: async (objectId: number, taskId?: number): Promise<NewDocumentItem[]> => {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: NewDocumentItem[] = JSON.parse(stringJson);

            let itmes: NewDocumentItem[] | undefined = data.filter((storage: NewDocumentItem) => {
                return storage.objectId === objectId &&
                    (taskId ? storage.taskId === taskId : true);
            })
            if (itmes) {
                return itmes;
            }
        }
        return [];
    },

    countOpenItems: async (): Promise<CountItems> => {
        let stringJson = await AsyncStorage.getItem(KEY)
        if (stringJson) {
            let data: NewDocumentItem[] = JSON.parse(stringJson);
            let itmes: any = {}
            data.forEach((storage: NewDocumentItem) => {
                storage.documents.forEach(item => {
                    // count also customers not
                    if (item.header && item.header.client && item.header.client.id) {
                        if (!itmes[item.header.client.id])
                            itmes[item.header.client.id] = 0
                        itmes[item.header.client.id]++;
                    }
                    else if (item.header && item.header.companyIdForCount) {
                        if (!itmes[item.header.companyIdForCount])
                            itmes[item.header.companyIdForCount] = 0
                        itmes[item.header.companyIdForCount]++;
                    }

                    // save object count separat with object as prefix 
                    if (!itmes['object' + storage.objectId] && storage.documents.length > 0) {
                        itmes['object' + storage.objectId] = 0
                    }

                    itmes['object' + storage.objectId]++;

                })


            })
            // console.log('countOpenItems', itmes)
            return itmes;
        }
        return [];
    },


    loadById: async (objectId: number, taskId?: number, id?: string): Promise<StoredNewDocument | undefined> => {
        // await save(true, objectId, taskId, document)
        if (objectId && taskId) {
            let stringJson = await AsyncStorage.getItem(KEY)
            if (stringJson) {
                let data: NewDocumentItem[] = JSON.parse(stringJson);

                let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                    return storage.objectId === objectId && storage.taskId === taskId;
                })

                if (item) {
                    let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === id)
                    if (foundDocumentIndex !== -1) {
                        return item.documents[foundDocumentIndex]
                    }
                }
                return undefined
            } else {
                return undefined
            }
        }
    },


    setErrorState: async (objectId?: number, taskId?: number, document?: StoredNewDocument, error?: string) => {
        // console.log(objectId, taskId, document)
        if (objectId && taskId && document) {
            let stringJson = await AsyncStorage.getItem(KEY)
            if (stringJson) {
                let data: NewDocumentItem[] = JSON.parse(stringJson);

                let item: NewDocumentItem | undefined = data.find((storage: NewDocumentItem) => {
                    return storage.objectId === objectId && storage.taskId === taskId;
                })

                if (item) {
                    let foundDocumentIndex = item.documents.findIndex((e: StoredNewDocument) => e.date.toString() === document.date.toString())
                    // console.log('foundDocumentIndex', foundDocumentIndex)
                    if (foundDocumentIndex !== -1) {
                        // console.log('remoceposition')
                        item.documents[foundDocumentIndex].error = error ? error : 'Error'
                    }
                }
            }
        }
    },


    removeAll: () => {
        AsyncStorage.removeItem(KEY);
    }
}