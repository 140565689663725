import { Colors, Layout, ThemeContext } from '@infominds/react-native-components';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import Storage from '../hooks/useAsyncStorage';
import IMIcon from './IMIcon';

const MasterDetail = ({ master, detail, positionKey }: { master: JSX.Element, detail: JSX.Element, positionKey: string | undefined }) => {

    const colorScheme = useContext(ThemeContext);
    const borderColor = colorScheme === 'light' ? Colors.light.background : '#424242';
    const theme = Colors[colorScheme];

    const [masterWidth, setMasterWidth] = useState(400);
    const [splitterSelected, setSplitterSelected] = useState(false);

    // Storage('position:' + positionKey).remove();

    useEffect(() => {
        if (positionKey) Storage('position:' + positionKey).load().then((res: string) => {
            if (res !== null)
                setMasterWidth(Number(res))
        })
    }, [])

    return (
        <View style={Platform.OS === 'web' ? { flex: 1 } : { height: '100%' }}>
            {Layout.isSmallDevice ?
                <View style={{ flex: 1 }}>{master}</View>
                :
                <View style={styles.root}>
                    <View style={[styles.masterView, { width: masterWidth }]}>{master}</View>
                    <View
                        style={[{
                            width: 15,
                            backgroundColor: splitterSelected ? Colors.grey : theme.background,
                            justifyContent: 'flex-end',
                            paddingBottom: 5
                        }, Platform.OS !== 'web' && { width: 15 }]}
                        onStartShouldSetResponder={(event) => true}
                        onResponderStart={() => setSplitterSelected(true)}
                        onResponderEnd={() => setSplitterSelected(false)}
                        onMoveShouldSetResponder={(event) => true}
                        onResponderMove={(e) => {
                            setMasterWidth(e.nativeEvent.pageX)
                            if (positionKey) Storage('position:' + positionKey).save(e.nativeEvent.pageX)
                        }}
                    >
                        <IMIcon size={20} color={theme.textPlaceholder} icon={'ellipsisV'} />
                    </View>
                    <View style={[styles.detailView, { borderLeftColor: borderColor }]}>{detail}</View>
                </View>
            }
        </View>
    )
};

const styles = StyleSheet.create({
    root: { flex: 1, flexDirection: 'row', maxHeight: '100%' },
    masterView: { minWidth: 250, maxWidth: 600, borderWidth: 0 },
    detailView: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        borderLeftWidth: 1
    },
});
export default MasterDetail;
