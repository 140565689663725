import React, { useContext, useEffect, useRef, useState } from 'react';
import { NativeSyntheticEvent, ScrollView, StyleSheet, TextInputProps, TextInputSubmitEditingEventData } from 'react-native';
import { Colors } from '../constants/Colors';
import { TextInput } from 'react-native';
import { Icon, Layout, ThemeContext, View } from '@infominds/react-native-components';
import { debounce } from "lodash";

type DebouncedProps = {
    debounce?: number
};

export type SearchBoxDebounced = DebouncedProps & TextInputProps;

export default function SearchBoxDebounced(props: SearchBoxDebounced) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, value, ...otherProps } = props;

    const [searchText, setSearchText] = useState(value);

    // const refinput = useRef();

    const onSearchChangeText = (text: string) => {
        if (props.onChangeText)
            props.onChangeText(text)
    }

    useEffect(() => {
        setSearchText(props.value)
    }, [props.value])

    const onChangeTextDelayed = React.useCallback(debounce(onSearchChangeText, props.debounce ? props.debounce : 100000), []);  // number is 1,6 minutes so hight because otherwise the keyboard closes imidiatly

    return (
        <View style={[styles.searchSection, Layout.defaultComponentsStyles,
        {
            backgroundColor: theme.inputBackground,
            borderColor: theme.inputBorder,
        }, style]}>
            <TextInput
                style={[styles.input, { color: theme.text }]}
                placeholder="Search..."
                // onChangeText={(searchString) => { this.setState({ searchString }) }}
                underlineColorAndroid="transparent"
                placeholderTextColor={theme.textPlaceholder}

                value={searchText}
                {...otherProps}
                onSubmitEditing={(e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
                    const text = e.nativeEvent.text;
                    setSearchText(text)
                    onSearchChangeText(text)
                }}
                onChangeText={(text: string) => {
                    setSearchText(text)
                    onChangeTextDelayed(text)
                }}

            />
            {(props.value && props.value !== '') ?
                <Icon style={styles.searchIcon} name="x" size={20} color={theme.textDetail}
                    onPress={() => { props.onChangeText && props.onChangeText('') }} />
                :
                <Icon style={styles.searchIcon} name="search" size={20} color={theme.textDetail} />
            }
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        padding: 12
    },
    searchSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 8,
        paddingRight: 5
    },
    searchIcon: {
        padding: 5,
    },

});
