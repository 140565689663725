import React, { useContext } from "react";
import { Platform, ScrollView, TouchableOpacity } from "react-native";
import { Icon, ModalBottom, Title, View, LanguageContext, Colors, ThemeContext, Layout } from '@infominds/react-native-components';
import Settings from "../screens/SettingsScreen"
import AsyncStorage from '@react-native-community/async-storage';
import LicenseGlobals from "@infominds/react-native-license";
import Storage from '../hooks/useAsyncStorage';
import usePersistenceApi from '../apis/usePeristenceApi';
import IMIcon from "../components/IMIcon";

export default function SettingsModal(props: { navigation: any, onSettingsChange: () => void, isVisible: boolean, close: () => void }) {
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const lang = useContext(LanguageContext);

    const onLogoutClick = () => {
        AsyncStorage.removeItem('selectedCompany');
        AsyncStorage.removeItem('mandantId');
        AsyncStorage.removeItem('username');
        AsyncStorage.removeItem('password');

        AsyncStorage.removeItem('saveImagesInMedia');
        AsyncStorage.removeItem('CreateNewDocumentStorage');
        AsyncStorage.removeItem('CreateNewDocumentStoragePOSITION');

        Storage('favorites').remove();
        Storage('lastUsed').remove();

        LicenseGlobals.license = ''
        LicenseGlobals.username = ''

        usePersistenceApi.removeAll();

        props.navigation.replace('Login')
    }

    return (
        <ModalBottom
            isVisible={props.isVisible}
            close={props.close}
            backdropOpacity={0.8}
            keyboardAvoiding
            propagateSwipe
            swipeDirection={[]}
            style={[{
                height: 'auto',
                maxHeight: '90%',
                width: Layout.isSmallDevice ? '96%' : '70%',
                backgroundColor: Colors.grey,
                padding: 0,

            }, Platform.OS !== 'web' && { marginLeft: Layout.isSmallDevice ? '2%' : '15%', }]}
            content={
                <View style={[{ height: 'auto' }, colorScheme === 'dark' && { borderColor: Colors.grey, borderWidth: 1, borderBottomWidth: 0, borderTopLeftRadius: 22, borderTopRightRadius: 22 }]}>
                    <View style={{ padding: 20, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderTopLeftRadius: 20, borderTopRightRadius: 20, backgroundColor: Colors.grey }}>
                        <TouchableOpacity onPress={onLogoutClick}>

                            <IMIcon icon={"logout"} color={Colors.white} style={{ alignSelf: 'flex-end' }} size={25} ></IMIcon>
                            {/* <Icon name='x' size={25} color={Colors.white} style={{ alignSelf: 'flex-end' }}></Icon> */}
                        </TouchableOpacity>
                        <Title style={{ color: Colors.white }}>{lang.SETTINGS}</Title>
                        <TouchableOpacity onPress={props.close}>
                            <IMIcon icon={"times"} color={Colors.white} style={{ alignSelf: 'flex-end' }} size={25} ></IMIcon>
                            {/* <Icon name='x' size={25} color={Colors.white} style={{ alignSelf: 'flex-end' }}></Icon> */}
                        </TouchableOpacity>
                    </View>
                    <ScrollView>
                        <View style={{ height: 'auto', backgroundColor: theme.background }}>
                            <Settings isSettingsOpen={props.isVisible} onSettingsChange={props.onSettingsChange} navigation={props.navigation}></Settings>
                        </View>
                    </ScrollView>
                </View>
            }
        />
    )
}