import AsyncStorage from '@react-native-community/async-storage';
import React, { useContext, useEffect, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { Colors, SwitchDarkMode, Text, ChangeThemeContext, ThemeContext, ChangeLanguageContext, LanguageContext, PickerModal, Switch } from '@infominds/react-native-components'
import { getLanguageJSON } from '../utils/LanguageUtils'
import Separator from '../components/Separator'
import { LicenseGlobals } from '@infominds/react-native-license'
import useApi from '../apis/useApi';
import createPersistedState from 'use-persisted-state';
import DeviceInfo from 'react-native-device-info';
import { Mandant } from '../types';
import MandantPickerModal from '../components/MandantPickerModal';
import useLoginApi from '../apis/useLoginApi';

const packageJson = require('../../package.json');

const useCounterState = createPersistedState('mandantID');

export default function Settings(props: { navigation: any, onSettingsChange: () => void, isSettingsOpen: boolean }) {
  const lang: any = useContext(LanguageContext);
  const setLanguage = useContext(ChangeLanguageContext);
  const colorScheme = useContext(ThemeContext);
  const setTheme = useContext(ChangeThemeContext);

  const [saveImagesInMedia, setSaveImagesInMedia] = useState(false)
  const [fastArticleCard, setFastArticleCard] = useState(Platform.OS === 'android')

  const isDarkMode = (colorScheme === 'dark')

  const [isDarkeModeEnabled, setIsDarkeModeEnabled] = useState(isDarkMode);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [mandants, setMandants] = useState<Mandant[]>([]);
  const [selectedMandant, setSelectedMandant] = useCounterState(LicenseGlobals.mandantId ? LicenseGlobals.mandantId : -1);

  const [selectedLanguage, setSelectedLanguage] = useState(lang.ID);

  // console.log(LicenseGlobals.mandantId)

  useEffect(() => {
    setIsDarkeModeEnabled(isDarkMode)

    useApi.getMandants().then((mandantsRes: Mandant[]) => {
      setMandants(mandantsRes);
      AsyncStorage.getItem('mandantId').then(res => { if (res) setSelectedMandant(Number(res)) });
    });

    AsyncStorage.getItem('lang').then(res => { if (res) setSelectedLanguage(res) });
    AsyncStorage.getItem('username').then((res: any) => setUsername(res));
    AsyncStorage.getItem('password').then((res: any) => setPassword(res));
    AsyncStorage.getItem('saveImagesInMedia').then((res: any) => setSaveImagesInMedia(res === "true"));
    AsyncStorage.getItem('fastArticleCard').then((res: any) => setFastArticleCard(res === "true"));

  }, [props.isSettingsOpen]);

  useEffect(() => {
    AsyncStorage.setItem('saveImagesInMedia', saveImagesInMedia.toString())
  }, [saveImagesInMedia])

  // useEffect(() => {
  //   AsyncStorage.setItem('fastArticleCard', fastArticleCard.toString())
  // }, [fastArticleCard])

  function toggleThemeSwitch() {
    setIsDarkeModeEnabled(previousState => !previousState);

    if (colorScheme === 'light') {
      setTheme('dark');
      AsyncStorage.setItem('theme', 'dark');
    } else {
      setTheme('light');
      AsyncStorage.setItem('theme', 'light');
    }
  }

  function getNewToken(mandant?: number, langstr?: string) {
    const useCompany = mandant ?? (selectedMandant as any);
    const useLang = langstr ?? selectedLanguage;

    useLoginApi.login(username, password ?? '', useLang, useCompany).then(token => {
      LicenseGlobals.token = token;
      props.onSettingsChange();
    }).catch(err => console.error(err));
  }

  const PickerLanguage = () => {
    const languages = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Italiano', value: 'it' },
      { label: 'English', value: 'en' },
    ];
    return (
      <View style={{ flex: 1 }}>
        <PickerModal
          style={{ height: 40 }}
          value={() => languages.find((item) => item.value === selectedLanguage)}
          onSelect={(language: any) => {
            if (language) {
              setLanguage(getLanguageJSON(language.value));
              setSelectedLanguage(language.value);
              AsyncStorage.setItem('lang', language.value);
              getNewToken(undefined, language.value);
            }
          }}
          renderSelected={(item: any) => item?.label}
          renderItemContent={({ item }: { item: any }) => <Text>{item.label}</Text>}
          data={languages} />
      </View>
    )
  }


  const PickerMandats = () => (
    <View style={{ flex: 1 }}>
      <MandantPickerModal
        style={{ height: 40 }}
        value={selectedMandant}
        onSelect={(value: Mandant) => {
          if (value && value.id !== -1) {
            setSelectedMandant(value.id);

            LicenseGlobals.mandantId = value.id;
            AsyncStorage.setItem('mandantId', value.id.toString());

            getNewToken(value.id);
          }
        }}
        renderSelected={(item: Mandant) => item?.description}
        renderItemContent={({ item }: { item: Mandant }) => <Text>{item.description}</Text>}
        data={mandants} />
    </View >
  )

  return (
    <View style={{ margin: 0 }} >
      <TouchableOpacity>
        <TouchableWithoutFeedback>
          <View style={{ padding: 20 }}>
            {/* {devEnviroments.enableIntroSlider && <>
              <Button
                title={'Show tutorial'}
                style={{ backgroundColor: Colors.grey, margin: 0, marginBottom: 5, padding: 3 }}
                onPress={() => {
                  AsyncStorage.removeItem('intro').then(() => {
                    if (Platform.OS === 'web')
                      window.location.reload();
                    else
                      DevSettings.reload();
                  });
                }}
              />
              <Separator />
            </>} */}

            <View style={styles.darkModeToggle}>
              <Text>{lang.SETTINGS_DARKMODE}</Text>
              <SwitchDarkMode
                style={{ flex: 1, alignSelf: 'flex-end' }}
                trackColor={{ true: Colors.tint }}
                onValueChange={() => toggleThemeSwitch()}
                value={isDarkeModeEnabled}
              />
            </View>

            <Separator />

            <View style={styles.picker}>
              <Text>{lang.SETTINGS_LANGUAGE}</Text>
              <PickerLanguage />
            </View>

            <Separator />

            <View style={styles.picker}>
              <Text>{lang.SETTINGS_MANDANT}</Text>
              <PickerMandats />
            </View>

            <Separator />


            <View style={styles.darkModeToggle}>
              <Text style={{ flex: 1, flexWrap: 'wrap' }}>{lang.KEEP_IMAGES_IN_MEDIA}</Text>
              <Switch
                style={{ flex: 1, alignSelf: 'flex-end' }}
                trackColor={{ true: Colors.tint }}
                onValueChange={(value: boolean) => setSaveImagesInMedia(value)}
                value={saveImagesInMedia}
              />
            </View>

            <Separator />


            <View style={styles.darkModeToggle}>
              <Text style={{ flex: 1, flexWrap: 'wrap' }}>Fast Mode</Text>
              <Switch
                style={{ flex: 1, alignSelf: 'flex-end' }}
                trackColor={{ true: Colors.tint }}
                onValueChange={(value: boolean) => {
                  setFastArticleCard(value)
                  AsyncStorage.setItem('fastArticleCard', value.toString())
                }}
                value={fastArticleCard}
              />
            </View>

            <Separator />


            <Text style={{ marginBottom: 10 }}>Info</Text>

            {Platform.OS !== 'web' &&
              <View style={styles.infoRow}>
                <Text style={styles.info}>{lang.SETTINGS_VERSION}: </Text>
                <Text style={styles.info}>{packageJson.name} v{DeviceInfo.getVersion()}</Text>
              </View>}

            <View style={styles.infoRow}>
              <Text style={styles.info}>{lang.SETTINGS_LICENSE}: </Text>
              <Text style={styles.info}>{LicenseGlobals.license}</Text>
            </View>

            <View style={styles.infoRow}>
              <Text style={styles.info}>{lang.SETTINGS_SERVER}: </Text>
              <Text style={styles.infoDetail}>{LicenseGlobals.baseUrl}</Text>
            </View>

            <View style={styles.infoRow}>
              <Text style={styles.info}>{lang.USERNAME}: </Text>
              <Text style={styles.info}>{username}</Text>
            </View>

            <View style={{ height: 20 }}></View>

          </View>
        </TouchableWithoutFeedback>
      </TouchableOpacity>
    </View >
  )
}

const styles = StyleSheet.create({
  info: {
    color: Colors.icon
  },
  infoDetail: {
    color: Colors.icon,
    flex: 1,
    flexWrap: 'wrap',
    textAlign: 'right'
  },
  infoRow: { flexDirection: 'row', justifyContent: 'space-between' },
  picker: { flex: 1, flexDirection: 'row', justifyContent: 'space-between' },
  darkModeToggle: { flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }
});
