export default {

    it: [
        {
            title: 'Login',
            text: 'Inserisci la licenza scansiona il qrcode\nDopo aver inserito Utente e Password attendi l’attivazione',
            image: require('./assets/slides/login.jpeg')
        },
        {
            title: 'Home',
            text: 'Inizia selezionando il cantiere o il cliente.\nSwipe a sinistra per Preferiti o Info. Premi la lente per eseguire una ricerca',
            image: require('./assets/slides/it/home-it.jpeg')
        },
        {
            title: 'Seleziona cantiere',
            text: 'Se hai selezionato un cliente devi scegliere un cantiere.\nSelezione Prosegui senza cantiere se non ti serve il cantiere',
            image: require('./assets/slides/it/constructionSite-it.jpeg')
        },
        {
            title: 'Documento da compilare',
            text: 'Scegli il documento da inserire e compila i dati di testata',
            image: require('./assets/slides/it/task-it.jpeg')
        },
        {
            title: 'Righe documento',
            text: 'Registra un vocale, scatta una foto o fai un video da inviare in ufficio.\nSelezione uno o più articoli dall’archivio anagrafico',
            image: require('./assets/slides/it/media-it.jpeg')
        }
    ],

    de: [
        {
            title: 'Login',
            text: 'Geben Sie Ihre Lizenz ein, indem Sie den qrcode scannen\nNachdem Sie Ihren Benutzernamen und Ihr Passwort eingegeben haben, warten Sie bitte auf die Aktivierung',
            image: require('./assets/slides/login.jpeg')
        },
        {
            title: 'Home',
            text: 'Beginnen Sie mit der Auswahl einer Baustelle oder eines Kunden. Streichen Sie nach links, um zu den Favoriten oder zur Info zu gelangen. Drücken Sie die Lupe, um eine Suche durchzuführen',
            image: require('./assets/slides/de/home-de.jpeg')
        },
        {
            title: 'Baustelle wählen',
            text: 'Wenn Sie einen Kunden ausgewählt haben, müssen Sie eine Baustelle auswählen. Wählen Sie Weiter ohne Baustelle, wenn Sie keine Baustelle benötigen',
            image: require('./assets/slides/de/constructionSite-de.jpeg')
        },
        {
            title: 'Dokument wählen',
            text: 'Wählen Sie das Dokument und geben Sie die Kopfdaten ein',
            image: require('./assets/slides/de/task-de.jpeg')
        },
        {
            title: 'Dokumentzeilen',
            text: 'Nehmen Sie eine Stimme auf, machen Sie ein Foto oder ein Video und senden Sie es an das Büro. Auswahl eines oder mehrerer Artikel aus der Stammdatei',
            image: require('./assets/slides/de/media-de.jpeg')
        }
    ]


}; 