import * as React from 'react'
import { Colors, View, Text, CardBasic } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';
import DoctypeUtils from '../utils/DoctypeUtil'
import IMIcon from '../components/IMIcon';

export default function DetailArchiveCard(props: {
    title: string,
    description?: string,
    description2?: string,
    docType: number,
    style?: any,
    color?: string
    onPress?: () => void,
    rightComponent?: JSX.Element
}) {
    const iconname = DoctypeUtils.getTaskIcon(props.docType)

    return (
        <TouchableOpacity disabled={props.onPress === undefined} onPress={() => { if (props.onPress) props?.onPress() }}>
            <CardBasic style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: props.color,
                padding: 0,
                elevation: 3
            }}>

                <View style={{
                    backgroundColor: Colors.grey,
                    marginRight: 10,
                    borderRadius: 8,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: 80,
                    marginVertical: 15,
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <IMIcon color={Colors.white} size={40} icon={DoctypeUtils.getIcon(iconname)} />
                </View>

                <View style={{ flex: 1, padding: 12 }}>
                    {!!props.title && <Text>{props.title}</Text>}
                    {!!props.description && <Text style={{ marginBottom: 0, paddingBottom: 0 }}>{props.description}</Text>}
                    {!!props.description2 && <Text style={{ marginTop: 0, paddingTop: 0 }}>{props.description2}</Text>}
                </View>

                {props.rightComponent &&
                    <View style={{ marginRight: 10 }}>
                        {props.rightComponent}
                    </View>
                }

            </CardBasic >
        </TouchableOpacity>
    )
}
