import * as React from 'react'
import { Colors, ThemeContext, View, Text, Icon, CardBasic } from "@infominds/react-native-components";
import { useContext, useEffect, useState } from "react";
import { TouchableOpacity, StyleSheet, Image } from 'react-native';
import useArticlesApi from '../apis/useArticlesApi';

export default function ArticleCard(props: {
    title: string,
    description: string,
    articleId: string,
    taskId: number,
    subTitle: string,
    minusVisible: () => boolean,

    onSubmit: () => void,
    onDelete: () => void,

    onInfoPress: () => void,
    deletemode: boolean,
    infomode: boolean,

    style?: any
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const IMAGE_SIZE = 100;

    const [image, setImage] = useState<string | undefined>(undefined);

    const getArticleImage = () => {
        if (props.taskId && props.articleId)
            useArticlesApi.getArticleDetail(props.taskId, props.articleId)
                .then((res) => {
                    if (res?.article_image?.image)
                        setImage(res?.article_image?.image)
                })
    }

    useEffect(() => {
        getArticleImage();
    }, [])

    // let [onPressColor, setOnPressColor] = useState('transparent')

    {/* TODO check image visualizazion on web because it does not show image, android and ios works */ }
    return (
        <CardBasic style={{
            flex: 1,
            // backgroundColor: props.color,
            padding: 0,
            margin: 5,
            elevation: 3,

            // shadowOpacity: 1
        }}>
            <TouchableOpacity onPress={() => {
                if (!props.deletemode) props.onSubmit()
            }}
                style={{ paddingVertical: 0, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }} >

                {image ?
                    <Image
                        style={{ width: IMAGE_SIZE, height: IMAGE_SIZE }}
                        resizeMode='contain'
                        source={{ uri: 'data:image/jpg;base64,' + image }} />
                    : <></>
                }
                <View style={{ flex: 1, margin: 5, minHeight: 50 }}>
                    <Text style={{ fontWeight: 'bold', flexWrap: 'wrap' }}>{props.title}</Text>
                    {!!props.subTitle && <Text style={{ flexWrap: 'wrap' }}>{props.subTitle}</Text>}
                </View>

                {props.deletemode ?
                    <TouchableOpacity onPress={props.onDelete} style={styles.deletebutton}>
                        <Icon
                            size={30}
                            name={'trash'}
                            color={theme.background} />
                    </TouchableOpacity>
                    :
                    <>
                        {props.infomode ?
                            <TouchableOpacity onPress={props.onInfoPress} style={styles.infobutton}>
                                <Icon
                                    size={30}
                                    name={'info'}
                                    color={theme.background} />
                            </TouchableOpacity>
                            :
                            <Text style={{ alignSelf: 'center', textAlign: 'right', fontWeight: 'bold', fontSize: 25 }}>{props.minusVisible() ? '-' : ''}{props?.description}</Text>
                        }

                    </>

                }
            </TouchableOpacity>
        </CardBasic>
    )
}

const styles = StyleSheet.create({
    deletebutton: {
        backgroundColor: Colors.modern.red, //'#eb5757',
        margin: -1,
        marginRight: -13,
        width: 70,
        borderTopEndRadius: 10,
        borderBottomEndRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    infobutton: {
        backgroundColor: Colors.grey,
        margin: -1,
        marginRight: -13,
        width: 70,
        borderTopEndRadius: 10,
        borderBottomEndRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});
