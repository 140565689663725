import { IMIconName } from '../components/IMIconNames'

export default {
    getLanguage: (docType: number, lang: any): string => {
        if (docType === 0) return lang.ALL  // tutto
        if (docType === 3) return lang.OC   // OC 
        if (docType === 6) return lang.DDT  // DDT
        if (docType === -6) return lang.DDTtraCantieri  // DDT Trasferimento merci tra cantieri
        if (docType === 4) return lang.OF   // OF
        if (docType === 8) return lang.Warehouse    // Carcio Magazion
        return '';
    },
    getLanguageShort: (docType: number, lang: any): string => {
        if (docType === 0) return lang.ALL  // tutto
        if (docType === 3) return lang.OCshort   // OC 
        if (docType === 6) return lang.DDTshort  // DDT
        if (docType === -6) return lang.DDTtraCantierishort  // DDT Trasferimento merci tra cantieri
        if (docType === 4) return lang.OFshort   // OF
        if (docType === 8) return lang.Warehouseshort    // Carcio Magazion
        return '';
    },
    getIcon: (name: string): IMIconName => {
        if (name === 'shopping-cart') return 'shoppingCart';
        if (name === 'truck') return 'truck';
        if (name === 'truck-loading') return 'truckLoading';
        if (name === 'DDT') return 'fileInvoice'
        else return 'shoppingCart';
    },
    getTaskIcon: (type: number): string => {
        if (type === 4) return 'shopping-cart';
        if (type === 3) return 'truck';
        if (type === -6) return 'DDT';
        if (type === 6) return 'DDT';
        if (type === 8) return 'truck-loading';
        else return 'shopping-cart';
    },
    getLanguageShortForDocumentsInArchive: (docType: number, lang: any): string => {
        if (docType === 0) return lang.ALL  // tutto
        if (docType === 3) return lang.OCshort   // OC 
        if (docType === 6 || docType === -6) return lang.DDTshort  // DDT
        if (docType === 4) return lang.OFshort   // OF
        if (docType === 8) return lang.Warehouseshort    // Carcio Magazion
        return '';
    },
    getProviderName: (docType: number) => {
        docType = Math.abs(docType);
        switch (docType) {
            case 3: return 'ERGO_WORK_OC'
            case 6: return 'ERGO_WORK_DDT'
            case 4: return 'ERGO_WORK_OF'
            case 8: return 'ERGO_WORK_CM'
            default: return '';
        }
    },
    hasBuyPrice: (docType: number) => {
        docType = Math.abs(docType);
        switch (docType) {
            case 3: return true
            case 6: return true
            case 4: return true
            case 8: return true
            default: return false;
        }
    },
    hasSellPrice: (docType: number) => {
        docType = Math.abs(docType);
        switch (docType) {
            case 3: return true
            case 6: return true
            case 4: return false
            case 8: return false
            default: return false;
        }
    }
}