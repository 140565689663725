import React, { useEffect, useState } from "react";
import { ColorSchemeName, KeyboardAvoidingView, LogBox, Platform, StatusBar, StyleSheet, View } from 'react-native';
import Navigation from './navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import {
  LanguageContext, ChangeLanguageContext, Colors, ThemeContext,
  ChangeThemeContext, useColorScheme, OfflineNotice
} from '@infominds/react-native-components'
import createPersistedState from 'use-persisted-state';
import getLanguage from './hooks/useLanguage';
import initLoad from './utils/InitLoad';
import SplashScreen from 'react-native-splash-screen';
import devEnviroments from './devEnviroments';
import Analytics from 'appcenter-analytics';
import IntroSlider from './components/IntroSlider';
import slides from './Intro';
import ObjectContext from './contexts/ObjectContext'
import TasksContext from './contexts/TasksContext'
import { DetailObject, ErgoTask } from "./types";

const useThemeState = createPersistedState('useThemeState');
const useLanguageState = createPersistedState('useLanguageState');
const useShowIntroState = createPersistedState('useShowIntroState');

export default function App() {
  const initColorScheme = useColorScheme();
  const theme = Colors[initColorScheme];

  const [colorScheme, setTheme] = useThemeState(initColorScheme);
  const [lang, setLanguage] = useLanguageState(getLanguage());
  const [obejctDetail, setObjectDetail] = useState<DetailObject>();
  const [showIntro, setShowIntro] = useShowIntroState(devEnviroments.enableIntroSlider);

  const [tasks, setTasks] = useState<ErgoTask[]>([]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs();
      Analytics.setEnabled(devEnviroments.appCenterAnalytics)
    }

    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
    LogBox.ignoreLogs(["EventEmitter.removeListener", 'VirtualizedLists should never be nested'])
    LogBox.ignoreLogs(['VirtualizedLists should never be nested inside plain ScrollViews with the same orientation because it can break windowing and other functionality - use another VirtualizedList-backed container instead.']);

    initLoad(setLanguage, setTheme)
      .then(() => { if (Platform.OS !== 'web') SplashScreen.hide() });
  }, []);


  // TODOs

  // OK partial -> depaunceserach enter sofort senden und timeout reducen
  // login license due volte
  // OK -> chiudere swipe sulla cancellazione di un media articolo -> OK 
  // OK -> rabit fast if input empty crash  -> OK
  // filter per ricerca da guarda
  // OK -> documenti aperti sul selezione cliente cantiere
  // OK -> docdate propore quella da oggi 
  // OK -> article id einfügen  -> OK
  // article id ricerca per aricle code    ?????
  // Info cliente da far vedere.
  // OK -> documenti da salvare solo se siono cambiamenti. Solo se cé almeno una possizione salvare.

  // + - wieder auf nix wird zu 0 in liste articel 

  // OK -> media popup show article info description


  if (showIntro) return <IntroSlider data={slides} onDone={() => setShowIntro(false)} />;

  return (
    <SafeAreaProvider>
      <LanguageContext.Provider value={lang}>
        <ChangeLanguageContext.Provider value={setLanguage}>
          <ThemeContext.Provider value={colorScheme as any as NonNullable<ColorSchemeName>}>
            <ChangeThemeContext.Provider value={setTheme}>

              <ObjectContext.Provider value={{ object: obejctDetail, setObject: setObjectDetail }}>
                <TasksContext.Provider value={{ tasks: tasks, setTasks: setTasks }}>

                  <KeyboardAvoidingView
                    behavior={Platform.OS === "ios" ? "padding" : "height"}
                    style={styles.container} >

                    <View style={[styles.screen, { backgroundColor: theme.background }]}>
                      {Platform.OS !== 'web' ? <OfflineNotice text={lang ? (lang as any).NO_INTERNET : ''} /> : <></>}
                      <Navigation colorScheme={colorScheme as any as NonNullable<ColorSchemeName>} />

                      <StatusBar
                        translucent
                        backgroundColor="transparent"
                        barStyle={(colorScheme as any as NonNullable<ColorSchemeName>) === "dark" ? "light-content" : "dark-content"}
                      />
                    </View>
                  </KeyboardAvoidingView>

                </TasksContext.Provider>
              </ObjectContext.Provider>

            </ChangeThemeContext.Provider>
          </ThemeContext.Provider>
        </ChangeLanguageContext.Provider>
      </LanguageContext.Provider>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  screen: {
    height: '100%'
  },
  app: {
    marginHorizontal: "auto",
    width: '100%',
    marginTop: 30,
    maxWidth: 500
  }
});
