import * as React from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { useContext } from 'react';
import { Colors, ThemeContext } from '@infominds/react-native-components';
import IMIcon from './IMIcon';
import { IMIconName } from './IMIconNames';

export default function ActionButton(props: {
    icon: IMIconName,
    onPress: () => void,
    hasMarginRight?: boolean,
    style?: StyleProp<ViewStyle>,
    iconColor?: string
}) {

    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <TouchableOpacity
            style={[{
                height: 35,
                justifyContent: 'center',
                backgroundColor: theme.inputBackground,
                borderRadius: 8,
                padding: 2,
                paddingHorizontal: 10,
            }, props.hasMarginRight && { marginRight: 10 }, props.style]}
            onPress={props.onPress}>
            <IMIcon size={28} color={props.iconColor ? props.iconColor : theme.text} icon={props.icon} />
        </TouchableOpacity>
    )
} 