import { Colors, Input, InputMaxLength, Label, LanguageContext, Note, ThemeContext } from "@infominds/react-native-components"
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { View } from "react-native"
import useArticlesApi from "../../apis/useArticlesApi";
import GrowingInput, { GrowingInputValues } from '../../components/GrowingInput'
import { ErgoTask } from "../../types";
import DoctypeUtil from '../../utils/DoctypeUtil'


type ArticleConfigValues = {
    title?: string;
    note?: string;
    quantity?: number;
    selPrice?: number;
    buyPrice?: number;
    total?: number;
    buyTotal?: number;
    rabat?: GrowingInputValues;
    buyRabat?: GrowingInputValues;
    hasBuyPrice?: boolean;
    hasSellPrice?: boolean;

}

export type { ArticleConfigValues };


export default function ArticleConfigView(props: {
    value?: ArticleConfigValues,
    onChange: (res: ArticleConfigValues) => void,
    ergoTask: ErgoTask,
    hasTitle: boolean
    hasDescription: boolean
    selectedArticleDetail?: any
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const lang = useContext(LanguageContext);

    const [description, setDescription] = useState(props?.value?.title || '');
    const [note, setNote] = useState(props?.value?.note || '');
    const [quantity, setQuantity] = useState(props?.value?.quantity?.toString() || "1");
    const [selPrice, setSelPrice] = useState(props?.value?.selPrice?.toString() || "");
    const [buyPrice, setBuyPrice] = useState(props?.value?.buyPrice?.toString() || "");
    const [total, setTotal] = useState(props?.value?.total || 0);
    const [buyTotal, setBuyTotal] = useState(props?.value?.buyTotal || 0);
    const [rabat, setRabat] = useState<GrowingInputValues>(props?.value?.rabat || {});
    const [buyRabat, setBuyRabat] = useState<GrowingInputValues>(props?.value?.buyRabat || {});

    const docType = Number(props.ergoTask.param_list.DOCTYPE);

    // this depends also on the document type 
    const ShowBuyPrice = props.ergoTask.param_list.SHOW_BUY_PRICE === "1" && DoctypeUtil.hasBuyPrice(docType);
    const ShowSellPrice = props.ergoTask.param_list.SHOW_SALE_PRICE === "1" && DoctypeUtil.hasSellPrice(docType);;

    const ChangeBuyPrice = props.ergoTask.param_list.CHANGE_BUY_PRICEANDDISCOUNT === "1";
    const ChangeSellPrice = props.ergoTask.param_list.CHANGE_SALE_PRICEANDDISCOUNT === "1";

    function calculateTotal(quantity: string, amount: string, rabat: any) {
        let price = Number(quantity) * Number(amount);
        if (rabat) {
            if (rabat.value1) { price = price - (price * (Number(rabat.value1) / 100)) }
            if (rabat.value2) { price = price - (price * (Number(rabat.value2) / 100)) }
            if (rabat.value3) { price = price - (price * (Number(rabat.value3) / 100)) }
            if (rabat.value4) { price = price - (price * (Number(rabat.value4) / 100)) }
        }
        return Math.round(price * 100) / 100;
    }

    useEffect(() => { if (props?.value?.quantity) setQuantity(props?.value?.quantity?.toString() || "1") }, [props?.value?.quantity])

    useEffect(() => {
        if (props.selectedArticleDetail && props.selectedArticleDetail.article_id)
            useArticlesApi.getArticleDetail(props.ergoTask.task_id, props.selectedArticleDetail.article_id)
                .then((article) => {
                    // console.log(article)

                    if (buyPrice === "")
                        setBuyPrice(article?.buy_price[0]?.net_price?.toFixed(2))

                    if (selPrice === "")
                        setSelPrice(article?.sale_price[0]?.net_price?.toFixed(2))

                });
    }, [])

    useEffect(() => {
        setTotal(calculateTotal(quantity, selPrice, rabat))
    }, [quantity, selPrice, rabat])

    useEffect(() => {
        if (!(ShowSellPrice && ChangeSellPrice))
            setBuyTotal(calculateTotal(quantity, buyPrice, rabat))
        else
            setBuyTotal(calculateTotal(quantity, buyPrice, buyRabat))
    }, [quantity, buyPrice, buyRabat, rabat])

    function save() {
        props.onChange({
            title: description, note: note, quantity: Number(quantity), selPrice: Number(selPrice), total: total, rabat: rabat, buyPrice: Number(buyPrice), buyRabat: buyRabat, buyTotal: buyTotal,
            hasBuyPrice: ShowBuyPrice && ChangeBuyPrice,
            hasSellPrice: ShowSellPrice && ChangeSellPrice
        })
    }

    useEffect(() => {
        save();
    }, [description, note, quantity, selPrice, total, rabat, buyPrice, buyRabat, buyTotal])

    return (
        <View style={{ backgroundColor: theme.backgroundModal, flex: 1, padding: 8 }}>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.grey, paddingBottom: 10 }}>
                {props.hasTitle &&
                    <InputMaxLength
                        placeholder={lang.TITLE}
                        maxLength={250}
                        value={description}
                        onChangeText={(text) => { setDescription(text); }} />
                }
                {props.hasDescription &&
                    <Note
                        placeholder={lang.DESCRIPTION}
                        value={note}
                        onChangeText={(text) => { setNote(text); }} />
                }
                <View style={{ flex: 1 }}>
                    <Label text={lang.QUANTITY}></Label>
                    <Input value={quantity.toString().replace('.', ',')}
                        onChangeText={(text: string) => { setQuantity(text.replace(',', '.')); }} keyboardType={'decimal-pad'} ></Input>
                </View>
            </View>

            {ShowSellPrice &&
                <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.grey, paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Label text={lang.SALE_PRICE}></Label>
                            <Input editable={ChangeSellPrice} value={selPrice.toString().replace('.', ',')} onChangeText={(res: string) => setSelPrice(res.replace(',', '.'))} keyboardType={'decimal-pad'}></Input>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Label text={lang.DISCOUNTS}></Label>
                            <GrowingInput
                                value={rabat}
                                editable={ChangeSellPrice}
                                onValueChange={(result: GrowingInputValues) => { setRabat(result) }} />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Label text={lang.TOTAL}></Label>
                            <Input
                                editable={false}
                                style={{ color: theme.textPlaceholder }}
                                keyboardType={'decimal-pad'}
                                value={total.toString().replace('.', ',')}
                                onChangeText={(res: string) => setTotal(Number(res))} />
                        </View>
                    </View>
                </View>
            }


            {
                ShowBuyPrice &&
                <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.grey, paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <View style={{ flex: 1 }}>
                            <Label text={lang.QUANTITY}></Label>
                            <Input value={quantity.toString().replace('.', ',')} onChangeText={(text: string) => setQuantity(text.replace(',', '.'))} keyboardType={'number-pad'} ></Input>
                        </View> */}
                        <View style={{ flex: 1 }}>
                            <Label text={lang.BUY_PRICE}></Label>
                            <Input editable={ChangeBuyPrice}
                                value={buyPrice.toString().replace('.', ',')}
                                onChangeText={(res: string) => setBuyPrice(res.replace(',', '.'))}
                                keyboardType={'decimal-pad'}></Input>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Label text={lang.TOTAL}></Label>
                            <Input
                                editable={false}
                                style={{ color: theme.textPlaceholder }}
                                keyboardType={'decimal-pad'}
                                value={buyTotal.toString().replace('.', ',')}
                                onChangeText={(res: string) => setBuyTotal(Number(res))} />
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {!ShowSellPrice &&
                            // If there is only the buyprice add discounts
                            <View style={{ flex: 1 }}>
                                <Label text={lang.DISCOUNTS}></Label>
                                <GrowingInput
                                    value={rabat}
                                    editable={ChangeBuyPrice}
                                    onValueChange={(result: GrowingInputValues) => { setRabat(result) }} />
                            </View>}
                        <View style={{ flex: 1 }}>
                            <Label text={lang.TOTAL}></Label>
                            <Input
                                editable={false}
                                style={{ color: theme.textPlaceholder }}
                                keyboardType={'decimal-pad'}
                                value={buyTotal.toString().replace('.', ',')}
                                onChangeText={(res: string) => setBuyTotal(Number(res))} />
                        </View>
                        {/* <View style={{ flex: 1 }}>
                            <Label text={lang.DISCOUNTS}></Label>
                            <GrowingInput
                                value={buyRabat}
                                editable={ChangeBuyPrice}
                                onValueChange={(result: GrowingInputValues) => { setBuyRabat(result) }} />
                        </View> */}
                        {/* <View style={{ flex: 1 }}>
                            <Label text={lang.TOTAL}></Label>
                            <Input
                                editable={false}
                                style={{ color: theme.textPlaceholder }}
                                keyboardType={'decimal-pad'}
                                value={buyTotal.toString().replace('.', ',')}
                                onChangeText={(res: string) => setBuyTotal(Number(res))} />
                        </View> */}
                    </View>
                </View>
            }


        </View >
    )
}