import { Icon, View, Text, Colors, CardBasic, SearchList } from "@infominds/react-native-components";
import React, { useEffect, useState } from "react";
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import ContentModal from "./ContentModal";


const lookUp: any = {
  'building': 'briefcase',
  'hard-hat': 'truck',
  'wrench': 'tool',
  'user': 'user'
}
function getWebIcon(name: string) {
  return lookUp[name];
}

export default function SearchModal(props: {
  text: string,
  data: any,
  renderSelected: (item: any, toggleModalBottom: any) => JSX.Element,
  searchString: (item: any) => string,
  renderItemContent: ({ item, index }: any) => JSX.Element,
  onSelect?: (item: any) => void,
  onDelete?: () => void,
  onClose?: () => void,
  value?: any,
  editable?: boolean
  style?: any,
  icon?: string,
  visible?: boolean,
  iconSize?: number
}) {
  const [selected, setSelected] = useState();

  const [isModalBottomVisible, setModalBottomVisible] = useState(props.visible ? props.visible : false);
  const toggleModalBottom = () => {
    setModalBottomVisible(!isModalBottomVisible);
    if (props.onClose)
      props.onClose();
  }

  useEffect(() => {
    setSelected(props.value)
  }, [props.value]);

  useEffect(() => {
    if (props.visible)
      setModalBottomVisible(props.visible)

  }, [props.visible]);


  return (
    <View>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        {props.icon &&
          <TouchableOpacity
            onPress={() => { if (props.editable !== false) toggleModalBottom() }}
            style={styles.icon}>
            {Platform.OS === 'web' ?
              <Icon
                name={getWebIcon(props.icon)}
                size={props.iconSize || 40}
                color={Colors.white} />
              :
              <Icon
                family='FontAwesome5'
                name={props.icon}
                size={props.iconSize || 40}
                color={Colors.white} />
            }
          </TouchableOpacity>
        }
        {selected ?
          props.renderSelected(selected, toggleModalBottom)
          :
          <Text
            style={[{ textDecorationLine: 'underline', fontSize: 16, fontWeight: 'bold', paddingTop: 10, paddingBottom: 10 }, props.style]}
            onPress={() => { if (props.editable !== false) toggleModalBottom() }} >
            {/* {props.text} */}
          </Text>
        }
      </View>

      <ContentModal
        isVisible={isModalBottomVisible}
        close={toggleModalBottom}
        style={{ height: '90%' }}
        title={props.text}
        onResetSelection={() => {
          setSelected(undefined);
          toggleModalBottom();
          props.onSelect?.(undefined)
        }}
        content={() =>
          <View style={{ flex: 1, justifyContent: 'space-between' }} >
            <View style={{ height: '100%' }}>
              <SearchList
                data={props.data}
                style={{ padding: 10 }}
                searchString={props.searchString}
                renderItem={({ item, index }: any) => (
                  <TouchableOpacity
                    onPress={() => {
                      setSelected(item);
                      toggleModalBottom();
                      props.onSelect?.(item)
                    }}>
                    <CardBasic>

                      {props.renderItemContent({ item, index })}

                    </CardBasic>
                  </TouchableOpacity>
                )}
              />
            </View>
          </View>
        } />
    </View >
  );
}


const styles = StyleSheet.create({
  icon: {
    backgroundColor: Colors.grey,
    marginRight: 10,
    borderRadius: 8,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 18,
    marginLeft: 5
  }
});
