import * as React from 'react'
import { Colors, ThemeContext, Text } from "@infominds/react-native-components";
import { View } from 'react-native';
import IMIcon from './IMIcon'
import BadgeBase from './BadgeBase'
import SearchModal from '../components/SearchModal';
import { useContext, useEffect, useState } from 'react';

export default function ComboBadge(props: {
    title?: string,
    data: string[];
    onChange: any,
    value?: any
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    let [selected, setSelected] = useState<string>(props.value ? props.value : props.data[0]);

    const [listVisible, setListVisible] = useState(false);


    useEffect(() => {
        setSelected(props.value)
    }, [props.value])

    return (
        <BadgeBase
            onPress={() => { setListVisible(true) }}
            isChecked={true}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', marginHorizontal: 5 }}>
                <Text style={{ color: Colors.white }}>{selected}</Text>
                <IMIcon style={{ flex: 1, }} color={Colors.white} icon={'chevronDown'}></IMIcon>
            </View>

            <SearchModal
                text={props.title ? props.title : ''}
                data={props.data}
                onClose={() => setListVisible(false)}
                onSelect={(item: any) => {
                    if (props.onChange)
                        props?.onChange(item);
                    setListVisible(false);
                }}
                visible={listVisible}
                renderItemContent={({ item, index }) => {
                    return (
                        <Text color={theme.text}>{item}</Text>
                    );
                }}
                renderSelected={function (item: any, toggleModalBottom: any): JSX.Element {
                    return (
                        <Text color={theme.text}>{item}</Text>
                    );
                }}
                searchString={function (item: any): string {
                    return item;
                }}></SearchModal>

        </BadgeBase>

    )

}
