import * as React from 'react'
import { Colors, View, Text, ThemeContext } from "@infominds/react-native-components";
import { Platform } from 'react-native';
import Section from '../components/Section';


export default function SelectionBox(props: {
    title?: string,
    hidden?: boolean;
    showError?: boolean;
    children: JSX.Element | JSX.Element[]
}) {
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    if (props?.hidden)
        return (<></>)
    return (
        <View
            style={[{
                flex: 1,
                margin: 5,
                marginVertical: 8,
                borderRadius: 8,
                borderColor: props.showError ? Colors.modern.red : theme.inputBorder,
                // borderColor: theme.inputBorder,
                borderWidth: 1,
                backgroundColor: theme.card,
                shadowColor: colorScheme === 'light' ? '#999999' : Colors.black,
                elevation: 3
            },
            Platform.OS === 'ios' && { shadowOffset: { width: 4, height: 4 }, shadowOpacity: 0.40, },
                // Platform.OS === 'web' && { shadowOffset: { width: 2, height: 2 }, shadowOpacity: 0.40, }
            ]}>

            <Text style={{ marginLeft: 10 }} color={theme.text}>{props.title ?? ''}</Text>
            <Section>
                {props.children}
            </Section>
        </View>
    )
}
