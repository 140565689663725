import React, { useEffect } from 'react'
import { Colors, ThemeContext, View } from "@infominds/react-native-components";
import { useContext } from "react";

export default function SignModal(props: { onSignAction: (params: any) => void, placeholder: string, style?: any }) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    // TODO test in web
    useEffect(() => {
        props.onSignAction('')
    }, [])

    return (
        <View>

        </View>
    )
}