import * as React from 'react';
import { ThemeContext, Colors, View, LanguageContext, List, Layout, LoadingSpinnerModal } from '@infominds/react-native-components';
import { useState } from 'react';
import { RefreshControl, TouchableOpacity, ImageBackground, SafeAreaView, Platform, StatusBar, Linking, Alert } from 'react-native';
import ImageBoxWithText from '../../components/ImageBoxWithText';
import useApi from '../../apis/useApi';
import { ErgoTask, StoredNewDocument, Client } from '../../types';
import TaskCard from '../../cards/TaskCard'
import ObjectContext from '../../contexts/ObjectContext';
import TitleBar from '../../components/TitleBar';
import MasterDetail from '../../components/MasterDetail';
import { useOrientation } from '../../hooks/useOrientation';
import Moment from 'moment'
import MapUtils from '../../utils/MapUtils'
import TasksContext from '../../contexts/TasksContext';
import { useFocusEffect } from '@react-navigation/native';
import CreateDocumentStorage, { CreatedDocument } from '../../utils/CreatedDocumentStorage';
import NewDocumentStorage, { NewDocumentItem } from '../../utils/NewDocumentStorage';
import IMIcon from '../../components/IMIcon';
import Images from '../../assets/img/BackgroundImages'
import CreatedDocumentStorage from '../../utils/CreatedDocumentStorage';


export default function TabHomeScreen(props: { navigation: any, route: any }) {
  const lang = React.useContext(LanguageContext);
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const { object, infoVisible, companyId } = React.useContext(ObjectContext).object || { infoVisible: false, object: undefined, companyId: 0 };

  const [loading, setLoading] = useState(true);
  const [listLoading] = useState(false);

  const [showInfo, setShowInfo] = useState(infoVisible || Layout.isLargeDevice || false);

  const [createdDocuments, setCreatedDocuments] = useState<CreatedDocument[]>([])
  const [savedDocuments, setSavedDocuments] = useState<NewDocumentItem[]>([])

  const [company, setCompany] = useState<Client | undefined>(undefined)

  const { tasks, setTasks } = React.useContext(TasksContext)

  function reload() {
    setLoading(true)
    load().finally(() => setLoading(false))
  }
  async function load() {


    if (companyId)
      useApi.getCompany(companyId).then((res) => { setCompany(res) })


    // useApi.getTasks('MOBILEWORK').then(res => console.log(res)).catch(error => console.error(error))

    try {
      let res: ErgoTask[] = await useApi.getTasks('MOBILEWORK')
      // console.log('tasks', res)

      if (res) {
        //res = res.sort((a: ErgoTask, b: ErgoTask) => ((a.param_list.MANDATORY_OBJECT === '1' && object === undefined)) ? 1 : -1)
        let tasksWithoutMandatoryObjects: ErgoTask[] = []
        let tasksWithtMandatoryObjects: ErgoTask[] = []
        res.forEach(element => {
          if (element.param_list.MANDATORY_OBJECT === '1' && object === undefined)
            tasksWithtMandatoryObjects.push(element)
          else
            tasksWithoutMandatoryObjects.push(element);
        })

        let tmpTasks = [...tasksWithoutMandatoryObjects, ...tasksWithtMandatoryObjects]

        setTasks(tmpTasks)

        // get already created documents to show in tasks
        let createdDocs: CreatedDocument[] | undefined = await CreateDocumentStorage.verifyAll(res, object?.object_id, companyId)
        if (createdDocs)
          setCreatedDocuments(createdDocs)

        let newDocuments: NewDocumentItem[] = await NewDocumentStorage.load(object ? object?.object_id : (companyId ? companyId : -1))
        setSavedDocuments(newDocuments)
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  // needs to bee refreshed every time, because if you create a new document then it shpuld be visible imidiatly in the list
  useFocusEffect(React.useCallback(() => {
    load()
  }, []));

  console.log(object)

  const Info = () => {
    return (
      <View style={{ flex: 0 }}>
        <View style={{
          margin: 5,
          marginHorizontal: 8,
          marginRight: 20,
          marginLeft: 16,
          marginBottom: 0,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
          {!Layout.isSmallDevice &&
            <ImageBoxWithText
              icon={'infoCircle'}
              text={object?.description || company?.description}
              text1={object?.note}
            />
          }
          {Layout.isSmallDevice && <View style={{ height: 5 }}></View>}
        </View>

        {showInfo &&
          <View style={{ margin: 8, marginTop: 0, marginLeft: 16, marginBottom: 20 }}>
            <ImageBoxWithText
              icon={'building'}
              text={object?.company?.description || company?.description}
              text1={object?.company?.company_id.toString() || company?.id?.toString() || ''}
            />

            {(object?.address?.longitude || object?.address?.street || object?.address?.place) ?
              <ImageBoxWithText
                style={{ marginTop: 0 }}
                icon={'map'}
                text={object?.address?.street}
                text1={object?.address?.place + (object?.address?.zip ? ' ' + object?.address?.zip : '')}
                onPress={() => MapUtils.openMap(object?.address, lang.NO_DATA_POSSITION)}
              /> : <></>
            }

            {object?.tel ?
              <ImageBoxWithText
                style={{ marginTop: 0 }}
                icon={'phone'}
                text={object?.tel}
                onPress={() => { Linking.openURL(`tel:${object?.tel}`) }}
              /> : <></>
            }

            {object?.email ?
              <ImageBoxWithText
                style={{ marginTop: 0 }}
                icon={'at'}
                text={object?.email}
                onPress={() => { Linking.openURL(`mailto:${object?.email}`) }}
              /> : <></>
            }
            {object?.times?.startWork && object?.times?.endWork &&
              <ImageBoxWithText
                style={{ marginTop: 0 }}
                text1Style={{ fontWeight: 'bold' }}
                icon={'calendar'}
                text={object?.times.startWork && `${lang.START_WORK}:\t ${Moment(object?.times.startWork).format('DD/MM/YYYY')}`}
                text1={object?.times.endWork && `${lang.END_WORK}:\t ${Moment(object?.times.endWork).format('DD/MM/YYYY')}`}
              />}
          </View>
        }
      </View>)
  }

  function areYouSure() {
    return new Promise((resolve, reject) => {
      Alert.alert(
        lang.DELETE,
        lang.ARE_YOU_SURE_DELETE_DOCUMENT,
        [
          { text: lang.CANCEL, onPress: () => reject(), style: "cancel" },
          { text: "OK", onPress: () => resolve(true) }
        ]
      );
    })
  }

  const TaskList = () => (
    <List
      style={{ marginTop: 10, paddingHorizontal: 8, height: '100%' }}
      data={tasks}
      keyExtractor={(item: ErgoTask, index: number) => item.task_id.toString()}
      renderItem={({ item, index }: { item: ErgoTask, index: number }) => {
        return (
          <TaskCard
            key={item.task_id.toString()}
            mandatoryObject={!(item.param_list.MANDATORY_OBJECT === '1' && object === undefined)}
            color={theme.card}
            title={item.description}
            object={object}
            taskId={item.task_id}
            docType={Number(item.param_list.DOCTYPE)}
            createdDocumentsInfo={createdDocuments.filter((cd: CreatedDocument) => cd.taskId === item.task_id)}
            savedDocuments={savedDocuments.find((cd: NewDocumentItem) => cd.taskId === item.task_id)}
            onSavedDocumentSelected={(selected: StoredNewDocument) => {
              props.navigation.navigate('New', { task: item, savedDocument: selected, savedDocumentId: selected.date } as { task: ErgoTask, savedDocument: StoredNewDocument })
            }}
            onSavedDocumentDeletePress={(selected: StoredNewDocument) => {
              areYouSure().then(() => NewDocumentStorage.remove(object ? object?.object_id : (companyId ? companyId : -1), item.task_id, selected).then(() => reload()))
            }}
            onCreatedDocumentDeletePress={(selected: CreatedDocument) => {
              CreatedDocumentStorage.remove(selected?.createDocumentResult).then(() => reload())
            }}
            onPress={() => { props.navigation.navigate('New', { task: item } as { task: ErgoTask }) }}
          />
        )
      }}
      refreshing={listLoading}
      refreshControl={
        <RefreshControl
          refreshing={listLoading}
          onRefresh={() => load()}
          colors={[Colors.tint]}
          tintColor={Colors.tint} />
      }
      ListFooterComponent={< View style={{ height: 10 }}></View >}
    />
  )

  const orientation = useOrientation();

  const TITLE_FONT_SIZE = 20;

  if (Layout.isSmallDevice || orientation === 'PORTRAIT') {
    return (
      <ImageBackground
        source={{ uri: Images.ellipse15 }}
        imageStyle={{ opacity: colorScheme === 'dark' ? 0.8 : 1 }}
        style={{ flex: 1, height: '10%', opacity: 1, backgroundColor: theme.background, }}>
        <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>
          <ImageBackground
            source={{ uri: Images.ellipse22 }}
            imageStyle={{ opacity: colorScheme === 'dark' ? 0.8 : 1 }} resizeMode='stretch'
            style={{ width: '100%', backgroundColor: theme.background }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ flex: 1 }}>
                <TitleBar fontSize={TITLE_FONT_SIZE}
                  title={object?.description ? object?.description : (company?.description ? company.description : lang.DOCUMENT_CREATION)}
                  onBackPress={() => props.navigation.navigate('Root')} />
              </View>
              {Layout.isSmallDevice &&
                <View style={{ margin: 5, marginTop: 10, marginRight: 10 }}>
                  {showInfo ?
                    <TouchableOpacity onPress={() => { setShowInfo(false) }} style={{ padding: 5, paddingLeft: 10 }}>
                      <IMIcon
                        icon={'chevronUp'}
                        size={25}
                        color={theme.text} />
                    </TouchableOpacity>
                    :
                    <TouchableOpacity onPress={() => { setShowInfo(true) }} style={{ padding: 5, paddingLeft: 10 }}>
                      <IMIcon
                        icon={'infoCircle'}
                        size={25}
                        color={theme.text}></IMIcon>
                    </TouchableOpacity>}
                </View>}
            </View>
            <Info />
          </ImageBackground>
          <LoadingSpinnerModal visible={loading} />
          <TaskList />
        </SafeAreaView>
      </ImageBackground >
    )
  } else

    return (
      <View>
        <MasterDetail
          positionKey='TabHomeScreen'
          master={
            <ImageBackground source={{ uri: Images.rectangle }}
              imageStyle={{ opacity: colorScheme === 'dark' ? 0.8 : 1 }} resizeMode='stretch'
              style={{ width: '100%', height: '100%', backgroundColor: theme.background }}>
              <SafeAreaView style={[{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }]}>
                <TitleBar fontSize={TITLE_FONT_SIZE}
                  title={object?.description ? object?.description : (company?.description ? company.description : lang.DOCUMENT_CREATION)}
                  onBackPress={() => props.navigation.navigate('Root')} />
                <View style={{ marginTop: 20 }}>
                  <Info />
                </View>
              </SafeAreaView>
            </ImageBackground>
          }
          detail={
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0, backgroundColor: theme.background }}>
              <TaskList />
            </SafeAreaView>
          }
        />

        <LoadingSpinnerModal visible={loading} />
      </View>
    )
}