import * as React from 'react'
import { Colors, View, Text, ThemeContext, LanguageContext } from "@infominds/react-native-components";
import { Platform, TouchableOpacity } from 'react-native';
import ImageBoxWithText from '../components/ImageBoxWithText';
import SearchModal from '../components/SearchModal';
import { useContext, useState } from 'react';
import { IMIconName } from '../components/IMIconNames';
import ImageBoxWithTextAndId from '../components/ImageBoxWithTextAndId';

Selection.defaultProps = {
    required: false
}

export default function Selection(props: {
    title?: string,
    onPress?: () => void,
    value?: any[],
    selected: any,
    icon: IMIconName,
    onChange?: (result: any) => void,
    searchString: (item: any) => string,
    itemTitle: (item: any) => string,
    disabled?: boolean;
    hidden?: boolean;
    showError?: boolean;
    required?: boolean;
    getId?: (item: any) => number
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const lang = useContext(LanguageContext)

    const [listVisible, setListVisible] = useState(false);

    const modifyDisabled = () => { return props.onChange === undefined || props?.disabled }

    const iconSize = 25;

    if (props?.hidden)
        return (<></>)
    return (
        <View style={[{ flex: 1, borderColor: props.showError ? Colors.modern.red : theme.inputBorder, borderWidth: props.showError ? 1 : 0, borderRadius: 8, }]}>
            {listVisible ?
                <View>
                    <SearchModal
                        text={props.title ? props.title : ''}
                        data={props.value}
                        onClose={() => setListVisible(false)}
                        onSelect={(item: any) => {
                            if (props.onChange)
                                props?.onChange(item);
                            setListVisible(false);
                        }}
                        visible={listVisible}
                        searchString={(item: any) => props.searchString(item) + (props.getId ? props.getId(item) : '')}
                        renderItemContent={({ item, index }) => {
                            return (
                                <View style={{ flexDirection: 'row' }}>
                                    {props.getId &&
                                        <>
                                            <Text color={theme.textDetail}>{props.getId(item)}</Text>
                                            <Text >-</Text>
                                        </>
                                    }
                                    <Text style={{ flex: 1, flexWrap: 'wrap' }} color={theme.text}>{props.itemTitle(item)}</Text>
                                </View>
                            );
                        }}
                        renderSelected={function (item: any, toggleModalBottom: any): JSX.Element {
                            return (
                                <View style={{ flexDirection: 'row' }}>
                                    <Text color={theme.text}>{props.itemTitle(item)}</Text>
                                    {props.getId &&
                                        <Text style={{ flex: 1, flexWrap: 'wrap' }} color={theme.textDetail}>{props.getId(item)}</Text>
                                    }
                                </View>
                            );
                        }}></SearchModal>
                </View>
                :
                <TouchableOpacity
                    style={[{ flex: 1 }]}
                    disabled={modifyDisabled()}
                    onPress={() => {
                        setListVisible(true);
                    }}>
                    <View style={{ margin: 10, marginBottom: 0, marginTop: 15 }}>
                        {props.selected ?
                            <ImageBoxWithText
                                icon={props.icon}
                                minHeight={60}
                                iconSize={iconSize}
                                // text={((props.getId && props.selected) ? ('(' + props.getId(props.selected)?.toString() + ') ') : '') + (props.selected ? props.itemTitle(props.selected) : lang.NOTHING_SELECTED)}
                                text={props.selected ? props.itemTitle(props.selected) : lang.NOTHING_SELECTED}
                                // id={(props.getId && props.selected) ? props.getId(props.selected)?.toString() : ''}
                                // text={props.selected ? props.itemTitle(props.selected) : lang.NOTHING_SELECTED}
                                text1={(props.getId && props.selected) ? ('(' + props.getId(props.selected)?.toString() + ')') : ''}
                                text1Style={{ color: theme.textDetail, fontSize: 12 }}
                            />
                            :
                            <ImageBoxWithText
                                icon={props.icon}
                                minHeight={60}
                                iconSize={iconSize}
                                text={props.selected ? props.itemTitle(props.selected) : (props.title ?? '' + (props.required && '*'))}
                                textStyle={{ textDecorationLine: 'underline', fontWeight: 'normal' }}
                                // id={(props.getId && props.selected) ? props.getId(props.selected)?.toString() : ''}
                                text1={(props.getId && props.selected) ? ('(' + props.getId(props.selected)?.toString() + ')') : ''}
                                text1Style={{ color: theme.textDetail, fontSize: 12 }}
                            />
                        }
                    </View>
                </TouchableOpacity >
            }
        </View>
        // </View>

    )
}
