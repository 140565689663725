import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Client, ConstructionLot, ConstructionSite, Destination, ErgoTask, Freight, HeaderDDT, ObjectInfo, StoredNewDocument, TransportReason } from '../../../types'
import { Button, Input, Label, LanguageContext } from "@infominds/react-native-components";
import useApi from '../../../apis/useApi'
import SelectionCard from '../../../cards/SelectionCard';
import Section from '../../../components/Section'
import ErrorMessage from "../../../components/ErrorMessage";
import NewDocumentStorage from "../../../utils/NewDocumentStorage";
import SelectionBox from "../../../cards/SelectionBox";
import Selection from "../../../cards/Selection";

export default function NewHeaderDDT(props: { constructionSite?: ObjectInfo, companyId?: number, task: ErgoTask, onSave: any, onSavedDocumentChanged: any, savedDocumentId: string, savedHeader: any }) {
    const lang = useContext(LanguageContext);

    const [object] = useState(props.constructionSite);

    const [errorMessage, setErrorMessage] = useState<string>();

    const [note, setNote] = useState<string>((props.savedHeader && props.savedHeader.note) || '');

    const [client, setClient] = useState<Client | undefined>((props.savedHeader && props.savedHeader.client) || undefined);

    // const [objects, setObjects] = useState<ConstructionSite[]>([]);

    const [objectLots, setObjectLots] = useState<ConstructionLot[]>([]);
    const [objectLot, setObjectLot] = useState<ConstructionLot | undefined>((props.savedHeader && props.savedHeader.objectLot) || undefined); //TODO create hook to simplify

    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [destination, setDestination] = useState<Destination | undefined>((props.savedHeader && props.savedHeader.destination) || undefined);

    const [transportReasons, setTransportReasons] = useState<TransportReason[]>([]);
    const [transportReason, setTransportReason] = useState<TransportReason | undefined>((props.savedHeader && props.savedHeader.transportReason) || undefined);

    // const [transportModes, setTransportModes] = useState<TransportMode[]>([]);
    // const [transportMode, setTransportMode] = useState<TransportMode | undefined>((props.savedHeader && props.savedHeader.transportMode) || undefined);

    const [freight, setFreight] = useState<Freight>();
    const [freights, setFreights] = useState<Freight[]>((props.savedHeader && props.savedHeader.freight) || []);

    const isObjectMandatory = props.task.param_list.MANDATORY_OBJECT === '1';

    useEffect(() => {

        //console.log('request param', props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId)
        useApi.getCompany(props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId).then((res: Client) => {
            if (res) setClient(res)
        }).catch(console.error)

        if (props.constructionSite?.object_id) {
            useApi.getOpenObjectLots(props.constructionSite?.object_id).then((res: ConstructionLot[]) => {
                setObjectLots(res)
            });
        }

        if ((props.constructionSite && props.constructionSite?.object_id && props.constructionSite?.company?.company_id !== undefined) || props.companyId !== undefined)
            useApi.getDestinations(props.constructionSite?.company?.company_id ? props.constructionSite?.company?.company_id : (props?.companyId ? props?.companyId : -1))
                .then((res: Destination[]) => {
                    setDestinations(res);

                    props.constructionSite?.destination_id && setDestination(res.find(item => {
                        return item.destination_id === props.constructionSite?.destination_id
                    }))
                });

        useApi.getTransportReasons().then((res: TransportReason[]) => { setTransportReasons(res) })
        // useApi.getTransportModes().then((res: TransportMode[]) => { setTransportModes(res) })
        useApi.getFreights().then((res: Freight[]) => { setFreights(res) })

    }, [])

    //save on every change
    useEffect(() => {
        const newDoc: StoredNewDocument = {
            date: props.savedDocumentId,
            docType: Number(props.task.param_list.DOCTYPE),
            header: {
                client: client,
                object: object,
                objectLot: objectLot,
                destination: destination,
                transportReason: transportReason,
                // transportMode: transportMode,
                freight: freight,
                note: note
            },
            error: ""
        }
        NewDocumentStorage.save(props.constructionSite?.object_id || props.companyId, props.task?.task_id, newDoc)
        props.onSavedDocumentChanged(newDoc)
    }, [client, object, objectLot, destination, transportReason, freight, note])

    function save() {
        if (client && (props.companyId === undefined ? object : true)) { // objectLot && transportReason && transportMode
            let data: HeaderDDT =
            {
                client: client,
                object: object,
                objectLot: objectLot,
                destination: destination,
                transportReason: transportReason,
                // transportMode: transportMode,
                freight: freight,
                note: note
            }
            props.onSave(data)
            setErrorMessage(undefined)
        } else {
            setErrorMessage(lang.REQUIRED_FIELDS);
        }
    }

    return (
        <View>
            {(object || isObjectMandatory) &&
                <SelectionBox title={lang.CANTIERE}>
                    <Selection
                        title={lang.CANTIERE}
                        icon={'hardHat'}
                        selected={object}
                        itemTitle={(item: ConstructionSite) => item.description}
                        searchString={(item: ConstructionSite) => item.description}
                    // value={objects}
                    // onChange={(item: ConstructionSite) => setObject(item)}
                    />

                    <Selection
                        title={lang.LOTTO}
                        hidden={objectLots?.length === 0}
                        icon={'pencilRuler'}
                        selected={objectLot}
                        value={objectLots}
                        getId={(item: ConstructionLot) => item.object_lot_id}
                        itemTitle={(item: ConstructionLot) => item.description}
                        searchString={(item: ConstructionLot) => item.description}
                        onChange={(item: ConstructionLot) => setObjectLot(item)}
                    />

                </SelectionBox>
            }

            <Section>
                <SelectionCard
                    title={lang.CUSTOMER}
                    icon={'building'}
                    selected={client}
                    itemTitle={(item: Client) => item.description}
                    searchString={(item: Client) => item.description}
                //value={object ? [] : clients}
                //onChange={(item: any) => setClient(item)}
                />

                <SelectionCard
                    title={lang.DESTINATIONS}
                    icon={'mapMarker'}
                    selected={destination}
                    hidden={(props.constructionSite && props.constructionSite.destination_id === undefined) || destinations.length === 0}
                    disabled={props.constructionSite?.destination_id !== undefined}
                    value={destinations}
                    getId={(item: Destination) => item.destination_id}
                    itemTitle={(item: Destination) => item.description}
                    searchString={(item: Destination) => item.description}
                    onChange={(item: Destination) => setDestination(item)}
                />
            </Section>

            <SelectionBox title={lang.TRANSPORT_DATA}>
                <Selection
                    title={lang.TRANSPORT_REASON}
                    icon={'shippingFast'}
                    selected={transportReason}
                    value={transportReasons}
                    // getId={(item: TransportReason) => item.id}
                    itemTitle={(item: TransportReason) => item.description}
                    searchString={(item: TransportReason) => item.description}
                    onChange={(item: TransportReason) => setTransportReason(item)}
                />

                {/* <Selection
                    title={lang.TRANSPORT_MODE}
                    icon={faShippingFast}
                    selected={transportMode}
                    value={transportModes}
                    itemTitle={(item: TransportMode) => item.description}
                    searchString={(item: TransportMode) => item.description}
                    onChange={(item: TransportMode) => setTransportMode(item)}
                /> */}

                <Selection
                    title={lang.FREIGHT}
                    icon={'truckContainer'}
                    selected={freight}
                    value={freights}
                    getId={(item: Freight) => item.id}
                    itemTitle={(item: Freight) => item.description}
                    searchString={(item: Freight) => item.description}
                    onChange={(item: Freight) => setFreight(item)}
                />
            </SelectionBox>




            <View>
                <Label text={lang.NOTE} />
                <Input value={note} onChangeText={setNote} />
            </View>

            <ErrorMessage errorMessage={errorMessage} />


            <Button style={{ marginTop: 20 }} title={lang.NEXT} onPress={() => { save() }} />
        </View>
    )
}