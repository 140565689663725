/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { Icon, ThemeContext, Colors, View, LanguageContext, LoadingSpinnerModal, Layout } from '@infominds/react-native-components';
import { useEffect, useState } from 'react';
import { RefreshControl, TouchableOpacity, StyleSheet, ImageBackground, Platform } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import SettingsModal from '../../modals/SettingsModal';
import useApi from '../../apis/useApi';
import { ConstructionSite, DetailObject, HomeItem, ObjectInfo } from '../../types';
import SelectConstructionSiteItem from '../../cards/SelectConstructionSiteItem'
import { SafeAreaView } from 'react-native-safe-area-context';
import TitleBar from '../../components/TitleBar';
import ObjectContext from '../../contexts/ObjectContext';
import Images from '../../assets/img/BackgroundImages'
import SearchBoxDebounced from '../../components/SearchBoxDebounced';

export default function MainCompanyScreen(props: { navigation: any, route: any, detail?: any, countOfOpenDocuments?: any }) {
  const lang = React.useContext(LanguageContext);
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  // get detail from rout for small devices or from props if large device
  let detail = props.route?.params?.detail ? props.route?.params?.detail as HomeItem : props.detail;
  let countOfOpenDocuments = props.route?.params?.countOfOpenDocuments || props.countOfOpenDocuments || {}

  const { setObject } = React.useContext(ObjectContext);

  const [loading, setLoading] = useState(false);
  const [openDetailLoading, setOpenDetailLoading] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  const [searchText, setSearchText] = useState('');

  let [data, setData] = useState<HomeItem[]>([]);

  async function filterData(dataToFilter: HomeItem[]) {
    const upperSearchText = searchText.toUpperCase();
    return dataToFilter.filter((item: HomeItem) => {
      return item?.title?.toUpperCase().includes(upperSearchText) || item?.object_id.toString().includes(upperSearchText)
    });
  }

  function reload() {
    setLoading(true)
    if (detail && detail.comapny_id > 0)
      useApi.getCompanyObjects(detail.comapny_id)
        .then((res: ConstructionSite[]) => {
          data = []
          if (res)
            res.forEach((constructionSite: ConstructionSite) => {
              var con: HomeItem = {
                object_id: constructionSite.object_id,
                comapny_id: constructionSite.company_id,
                title: constructionSite.description,
                description1: constructionSite.address.street,
                description2: constructionSite.address.place,
                is_object: true
              }
              data.push(con)
            });

          setData(data);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    else
      setLoading(false)
  }

  async function loadData() {
    setData(await filterData(data))
  }

  useEffect(() => {
    loadData();
  }, [searchText])


  useEffect(() => { reload(); }, [detail]) // TODO check this might reload to often in master detail

  const renderItem = ({ item, index }: { item: HomeItem, index: number }) => {
    return (
      <SelectConstructionSiteItem
        style={{}}
        color={theme.card}
        title={item.title}
        description={item.description1}
        description2={item.description2}
        count={item.object_id !== -1 ? countOfOpenDocuments['object' + item.object_id] : 0}
        id={item.is_object ? item?.object_id?.toString() : item?.comapny_id?.toString()}
        icon={item.is_object ? 'hardHat' : 'building'}
        onPress={() => {
          setOpenDetailLoading(true);
          useApi.getObjectDetail(item.object_id).then((res: ObjectInfo) => {
            // console.log('constructionSite', res)
            var info: DetailObject = {
              infoVisible: false,
              object: res
            };
            setObject(info);
            props.navigation.navigate('Home');
          })
            .finally(() => { setOpenDetailLoading(false) })
        }}
      />
    );
  };

  const renderHiddenItem = ({ item }: { item: HomeItem }) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={() => {
          useApi.getObjectDetail(item.object_id).then((res: ObjectInfo) => {
            var info: DetailObject = {
              infoVisible: true,
              object: res
            };
            setObject(info);
            props.navigation.navigate('Home');
          })
        }}>
        <Icon size={28} style={styles.backTextWhite} name="info" />
      </TouchableOpacity>
    </View>
  );

  return (
    <ImageBackground
      source={{ uri: Images.ellipse19 }}
      imageStyle={{ opacity: colorScheme === 'dark' ? 0.8 : 1 }}
      resizeMode='stretch'
      style={{ flex: 1, height: '25%', opacity: 1, backgroundColor: theme.background }}>
      <SafeAreaView>

        {Layout.isSmallDevice &&
          <TitleBar
            title={detail?.title ? detail?.title : 'Ergo Mobile Work'}
            disableBackPress={Layout.isLargeDevice}
            disableSettingsButton={Layout.isLargeDevice}
            onBackPress={() => props.navigation.navigate('Root')}
            onSettingsPress={() => setSettingsVisible(true)}
          />}
        <SettingsModal
          isVisible={settingsVisible}
          close={() => setSettingsVisible(false)}
          onSettingsChange={() => reload()}
          navigation={props.navigation}
        />

        {Layout.isSmallDevice ?
          <View style={{
            marginHorizontal: 5,
          }}>
            <SearchBoxDebounced
              placeholder={lang.SEARCH}
              value={searchText}
              onChangeText={(serachString: string) => { setSearchText(serachString) }}></SearchBoxDebounced>
          </View>
          :
          <View style={{
            marginHorizontal: 5,
            flexDirection: 'row'
          }}>
            <SearchBoxDebounced style={{ flex: 1 }}
              placeholder={lang.SEARCH}
              value={searchText}
              onChangeText={(serachString: string) => { setSearchText(serachString) }}></SearchBoxDebounced>
          </View>}


        <SwipeListView
          style={{ padding: 10 }}
          stickySectionHeadersEnabled={false}
          data={data}
          useSectionList={false}
          renderItem={renderItem}
          ItemSeparatorComponent={() => <View style={{ height: 5 }}></View>}
          keyExtractor={(item: HomeItem, index: number) => item?.object_id?.toString() || index.toString()}
          refreshControl={
            <RefreshControl
              refreshing={loading}
              onRefresh={() => reload()}
              colors={[Colors.tint]}
              tintColor={Colors.tint} />
          }
          renderHiddenItem={renderHiddenItem}
          leftOpenValue={0}
          disableRightSwipe={true}
          rightOpenValue={-75}
          ListFooterComponent={
            <View style={{ marginTop: 5 }}>
              <SelectConstructionSiteItem
                title={lang.CONTINUE_WITHOUT_CONSTRUCTIONSITE}
                icon={'hardHat'}
                onPress={() => {
                  // console.log('constructionSite', undefined)
                  var info: DetailObject = {
                    infoVisible: false,
                    companyId: detail.comapny_id,
                    object: undefined
                  };
                  setObject(info);
                  props.navigation.navigate('Home');
                }}
                count={countOfOpenDocuments['object' + detail.comapny_id]}
                color={theme.card} />
              <View style={{ height: Platform.OS === 'android' ? 200 : 140 }}></View>
            </View>

          }
        // ItemSeparatorComponent={() => <View style={{ height: 6 }}></View>}
        // renderHiddenRow={() => <View></View>}
        />

        <LoadingSpinnerModal visible={openDetailLoading} />
      </SafeAreaView>
    </ImageBackground >
  )
}


const styles = StyleSheet.create({
  rowBack: {
    marginTop: 0,
    marginBottom: 0,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#6A7079',
    marginRight: 6,
    marginLeft: 30,
    borderRadius: 12,

  },
  backTextWhite: {
    color: Colors.white,
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,

  },
  backRightBtnLeft: {
    backgroundColor: '#6A7079',
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: Colors.grey,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    right: 0,
  },
});