import { Colors, Layout, ThemeContext } from '@infominds/react-native-components';
import * as React from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { useState } from 'react';
import IMIcon from './IMIcon';

export default function MasterDetailVisible(props: { master: JSX.Element, detail: JSX.Element, isDetailVisible: boolean }) {
    const colorScheme = React.useContext(ThemeContext);
    const borderColor = colorScheme === 'light' ? Colors.light.background : '#424242';
    const theme = Colors[colorScheme];

    const [masterWidth, setMasterWidth] = useState(400);
    const [splitterSelected, setSplitterSelected] = useState(false);

    return (
        <View style={Platform.OS === 'web' ? { flex: 1 } : { height: '100%' }}>
            {(Layout.isSmallDevice || !props.isDetailVisible) ?
                <View style={{ flex: 1 }}>{props.master}</View>
                :
                <View style={styles.root}>
                    <View style={[styles.masterView, { width: masterWidth }]}>{props.master}</View>
                    <View
                        style={{ width: 15, backgroundColor: splitterSelected ? Colors.grey : 'transparent', justifyContent: 'flex-end', marginBottom: 5 }}
                        onStartShouldSetResponder={(event) => true}
                        onResponderStart={() => setSplitterSelected(true)}
                        onResponderEnd={() => setSplitterSelected(false)}
                        onMoveShouldSetResponder={(event) => true}
                        onResponderMove={(e) => {
                            setMasterWidth(e.nativeEvent.pageX)
                        }}
                    >
                        <IMIcon size={20} color={theme.textPlaceholder} icon={'ellipsisV'} />
                    </View>
                    <View style={[styles.detailView, { borderLeftColor: borderColor }]}>{props.detail}</View>
                </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    root: { flex: 1, flexDirection: 'row', maxHeight: '100%' },
    masterView: { minWidth: 250, maxWidth: 600, borderWidth: 0 },
    detailView: {
        flex: 1,
        height: '100%',
        overflow: 'hidden',
        borderLeftWidth: 1
    },
});