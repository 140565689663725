import * as React from 'react'
import { View, Screen, Layout, Text, LanguageContext } from '@infominds/react-native-components';
import { Platform, SafeAreaView, StatusBar, Linking } from 'react-native';
import { Contact } from '../../types';
import ImageBoxWithText from '../../components/ImageBoxWithText';
import TitleBar from '../../components/TitleBar';
import MapUtils from '../../utils/MapUtils';


export default function DetailContactsScreen(props: { navigation: any, route: any, detail?: Contact }) {
  const lang = React.useContext(LanguageContext)

  // get detail from rout for small devices or from props if large device
  let detail = props.route?.params?.detail as Contact | undefined;
  if (!detail)
    detail = props.detail;


  return (
    <Screen backgroundImage={Layout.isSmallDevice ? 'ellipse6' : 'none'} style={{ padding: 0, marginBottom: 0 }} scrollable={false} >
      <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>
        <TitleBar
          title={detail?.description}
          visible={Layout.isSmallDevice}
          disableBackPress={Layout.isLargeDevice}
          onBackPress={() => props.navigation.navigate('TabContactScreen')}
        />

        {detail &&
          <View style={{ margin: 8 }}>
            <Text style={{ fontSize: 20 }}>{detail?.position}</Text>

            <View style={{ margin: 8 }}>
              {detail?.phone ?
                <ImageBoxWithText
                  style={{ marginTop: 15 }}
                  icon={'phone'}
                  text={detail.phone}
                  onPress={() => { Linking.openURL(`tel:${detail?.phone}`) }} /> : <></>}

              {detail?.phone2 ?
                <ImageBoxWithText
                  style={{ marginTop: 0 }}
                  icon={'phone'}
                  text={detail.phone2}
                  onPress={() => { Linking.openURL(`tel:${detail?.phone2}`) }} /> : <></>}

              {detail?.email ?
                <ImageBoxWithText
                  style={{ marginTop: 0 }}
                  icon={'at'}
                  text={detail.email}
                  onPress={() => { Linking.openURL(`mailto:${detail?.email}`) }} /> : <></>}

              <ImageBoxWithText
                style={{ marginTop: 0 }}
                icon={'map'}
                text={detail.address.street}
                text1={detail.address.place}
                text2={detail.address.zip}
                onPress={() => MapUtils.openMap(detail?.address, lang.NO_DATA_POSSITION)}
              />
            </View>
          </View>
        }
      </SafeAreaView>
    </Screen >
  )
}