import * as React from 'react'
import { useState } from 'react';
import MasterDetail from '../../components/MasterDetail';
import MasterArchiveScreen from './MasterArchiveScreen';
import DetailArchiveScreen from './DetailArchiveScreen';

export default function ArchiveScreen(props: { navigation: any, route: any }) {

  const [detail, setDetail] = useState()

  return (
    <MasterDetail
      positionKey='ArchiveScreen'
      master={
        <MasterArchiveScreen navigation={props.navigation} setDetail={setDetail}></MasterArchiveScreen>
      }
      detail={
        <DetailArchiveScreen navigation={props.navigation} route={props.route} detail={detail}></DetailArchiveScreen>}
    />
  )
}