import getSystemLanguage from "../utils/LanguageUtils";
import ImAnalytics from "../utils/ImAnalytics";
import AsyncStorage from '@react-native-community/async-storage';
import base64 from 'react-native-base64';
import { ApiUtils } from '@infominds/react-native-components'
import { UserInfo } from '../types';
import { LicenseGlobals } from '@infominds/react-native-license';

const useLoginApi = {
    login(username: string, password: string, langstr?: string, mandant?: number) {
        return new Promise(async (resolve, reject) => {
            let language = langstr;
            if (!language) {
                const storedLang = await AsyncStorage.getItem('lang');
                if (storedLang)
                    language = storedLang;
                else
                    language = await getSystemLanguage();
            }

            let mandant_id = mandant;
            if (!mandant_id) {
                mandant_id = LicenseGlobals.mandantId;
            }

            const body = {
                username,
                password: password ? base64.encode(password) : '',
                language,
                mandant_id
            }

            LicenseGlobals.username = body.username;
            if (!LicenseGlobals.baseUrl.includes('/rest/app')) {
                LicenseGlobals.baseUrl += '/rest/app';
            }

            fetch(LicenseGlobals.baseUrl + "/auth", ApiUtils.createPostRequestOptions(LicenseGlobals, body))
                .then(response => {
                    if (response.status === 200 || response.status === 401)
                        return response.json();
                    else
                        throw new Error(response.statusText)
                })
                .then(result => {
                    if (result?.message) {
                        throw new Error(result.message);
                    } else {
                        resolve('Bearer ' + result.access_token);
                        ImAnalytics.trackEvent("Login", { username: body.username });
                    }
                })
                .catch(error => {
                    console.error('Login error', error);
                    reject(error);
                });
        })
    },

    getUserInfo() {
        return new Promise<UserInfo>((resolve, reject) => {
            fetch(LicenseGlobals.baseUrl + "/connect/userinfo", ApiUtils.createPostRequestOptions(LicenseGlobals, {}))
                .then(response => response.json())
                .then(result => resolve(result))
                .catch(error => {
                    console.error('error', error);
                    reject(error);
                });
        })
    },
}

export default useLoginApi;
