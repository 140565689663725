import { Alert, Linking, Platform } from "react-native";
import { Address } from "../types";

function getOSMCoords(address: string) {
    return new Promise((res, rej) => {
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        fetch('https://nominatim.openstreetmap.org/search/' + address + '?format=json&limit=1', requestOptions)
            .then(response => response.json())
            .then(result => {
                let lonLat;
                if (result?.[0]?.lat && result?.[0]?.lon) {
                    lonLat = {
                        lat: result?.[0]?.lat,
                        lon: result?.[0]?.lon
                    }
                }
                res(lonLat)
            }).catch(err => console.error(err));
    });
}

function getGoogleCoords(address: string) {
    return new Promise((res, rej) => {
        var requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        fetch('https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBQpPuh7WuKu-9tEpwreXUBi7vV06bukok&address=' + address, requestOptions)
            .then(response => response.json())
            .then(result => {
                const lonLat = {
                    lat: result?.[0]?.lat,
                    lon: result?.[0]?.lon
                }
                res(lonLat)
            }).catch(err => console.error(err));
    });
}



function getAddress(address: Address): string {
    if (address?.street && address?.place)
        return `${address?.street} ${address?.place} ${address?.zip} ${address?.state}`;
    else
        return '';
}

async function openMap(address: Address, detailText: string) {

    if (address?.longitude && address?.latitude) {

        const lonInt = Number(address?.longitude.replace(',', '.'));
        const latInt = Number(address?.latitude.replace(',', '.'));

        // console.log('founbd long lat', lonInt, latInt)

        if (Platform.OS === 'web')
            Linking.openURL(`https://maps.google.com/?q=${latInt},${lonInt}`);
        else {
            const url = Platform.select({
                ios: `maps:0,0?q=${latInt},${lonInt}`,
                android: `geo:0,0?q=${latInt},${lonInt}`,
            })

            if (url)
                Linking.openURL(url)
        }
    } else {
        const fullAddress = getAddress(address);
        // console.log('founbd fullAddress', fullAddress)
        if (fullAddress !== '') {
            const url = Platform.select({
                ios: `maps:0,0?q=${fullAddress}`,
                android: `geo:0,0?q=${fullAddress}`,
            })

            if (url)
                Linking.openURL(url)
        } else
            Alert.alert("Info", detailText, [{ text: "OK", onPress: () => console.log("OK Pressed") }]);
    }
}

const MapUtils = {
    getCoordsFromAddress(address: string, provider?: 'osm' | 'google') {
        // OSM is default return
        if (provider === 'google')
            return getGoogleCoords(address);
        else
            return getOSMCoords(address);
    },
    openMap(address: Address | undefined, infoText: string) {
        // console.log('address', address)
        if (address)
            openMap(address, infoText)
    }
}

export default MapUtils;