import * as React from 'react'
import { Colors, View, Text, CardBasic, ThemeContext, Layout, LanguageContext } from "@infominds/react-native-components";
import { Pressable, TouchableOpacity } from 'react-native';
import DoctypeUtils from '../utils/DoctypeUtil'
import CreatedDocumentStorage, { CreatedDocument } from '../utils/CreatedDocumentStorage';
import { useContext, useEffect, useState } from 'react';
import Moment from 'moment'
import { ErrorList, ObjectInfo, StoredNewDocument } from '../types';
import { useOrientation } from '../hooks/useOrientation';
import { NewDocumentItem } from '../utils/NewDocumentStorage';
import IMIcon from '../components/IMIcon';

TaskCard.defaultProps = {
    mandatoryObject: true
}

export default function TaskCard(props: {
    title: string,
    taskId: number,
    object: ObjectInfo | undefined,
    description?: string,
    description2?: string,
    docType: number,
    style?: any,
    color?: string
    mandatoryObject: boolean,
    onPress?: () => void,
    rightComponent?: JSX.Element,
    createdDocumentsInfo: CreatedDocument[];
    onCreatedDocumentDeletePress: (item: CreatedDocument) => void;
    savedDocuments: NewDocumentItem | undefined;
    onSavedDocumentSelected: (item: StoredNewDocument) => void;
    onSavedDocumentDeletePress: (item: StoredNewDocument) => void;
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const lang = useContext(LanguageContext);

    const orientation = useOrientation();


    const iconname = DoctypeUtils.getTaskIcon(props.docType)

    const [documentsWaitingForCreate, setDocumentsWaitingForCreate] = useState<CreatedDocument[]>(props.createdDocumentsInfo);

    const sublistHeaderBackgroundColor = colorScheme === 'light' ? theme.inputBorder : '#212830';

    useEffect(() => {
        setDocumentsWaitingForCreate(props.createdDocumentsInfo);
    }, [props.createdDocumentsInfo])

    return (
        <TouchableOpacity disabled={props.onPress === undefined || !props.mandatoryObject} onPress={() => { if (props.onPress) props?.onPress() }}>
            <CardBasic style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: props.color,
                padding: 0,
                elevation: 3
            }}>

                <View style={{
                    backgroundColor: Colors.grey,
                    //marginRight: 10,
                    borderRadius: 8,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: 80,
                    marginVertical: 15,
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <IMIcon color={Colors.white} size={40} icon={DoctypeUtils.getIcon(iconname)} />
                </View>

                <View style={{ flex: 1 }}>
                    <View style={{ padding: 10, paddingLeft: 0, marginLeft: 10 }}>
                        {!!props.title && <Text style={[{ fontWeight: 'bold' }, props.mandatoryObject === false && { color: theme.textPlaceholder }]}>{props.title}</Text>}

                        {!!props.description && <Text style={{ marginBottom: 0, paddingBottom: 0 }}>{props.description}</Text>}
                        {!!props.description2 && <Text style={{ marginTop: 0, paddingTop: 0 }}>{props.description2}</Text>}
                        {/* <Text style={{ marginTop: 0, paddingTop: 0 }}>{JSON.stringify(documentsWaitingForCreate?.documents)}</Text> */}
                        {!props.mandatoryObject && <Text style={{ marginBottom: 0, paddingBottom: 0, color: Colors.modern.red }}>{lang.CONSTRUCTIONSITE_REQUIRED}</Text>}
                    </View>

                    {/* Saved Documents */}
                    {props.savedDocuments && props.savedDocuments.documents && props.savedDocuments.documents.length > 0 &&
                        <View style={{ backgroundColor: theme.inputBackground, borderTopColor: theme.inputBorder, borderTopWidth: 2, paddingLeft: 0, marginLeft: 0 }}>
                            <View style={{ backgroundColor: sublistHeaderBackgroundColor, paddingLeft: 10 }}>
                                <Text>{lang.SAVED_DOCUMENTS}</Text>
                            </View>

                            <View>
                                {props.savedDocuments?.documents.map((item: StoredNewDocument) => {
                                    return (
                                        <View key={item.date} style={{ borderWidth: 0.5, borderColor: theme.inputBorder, borderStartWidth: 0, borderEndWidth: 0 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginRight: 5 }}>
                                                <TouchableOpacity style={{ flex: 1, paddingVertical: 5, padding: 5 }} onPress={() => props.onSavedDocumentSelected(item)}>
                                                    <View style={{ padding: 5, marginLeft: 10, flexDirection: 'row' }}>
                                                        <IMIcon style={{ alignSelf: 'center', justifyContent: 'center' }} color={theme.text} icon={'fileEdit'}></IMIcon>
                                                        <Text>{Moment(item.date).format('DD/MM/YYYY HH:mm')}</Text>
                                                        {item.error !== undefined && <Text style={{ color: Colors.modern.red }}>{item.error}</Text>}
                                                    </View>
                                                </TouchableOpacity>
                                                <TouchableOpacity style={{ justifyContent: 'center', padding: 10 }} onPress={() => props.onSavedDocumentDeletePress(item)}>
                                                    <IMIcon style={{ alignSelf: 'center', justifyContent: 'center' }} color={theme.text} icon={'trash'}></IMIcon>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>}

                    {/* Created Documents */}
                    {documentsWaitingForCreate && documentsWaitingForCreate?.length > 0 &&
                        <Pressable>
                            <View style={{ backgroundColor: theme.inputBackground, borderTopColor: theme.inputBorder, borderTopWidth: 2, paddingLeft: 0, marginLeft: 0 }}>
                                <View style={{ backgroundColor: sublistHeaderBackgroundColor, paddingLeft: 10 }}>
                                    <Text>{lang.SEND_DOCUMENTS}</Text>
                                </View>

                                <View>
                                    {documentsWaitingForCreate?.map((item: CreatedDocument) => {
                                        return (
                                            <View key={item.createDocumentResult} style={{ borderWidth: 0.5, borderColor: theme.inputBorder, borderStartWidth: 0, borderEndWidth: 0 }}>
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 5, padding: 5 }}>
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                                                        <View style={{ marginLeft: 10, justifyContent: 'center' }}>
                                                            <IMIcon color={theme.text} icon={'fileImport'}></IMIcon>
                                                        </View>

                                                        {Layout.isLargeDevice && orientation === 'LANDSCAPE' ?
                                                            <Text style={{ flex: 1, textAlignVertical: 'center', flexWrap: 'wrap' }}>{Moment(item.documentRequest.doc_date).format('DD/MM/YYYY HH:mm')} - {item.ediInfo ? item.ediInfo?.document_status : lang.WAITING_IMPORT_EDI}</Text>
                                                            :
                                                            <View style={{ flex: 1, marginLeft: 5, paddingBottom: 0 }}>
                                                                <Text>{Moment(item.documentRequest.doc_date).format('DD/MM/YYYY HH:mm')}</Text>
                                                                <Text style={{ flexWrap: 'wrap' }}>{item.ediInfo ? item.ediInfo?.document_status : lang.WAITING_IMPORT_EDI}</Text>
                                                            </View>

                                                        }
                                                        <TouchableOpacity style={{ justifyContent: 'center', padding: 10, }}
                                                            onPress={() => {
                                                                props.onCreatedDocumentDeletePress(item)
                                                            }}
                                                        >
                                                            <IMIcon style={{ alignSelf: 'flex-end', justifyContent: 'center' }} color={theme.text} icon={'times'}></IMIcon>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ paddingLeft: 2 }}>
                                                    {item.ediInfo?.error_list?.map((item: ErrorList) => {
                                                        //  if (item.error_kind === 'E')
                                                        return (
                                                            <View style={{ flex: 1, flexDirection: 'row', marginRight: 5 }}>
                                                                <View style={{ width: 15 }}></View>
                                                                <IMIcon style={{ alignSelf: 'center', justifyContent: 'center' }} color={item.error_kind === 'E' ? Colors.modern.red : Colors.yellow} icon={'exclamationTriangle'}></IMIcon>
                                                                <Text style={{ flex: 1, flexWrap: 'wrap', color: item.error_kind === 'E' ? Colors.modern.red : Colors.yellow, }}>{item.message}</Text>
                                                            </View>
                                                        )
                                                    })}
                                                </View>

                                            </View>
                                        )
                                    })}
                                </View>
                            </View>
                        </Pressable>
                    }
                </View>

                {props.rightComponent &&
                    <View style={{ marginRight: 10 }}>
                        {props.rightComponent}
                    </View>
                }
            </CardBasic >
        </TouchableOpacity >
    )
}
