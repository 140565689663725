import * as React from 'react'
import { Colors, View, Text, CardBasic } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';
import IMIcon from '../components/IMIcon'
import { Contact } from '../types';

export default function ContactCard(props: {
    title: string,
    item: Contact,
    description?: string,
    description2?: string,
    iconname: string
    style?: any,
    color?: string,
    onPress?: (item: Contact) => void,
    isSelected: boolean
}) {
    return (
        <CardBasic style={[{
            flex: 1,
            backgroundColor: props.color,
            padding: 0,
            margin: props.isSelected ? 3 : 5,
            elevation: 3
        },
        props.isSelected && { borderColor: Colors.grey, borderWidth: 2 }
        ]}>
            <TouchableOpacity
                style={[{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: props.color,
                    borderRadius: 10,
                }]}
                onPress={() => { if (props.onPress) props.onPress(props.item); }}>
                <View style={{
                    backgroundColor: Colors.grey,
                    marginRight: 10,
                    borderRadius: 8,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    width: 80,
                    marginVertical: 15,
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {props.iconname.includes('DDT') ?
                        <View>
                            <Text style={{ color: Colors.white, fontSize: 24, fontWeight: 'bold' }}>DDT</Text>
                        </View>
                        :
                        <IMIcon color={Colors.white} size={40} icon={'user'} />
                    }
                </View>

                <View style={{ padding: 12, marginBottom: 5 }}>
                    {!!props.title && <Text style={{ fontWeight: 'bold' }}>{props.title}</Text>}
                    {!!props.description && <Text style={{ marginBottom: 0, paddingBottom: 0 }}>{props.description}</Text>}
                    {!!props.description2 && <Text style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }}>{props.description2}</Text>}
                </View>
            </TouchableOpacity>
        </CardBasic >
    )
}
