import * as React from 'react'
import { Colors, ThemeContext } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';

export default function Badge(props: { onPress: any, isChecked: boolean, children: JSX.Element | JSX.Element[] }) {
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <TouchableOpacity
            onPress={() => { props.onPress(props.onPress()); }}
            style={{
                backgroundColor: props.isChecked ? Colors.tint : theme.inputBackground,
                borderRadius: 8,
                marginRight: 10,
                padding: 2,
                paddingHorizontal: 5,
                // borderColor: Colors.white,
                // borderWidth: 1
            }}>
            {props.children}
        </TouchableOpacity>
    )

}
