import * as React from 'react'
import { useContext, useEffect, useState } from 'react';
import { Screen, View, Text, Icon, List, Button, ThemeContext, Colors, LanguageContext, LoadingSpinnerModal } from '@infominds/react-native-components';
import AddArticlesModal from '../../modals/articles/addArticles/AddArticlesModal';
import { Platform, SafeAreaView, StatusBar, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import TitleBar from '../../components/TitleBar';
import SignModal from '../../components/SignModal'
import { CreateDocument, ErgoTask, InvertQuantityResult, StoredNewDocument } from '../../types';
import AddMediaModal from '../../modals/articles/AddMediaModal'
import MediaCard from '../../cards/MediaCard'
import CreateDocumentUtil from '../../utils/CreateDocumentUtil'
import ArticleCard from '../../cards/ArticleCard'
import ArticleDetailModal from '../../modals/articles/ArticleDetailModal';
import NewDocumentStorage from '../../utils/NewDocumentStorage';
import ObjectContext from '../../contexts/ObjectContext';
import useArticlesApi from '../../apis/useArticlesApi';
import { SwipeListView } from 'react-native-swipe-list-view';
import Storage from '../../hooks/useAsyncStorage'
import AsyncStorage from '@react-native-community/async-storage';

export default function NewAddMaterialsScreen(props: { navigation: any, route: any }) {
  const lang = useContext(LanguageContext);
  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const ergoTask = props?.route?.params?.task as ErgoTask;
  const header = JSON.parse(props?.route?.params?.header);

  const [loading, setLoading] = useState(false);

  let savedDocument: StoredNewDocument = JSON.parse(props?.route?.params?.savedDocument);
  let companyId: number = props?.route?.params?.companyId;

  // console.log('savedDocumentsavedDocument', savedDocument)

  const [isMediaVisibloe, setMediaVisible] = useState(false);
  const [isMaterialVisible, setMaterialVisible] = useState(false);
  const [firstClick, setFirstClick] = useState(true);

  const [fastArticleCard, setFastArticleCard] = useState(Platform.OS !== 'android');

  const [infoMode, setInfoMode] = useState(false);

  const [deletemode, setDeletemode] = useState<'' | 'mediaMaterial' | 'material'>('');

  const [mediaMaterialData, setMediaMaterialData] = useState<any[]>((savedDocument && savedDocument.positions && savedDocument.positions.media) ? savedDocument.positions.media : []);
  const [materialData, setMaterialData] = useState<any[]>((savedDocument && savedDocument.positions && savedDocument.positions.material) ? savedDocument.positions.material : []);

  const [selectedArticleDetail, setSelectedArticleDetail] = useState(undefined)
  const [materialDataIndex, setMaterialDataIndex] = useState(-1)
  const [selectedMediaArticle, setSelectedMediaArticle] = useState(undefined)
  const [selectedMediaArticleIndex, setSelectedMediaArticleIndex] = useState(-1)

  const hasSignature = ergoTask?.param_list.SIGNATURE === "1" && Platform.OS !== 'web';
  const [signatureModalVisible, setSignatureModalVisible] = useState(false)

  const [showArticleDetail, setShowAricleDetail] = useState(false)

  const { object } = useContext(ObjectContext)

  const [minusArticles, setMinusArticles] = useState<InvertQuantityResult[]>([])

  const [savedAddArticleType, setSavedAddArticleType] = useState(lang.CATALOGUE)

  async function checkInvertQunatityFlag() {
    // get the flag to invert the value or not
    if (ergoTask.param_list.DOCTYPE === '-6' && ergoTask.param_list.TRANSFER_WAREHOUSE === '1') {
      try {
        let requestList: string[] = []
        materialData.forEach(article => { requestList.push(article.article.article_id) })
        let result = await useArticlesApi.getArticleInverQuantityFlag(requestList)
        setMinusArticles(result)
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function savePos() {
    NewDocumentStorage.savePosition(object?.object?.object_id || companyId, ergoTask?.task_id, savedDocument)
    NewDocumentStorage.savePositionNew(savedDocument.date, savedDocument.positions)
  }

  async function saveMedia() {
    if (savedDocument && (mediaMaterialData.length + materialData.length > 0)) {
      savedDocument.positions = {
        media: mediaMaterialData,
        material: materialData
      }

      savePos();
    }
  }

  const loadArticleType = () => {
    // load add article type 
    Storage('ArticleType').load().then(res => {
      if (res)
        setSavedAddArticleType(res)
    })

    AsyncStorage.getItem('fastArticleCard').then((res: any) => setFastArticleCard(res === "true"));
  }

  // save saved document
  useEffect(() => {
    loadArticleType();

    saveMedia();
    checkInvertQunatityFlag()
  }, [mediaMaterialData, materialData])

  useEffect(() => {
    loadArticleType();
  }, [])

  // const [showErrors, setShowErrors] = useState(false);

  function removeMaterial(index: number) {
    let different = [...materialData];
    different.splice(index, 1)
    setMaterialData(different);
    if (different.length === 0) setDeletemode('');
  }

  function removeMedia(index: number) {
    let different = [...mediaMaterialData];
    different.splice(index, 1)
    setMediaMaterialData(different);
  }

  const ButtonEnabled = () => (mediaMaterialData.length > 0 || materialData.length > 0) && firstClick

  async function save(base64String?: string) {

    if (base64String)
      header.signature = base64String;

    let materialPositions = materialData.map(item => {
      return {
        articleId: item.article.article_id,
        count: item.count,
        media: [],
        title: item.title,
        description: item.note,
        rabat: item.rabat,
        selPrice: item.selPrice,
        buyPrice: item.buyPrice,
        buyRabat: item.buyRabat,
        hasBuyPrice: item.hasBuyPrice,
        hasSellPrice: item.hasSellPrice
      }
    });
    let mediaPositions = mediaMaterialData.map(item => {
      return {
        articleId: ergoTask.param_list.DEFAULT_ARTICLEID,
        count: item.quantity,
        media: item.media,
        title: item.title,
        description: item.note,
        rabat: item.rabat,
        selPrice: item.selPrice,
        buyPrice: item.buyPrice,
        buyRabat: item.buyRabat,
        hasBuyPrice: item.hasBuyPrice,
        hasSellPrice: item.hasSellPrice
      }
    });

    // check if qunatity has minus
    if (ergoTask.param_list.DOCTYPE === '-6' && ergoTask.param_list.TRANSFER_WAREHOUSE === '1') {
      materialPositions.forEach(item => {
        if (hasMinus(item.articleId))
          item.count = -Math.abs(item.count)
      })
    }

    const positions = [...materialPositions, ...mediaPositions]

    const request: CreateDocument = {
      docType: Number(ergoTask?.param_list?.DOCTYPE),
      header: header,
      positions: positions
    }

    CreateDocumentUtil.createDocument(request, ergoTask, savedDocument, object?.object?.object_id || companyId)
      .then(res => {
        // console.debug(res);

        props.navigation.navigate('Home');
      }).catch((error) => {
        setLoading(false)
        setFirstClick(false)
        console.error(error)
      }) // TODO handle error
    // .finally(() => setLoading(false))
  }

  function hasMinus(articleId: string) {
    if (ergoTask.param_list.DOCTYPE === '-6' && ergoTask.param_list.TRANSFER_WAREHOUSE === '1') {
      let res = minusArticles.find(minus => minus.article_id === articleId)
      if (res) {
        return res.invert_quantity
      } else return true
    } else return false
  }


  const renderHiddenItem = ({ item, index }: { item: any, index: number }) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => { removeMaterial(index) }}>
        <Icon size={28} style={styles.backTextWhite} name="trash" />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={() => {
          setShowAricleDetail(true)
          setSelectedArticleDetail(item?.article);
        }}>
        <Icon size={28} style={styles.backTextWhite} name="info" />
      </TouchableOpacity>
    </View>
  );

  return (
    <>
      <Screen backgroundImage='ellipse8' style={{ padding: 0 }} scrollable={false} >
        <SafeAreaView style={{ flex: 1, justifyContent: 'space-between', paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>
          <TitleBar
            title={ergoTask ? ergoTask?.description : lang.NEW_REQUEST}
            onBackPress={() => { props.navigation.pop() }}
          />

          <ScrollView style={{ flex: 1 }} contentContainerStyle={{ justifyContent: 'space-between' }}>

            <LoadingSpinnerModal visible={loading}></LoadingSpinnerModal>

            <View key={'lists'} style={{ paddingHorizontal: 8 }}>

              {!ergoTask.param_list.DEFAULT_ARTICLEID ?
                <View>
                  <Text>{lang.NO_MEDIA_ARTICLE}</Text>
                </View> :
                <List
                  style={{ marginBottom: 20 }}
                  scrollEnabled={false}
                  data={mediaMaterialData}
                  keyExtractor={(item: any, index: any) => item?.title + index.toString()}
                  ListHeaderComponent={() =>
                    <View style={styles.listheader}>
                      <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{lang.MEDIA}</Text>

                      <View style={{ flexDirection: 'row' }}>
                        {(mediaMaterialData.length > 0) &&
                          <Icon
                            style={{ padding: 5, marginRight: 15 }}
                            size={30}
                            name={deletemode === 'mediaMaterial' ? 'x' : 'trash'}
                            onPress={() => deletemode === 'mediaMaterial' ? setDeletemode('') : setDeletemode('mediaMaterial')} />
                        }
                        <Icon
                          size={30}
                          name={'plus-circle'}
                          style={{ padding: 5 }}
                          onPress={() => {
                            setDeletemode("")
                            setSelectedMediaArticle(undefined)
                            setSelectedMediaArticleIndex(-1)
                            setMediaVisible(true)
                          }} />
                      </View>
                    </View>
                  }
                  renderItem={({ item, index }: any) =>
                    <MediaCard
                      key={index.toString()}
                      title={item.title}
                      media={item.media}
                      quantity={item.quantity}
                      onSubmit={() => { setSelectedMediaArticle(item); setSelectedMediaArticleIndex(index); setMediaVisible(true) }}
                      onDelete={() => removeMedia(index)}
                      deletemode={deletemode === 'mediaMaterial'}
                    />
                  }
                />
              }

              <SwipeListView
                style={{ marginBottom: 20 }}
                scrollEnabled={false}
                data={materialData}
                keyExtractor={(item: any, index: any) => item?.article?.article_id + index.toString()}
                renderHiddenItem={renderHiddenItem}
                leftOpenValue={0}
                rightOpenValue={-150}
                recalculateHiddenLayout={true}
                ListHeaderComponent={() =>
                  <View style={styles.listheader}>
                    <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{lang.ARTICLE}</Text>

                    <View style={{ flexDirection: 'row' }}>
                      {(materialData.length > 0) &&
                        <>
                          {deletemode !== 'material' &&
                            <Icon
                              style={{ padding: 5, marginRight: 15 }}
                              size={30}
                              name={'info'}
                              color={theme.text}
                              onPress={() => { setInfoMode(!infoMode) }} />}
                          <Icon
                            style={{ padding: 5, marginRight: 15 }}
                            size={30}
                            name={deletemode === 'material' ? 'x' : 'trash'}
                            color={theme.text}
                            onPress={() => deletemode === 'material' ? setDeletemode('') : setDeletemode('material')} />
                        </>
                      }
                      <Icon
                        size={30}
                        name={'plus-circle'}
                        style={{ padding: 5 }}
                        color={theme.text}
                        onPress={() => {
                          setMaterialVisible(true);
                          setMaterialDataIndex(-1)
                          setDeletemode("")
                        }} />
                    </View>
                  </View>
                }
                renderItem={({ item, index }: any) => (
                  <ArticleCard
                    key={index.toString()}
                    onSubmit={() => { setSelectedArticleDetail(item?.article); setMaterialDataIndex(index); }}
                    taskId={ergoTask?.task_id}
                    title={item?.article?.description}
                    articleId={item?.article?.article_id}
                    subTitle={item?.article?.article_id}
                    minusVisible={() => hasMinus(item?.article?.article_id)}
                    description={item?.count?.toString()?.replace('.', ',') + ' ' + (item?.article?.measure_unit?.description ? item?.article?.measure_unit?.description : '')}
                    onDelete={() => removeMaterial(index)}
                    deletemode={deletemode === 'material'}
                    infomode={infoMode}
                    onInfoPress={() => {
                      setShowAricleDetail(true)
                      setSelectedArticleDetail(item?.article);
                    }} />)
                }
              //ListFooterComponent={<View style={{ height: 100 }}></View>}
              /></View>
          </ScrollView>

          <View style={{ justifyContent: 'flex-end', }}>
            <Button

              style={{ margin: 5 }}
              title={lang.SEND}
              disabled={!ButtonEnabled()}
              onPress={() => {
                setFirstClick(false)
                if (hasSignature)
                  setSignatureModalVisible(true);
                else {
                  setLoading(true);
                  save() //.finally(() => { setLoading(false) });
                }
              }} />
          </View>

        </SafeAreaView>




      </Screen >

      {/* MODALS */}

      {signatureModalVisible &&
        <SignModal
          isVisible={signatureModalVisible}
          placeholder={lang.SIGN_PLACEHOLDER}
          onSignAction={(signature: any) => {
            setLoading(true);
            save(signature?.encoded) //.finally(() => { setLoading(false) });
          }} />}

      {isMaterialVisible &&
        <AddArticlesModal
          // savedType={savedAddArticleType}
          onSubmit={material => {
            setMaterialData([...material])
          }}
          ergoTask={ergoTask}
          init={materialData}
          close={() => { setMaterialVisible(false); setMaterialDataIndex(-1) }}
          isVisible={isMaterialVisible}
          fastMode={fastArticleCard}
        />
      }

      {isMediaVisibloe &&
        <AddMediaModal
          onSubmit={newMedia => {
            // console.log('AddMediaModal', newMedia)
            if (selectedMediaArticleIndex === -1)
              setMediaMaterialData([...mediaMaterialData, ...newMedia])
            else {
              // Todo editing existing with new foto does not worl
              mediaMaterialData[selectedMediaArticleIndex] = newMedia[0];
              setMediaMaterialData(mediaMaterialData)

              saveMedia();
            }
          }}
          ergoTask={ergoTask}
          selected={selectedMediaArticle}
          close={() => { setMediaVisible(false); setSelectedMediaArticle(undefined); setSelectedMediaArticleIndex(-1) }}
          isVisible={isMediaVisibloe}
          addMediaVisible
          titleDescriptionVisible
          showInfoArticle={false}
          selectedArticleDetail={undefined}
        />}

      {selectedArticleDetail !== undefined && !showArticleDetail &&
        <AddMediaModal
          onSubmit={newMedia => {
            let tmp = { ...newMedia[0] }
            if (newMedia[0].quantity)
              tmp.count = newMedia[0].quantity;
            // console.log('AddMediaModal', tmp)
            materialData[materialDataIndex] = { ...materialData[materialDataIndex], ...tmp };
            setMaterialData(materialData)

            saveMedia();
          }}
          ergoTask={ergoTask}
          selected={materialData[materialDataIndex]}
          close={() => { setSelectedArticleDetail(undefined); setSelectedMediaArticleIndex(-1) }}
          isVisible
          addMediaVisible={false}
          titleDescriptionVisible={false}
          showInfoArticle
          selectedArticleDetail={selectedArticleDetail}
          title={materialData[materialDataIndex].article.article_id}
        />}

      {showArticleDetail &&
        <ArticleDetailModal
          article={selectedArticleDetail}
          ergoTask={ergoTask}
          isVisible={showArticleDetail}
          close={() => { setSelectedArticleDetail(undefined); setShowAricleDetail(false); setSelectedMediaArticleIndex(-1) }}
        />
      }
    </>
  )
}

const styles = StyleSheet.create({
  listheader: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  rowBack: {
    flex: 1,
    margin: 6,
    flexDirection: 'row',
    backgroundColor: Colors.modern.red,
    marginRight: 6,
    marginLeft: 30,
    borderRadius: 12,
  },
  backTextWhite: {
    color: '#FFF'
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
    height: '100%',
  },
  backRightBtnLeft: {
    backgroundColor: Colors.modern.red,
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: Colors.grey,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    right: 0,
  },
});