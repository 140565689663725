import * as React from 'react'
import { useState } from 'react';
import MasterDetail from '../../components/MasterDetail';
import MasterContactsScreen from './MasterContactsScreen';
import DetailContactsScreen from './DetailContactsScreen';

export default function ContactsScreen(props: { navigation: any, route: any }) {

  const [detail, setDetail] = useState()

  return (
    <MasterDetail
      positionKey='ContactsScreen'
      master={
        <MasterContactsScreen navigation={props.navigation} setDetail={setDetail}></MasterContactsScreen>
      }
      detail={
        <DetailContactsScreen navigation={props.navigation} route={props.route} detail={detail}></DetailContactsScreen>}
    />
  )
}