import * as React from 'react'
import { Colors, ThemeContext } from "@infominds/react-native-components";
import { View, StyleSheet } from 'react-native';

export default function Separator() {

    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <View style={[styles.separator, { borderBottomColor: theme.inputBorder }]}></View>
    )
}

const styles = StyleSheet.create({
    separator: {
        borderBottomWidth: 1,
        marginVertical: 5,
        borderBottomColor: Colors.icon,
    }
});
