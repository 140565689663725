import * as React from 'react'
import { CardBasic, Colors, Icon, Layout, RenderNode, ThemeContext, Tree, View, Text, LanguageContext, LoadingSpinnerModal } from "@infominds/react-native-components";
import { TouchableOpacity, StyleSheet, ScrollView, RefreshControl, NativeSyntheticEvent, NativeScrollEvent, TextInputEndEditingEventData } from 'react-native';
import NewSearchList from '../../../components/NewSearchList'
import { useCallback, useEffect, useState } from 'react';
import useArticlesApi from '../../../apis/useArticlesApi';
import { ArticlesFromCatalougueResponse, ErgoTask } from '../../../types';
import MasterDetail from '../../../components/MasterDetail';
import SearchBoxBarcode from '../../../components/SearchBoxBarcode';
import ArticleDetailModal from '../ArticleDetailModal';
import IMIcon from '../../../components/IMIcon';
import AddMaterialCardV3 from '../../../cards/AddMaterialCardV3';

export default function ArticlesTree(props: {
    data: any,
    initialQuantity: any,
    onValueChange: any,
    initialSearchText: string,
    onSearchTextChanged: any,
    onFinishSearchTextInput?: any,
    ergoTask: ErgoTask,
    onBarcodePressed: any,
    performnanceBoost: boolean
}) {
    const lang = React.useContext(LanguageContext);
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    let [openItemsTree, setOpenItemsTree] = useState<string[]>([]);
    let [loadingDetailView, setLoadingDetailView] = useState<boolean>(false);

    let [detailArticlesOfCategorie, setDetailArticlesOfCategorie] = useState<any>({});

    let [searchtext, setSearchText] = useState<string>('');

    let [priceListArticles, setPriceListArticles] = useState<any[]>(props.data);
    const [searchResultArticles, setSearchResultArticles] = useState<any[]>([]);

    const [selectedCategorieId, setSelectedCategorieId] = useState<number>(-1);

    const [selectedArticleDetail, setSelectedArticleDetail] = useState(undefined)

    useEffect(() => {
        getDataBySearchText()



    }, [searchtext])

    useEffect(() => { props.initialSearchText && setSearchText(props.initialSearchText) }, [props.initialSearchText])


    function isArticleOfCategorie(categoriesStrins: string[], items: any[]) {
        return items.filter(item => {
            let found = categoriesStrins.filter((cat: string) => {
                let itemGoodCategorieString = item.goodCategories.good_category_id1 + ',' + item.goodCategories.good_category_id2 + ',' + item.goodCategories.good_category_id3 + ',' + item.goodCategories.good_category_id4
                return cat === itemGoodCategorieString || (cat.replaceAll('0', '-1') === itemGoodCategorieString)
            })
            if (item.children.length > 0 && item.isArticle === undefined) {
                item.children = isArticleOfCategorie(categoriesStrins, item.children)
                if (item.children.length > 0)
                    return true;
                else return found.length > 0;
            } else {
                return found.length > 0;
            }
        })
    }

    const getDataBySearchText = () => {

        if (searchtext === '') {
            setPriceListArticles(props.data)
            setSearchResultArticles([])
            return props.data
        }
        setLoadingDetailView(true)

        const priceListID = props.ergoTask.param_list.PRICELISTID
        let apiCall = useArticlesApi.searchArticleFullTextGoodCategorie(searchtext)
        if (priceListID !== undefined && priceListID !== '') {
            apiCall = useArticlesApi.searchArticleFullTextGoodCategorieWithPriceList(searchtext, Number(priceListID));
        }

        apiCall.then((res: any) => {

            let categoriesStrins: string[] = [];
            res.forEach((element: any) => {
                let good_category_string: string = element.good_category.toString();
                if (!categoriesStrins.includes(good_category_string))
                    categoriesStrins.push(good_category_string)
            })
            priceListArticles = props.data;


            //console.log('categoriesStrins', categoriesStrins)
            let tmp = isArticleOfCategorie(categoriesStrins, JSON.parse(JSON.stringify(priceListArticles)))

            //console.log('tmp', tmp)
            setPriceListArticles(tmp)
            //console.log('res', res)
            setSearchResultArticles(res);
            // console.log('res', res[0].id)

            // getArticlesOfCategory(priceListArticles[0])

            mapArticlesForSearch(res, tmp);


        }).finally(() => { setLoadingDetailView(false) })
    }

    function mapArticlesForSearch(searchResultArticles: any[], data: any[]) {
        if (searchtext !== '') {
            detailArticlesOfCategorie = doMapArticles(searchResultArticles, {}, data);
            setDetailArticlesOfCategorie({ ...detailArticlesOfCategorie });
        }
    }

    function doMapArticles(searchResultArticles: any[], detailArticlesOfCategorie: any, children: any[]) {
        children.forEach(node => {
            if (node.goodCategories.good_category_id1 !== undefined) {
                detailArticlesOfCategorie[node.id] = []

                searchResultArticles.forEach(element => {
                    if (((node.goodCategories.good_category_id1 === -1 || element.good_category[0] === 0) || element.good_category[0] === node.goodCategories.good_category_id1) &&
                        ((node.goodCategories.good_category_id2 === -1 || element.good_category[1] === 0) || element.good_category[1] === node.goodCategories.good_category_id2) &&
                        ((node.goodCategories.good_category_id3 === -1 || element.good_category[2] === 0) || element.good_category[2] === node.goodCategories.good_category_id3) &&
                        ((node.goodCategories.good_category_id4 === -1 || element.good_category[3] === 0) || element.good_category[3] === node.goodCategories.good_category_id4)) {
                        detailArticlesOfCategorie[node.id].push(element);
                    }
                });
                setOpenItemTree(node.id)
                if (node.children.length !== 0)
                    return doMapArticles(searchResultArticles, detailArticlesOfCategorie, node.children);
            }
        })
        return detailArticlesOfCategorie;
    }

    function setOpenItemTree(id: string) {
        console.log(id)
        const index = openItemsTree.indexOf(id);
        if (index === -1) openItemsTree.push(id);
        else openItemsTree.splice(index, 1);
        setOpenItemsTree([...openItemsTree])
        // console.log(openItemsTree)
    }

    async function getArticlesOfCategory(node: any) {
        console.log(openItemsTree)
        if (node.goodCategories) {
            setDetailArticlesOfCategorie({})
            const hasSearchText = searchResultArticles.length === 0 && searchtext === '';
            if (hasSearchText) {
                if (!props.ergoTask.param_list.PRICELISTID)
                    node.goodCategories.price_list_id = Number(props.ergoTask.param_list.PRICELISTID);
                useArticlesApi.getArticlesFromCatalouge(node.goodCategories)
                    .then((res: ArticlesFromCatalougueResponse[]) => {
                        if (Layout.isSmallDevice && !node.hasChildrenNodes) {
                            setOpenItemTree(node.id)
                        }
                        detailArticlesOfCategorie[node.id] = res;
                        setDetailArticlesOfCategorie({ ...detailArticlesOfCategorie });
                    }).finally(() => { setLoadingDetailView(false) })
            } else {
                if (Layout.isSmallDevice) {
                    setOpenItemTree(node.id)
                }

                detailArticlesOfCategorie[node.id] = [];
                searchResultArticles.forEach(element => {
                    if (((node.goodCategories.good_category_id1 === -1 || element.good_category[0] === 0) || element.good_category[0] === node.goodCategories.good_category_id1) &&
                        ((node.goodCategories.good_category_id2 === -1 || element.good_category[1] === 0) || element.good_category[1] === node.goodCategories.good_category_id2) &&
                        ((node.goodCategories.good_category_id3 === -1 || element.good_category[2] === 0) || element.good_category[2] === node.goodCategories.good_category_id3) &&
                        ((node.goodCategories.good_category_id4 === -1 || element.good_category[3] === 0) || element.good_category[3] === node.goodCategories.good_category_id4))
                        detailArticlesOfCategorie[node.id].push(element)
                });
                setDetailArticlesOfCategorie({ ...detailArticlesOfCategorie });
                setLoadingDetailView(false)
            }
        }
        console.log(detailArticlesOfCategorie)
    }



    const Footer = () => (<View style={{ height: 20 }}></View>)

    const [scrollPosition, setScrollPosition] = useState(0);

    function PriceListTreeMaster(propsPriceList: { style?: any }) {
        const scrollView = React.useRef<ScrollView>()

        // used to keep the position of the scroll view after state update
        useEffect(() => {
            scrollView.current?.scrollTo({ x: 0, y: scrollPosition, animated: false })
        }, [scrollPosition])

        const onNodePress = ({ node, level }: any) => {
            if (node.hasChildrenNodes)
                setOpenItemTree(node.id)

            if (Layout.isSmallDevice) {
                setSelectedCategorieId(node.id)

                if (!node.hasChildrenNodes) {
                    setLoadingDetailView(true);
                    getArticlesOfCategory(node).finally(() => setLoadingDetailView(false))
                }
            }
        }

        return (
            <ScrollView
                //@ts-ignore
                ref={scrollView}
                scrollEventThrottle={16}
                onScrollEndDrag={(event: NativeSyntheticEvent<NativeScrollEvent>) => {
                    if (Layout.isLargeDevice) {
                        setScrollPosition(event?.nativeEvent?.contentOffset?.y || 0)
                    }
                }}
                style={propsPriceList.style} >
                <Tree
                    //@ts-ignore
                    onNodePress={onNodePress}
                    //@ts-ignore
                    isNodeExpanded={id => { return openItemsTree.includes(id) }}
                    data={priceListArticles}
                    renderNode={renderTreeItem}
                />
                <Footer />
            </ScrollView >
        )
    }

    const Indicator = (IndicatorProps: { up: boolean }) => (
        <IMIcon
            style={{ alignSelf: 'flex-start', marginTop: 10, marginLeft: 5 }}
            size={15}
            color={theme.text}
            icon={IndicatorProps.up ? 'chevronUp' : 'chevronDown'} />
    )

    const getIndicator = (isExpanded: boolean, hasChildrenNodes: boolean) => {
        if (!hasChildrenNodes) {
            return <></>;
        } else if (isExpanded) {
            return <Indicator up />
        } else {
            return <Indicator up={false} />;
        }
    };

    const renderItem = ({ item, index }: any) => {
        return (
            <AddMaterialCardV3
                item={item}
                performanceBoost={props.performnanceBoost}
                initialQuantity={props?.initialQuantity ? props?.initialQuantity(item) : 0}
                onValueChange={(item: any, newValue: number) => {
                    if (props.onValueChange) props.onValueChange(item, newValue);
                }} />)
    }

    const RefreshView = (
        <RefreshControl
            refreshing={loadingDetailView}
            colors={[Colors.tint]}
            tintColor={Colors.tint} />
    )

    const renderTreeItem = ({ node, level, isExpanded, hasChildrenNodes }: RenderNode) => {
        return (
            <TouchableOpacity
                // disabled={hasChildrenNodes}
                onPress={() => {
                    if (!hasChildrenNodes) {
                        setLoadingDetailView(true);
                        setSelectedCategorieId(node.id)
                        getArticlesOfCategory(node).finally(() => setLoadingDetailView(false))
                    } else {
                        setOpenItemTree(node.id)
                    }
                }}>
                <CardBasic
                    style={[{ marginLeft: 25 * level, flexDirection: 'row', padding: 2 }, (selectedCategorieId === node.id && isExpanded) && { borderWidth: 2, borderColor: Colors.grey }]}>

                    <View style={{ width: '100%', flexDirection: 'row', alignContent: 'center' }}>
                        {getIndicator(isExpanded, hasChildrenNodes)}
                        <View style={{ flex: 1, justifyContent: 'flex-start', marginTop: 5, paddingBottom: 0 }}>
                            <Text >{node.description}</Text>
                            {/* <LoadingIndicator isVisible={loadingDetailView}></LoadingIndicator> */}
                        </View>
                    </View>

                </CardBasic>
                {Layout.isSmallDevice && !hasChildrenNodes && detailArticlesOfCategorie[node.id] !== undefined &&
                    detailArticlesOfCategorie[node.id].length > 0 && isExpanded && //selectedCategorieId === node.id &&
                    < NewSearchList
                        keyExtractor={getKey}
                        style={{ backgroundColor: theme.backgroundModal }}
                        searchBarOverlay={false}
                        headerStyle={{ backgroundColor: theme.backgroundModal }}
                        data={detailArticlesOfCategorie[node.id]}
                        renderItem={renderItem}
                        refreshing={loadingDetailView}
                        refreshControl={RefreshView}
                        footer={(<View style={{ height: 20 }}></View>)}
                        renderHiddenItem={renderHiddenItem}
                        //@ts-ignore
                        leftOpenValue={0}
                        disableRightSwipe={true}
                        rightOpenValue={-75}
                    />
                }
            </TouchableOpacity >
        );
    }

    const renderHiddenItem = ({ item }: { item: any }) => (
        <View style={styles.rowBack}>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnLeft]}
                onPress={() => {
                    if (props.onValueChange) props.onValueChange(item, 0);
                    setSelectedArticleDetail(item)
                }}>
                <Icon size={28} style={styles.backTextWhite} name="trash" />
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnRight]}
                onPress={() => {
                    setSelectedArticleDetail(item)
                }}>
                <Icon size={28} style={styles.backTextWhite} name="info" />
            </TouchableOpacity>
        </View>
    );

    const getKey = useCallback((item: any, index: number) => item.article_id ? item.article_id : index.toString(), [])

    const SmallDeviceView = () => (
        <View style={{ flex: 1, backgroundColor: Colors.grey }} >
            <View style={{ paddingHorizontal: 10, paddingTop: 0, paddingBottom: 5 }}>
                <SearchBoxBarcode style={{ marginBottom: 10 }}
                    placeholder={lang.SEARCH}
                    value={searchtext}
                    barcodeScannerVisible={true}
                    onBarcodePress={() => { props.onBarcodePressed() }}
                    onChangeText={text => { props.onSearchTextChanged && props.onSearchTextChanged(text) }}
                    onEndEditing={(e: NativeSyntheticEvent<TextInputEndEditingEventData>) => props.onFinishSearchTextInput(e.nativeEvent.text)}
                />
            </View>
            <View style={{ paddingVertical: 0, backgroundColor: theme.backgroundModal }}>
                <PriceListTreeMaster style={{ padding: 10, marginBottom: 20, height: '100%' }} />
            </View>
        </View>
    )

    const LargeDeviceView = () => (
        <>
            <View style={{ paddingHorizontal: 10, paddingTop: 0, paddingBottom: 5, backgroundColor: Colors.grey }}>
                <SearchBoxBarcode style={{ marginBottom: 10 }}
                    placeholder={lang.SEARCH}
                    value={searchtext}
                    barcodeScannerVisible={true}
                    onBarcodePress={() => { props.onBarcodePressed() }}
                    onChangeText={text => { props.onSearchTextChanged && props.onSearchTextChanged(text) }}
                    onEndEditing={(e: NativeSyntheticEvent<TextInputEndEditingEventData>) => props.onFinishSearchTextInput(e.nativeEvent.text)}
                />
            </View>
            <View style={{ padding: 10, height: '100%' }} >
                <MasterDetail
                    positionKey='articleTree'
                    master={
                        <PriceListTreeMaster />
                    }
                    detail={
                        <NewSearchList
                            keyExtractor={getKey}
                            style={{ backgroundColor: theme.backgroundModal }}
                            searchBarOverlay={false}
                            headerStyle={{ backgroundColor: theme.backgroundModal }}
                            data={detailArticlesOfCategorie[selectedCategorieId]}
                            onSearchTextChanged={(text: string) => { props.onSearchTextChanged && props.onSearchTextChanged(text) }}
                            renderItem={renderItem}
                            refreshing={loadingDetailView}
                            refreshControl={RefreshView}
                            footer={(<View style={{ height: 20 }}></View>)}
                            renderHiddenItem={renderHiddenItem}
                            //@ts-ignore
                            leftOpenValue={0}
                            disableRightSwipe={true}
                            rightOpenValue={-75}
                        />
                    }
                />
            </View>
        </>
    )

    if (selectedArticleDetail !== undefined) {
        return (
            <ArticleDetailModal
                ergoTask={props.ergoTask}
                article={selectedArticleDetail}
                isVisible={selectedArticleDetail !== undefined}
                close={() => setSelectedArticleDetail(undefined)} />
        )
    }

    return (
        <View style={{ backgroundColor: theme.backgroundModal, flex: 1 }}>
            {Layout.isSmallDevice ?
                <SmallDeviceView /> : <LargeDeviceView />
            }
            <LoadingSpinnerModal visible={loadingDetailView}></LoadingSpinnerModal>
        </View>
    )
}


const styles = StyleSheet.create({
    rowBack: {
        marginTop: 5,
        marginBottom: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Colors.modern.red,
        marginRight: 6,
        marginLeft: 30,
        borderRadius: 12,
    },
    backTextWhite: {
        color: Colors.white
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
        height: '100%',
    },
    backRightBtnLeft: {
        backgroundColor: Colors.modern.red,
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: Colors.grey,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        right: 0,
    }
});