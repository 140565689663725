import * as React from 'react'
import { Colors, View, Text } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';
import IMIcon from './IMIcon';
import { IMIconName } from './IMIconNames';

export default function ImageBoxWithText(props: {
    icon: IMIconName
    style?: any,
    color?: string,
    text?: string,
    textStyle?: any,
    text1Style?: any,
    text1?: string,
    text2?: string,
    text3?: string,
    onPress?: () => void,
    iconSize?: number,
    minHeight?: number
}) {
    const textStyle = props.textStyle ? props.textStyle : {}
    const text1Style = props.text1Style ? props.text1Style : {}

    return (
        <View style={[{ flexDirection: 'row' }, props.style]}>
            <TouchableOpacity disabled={props.onPress == undefined} onPress={() => { if (props.onPress) props?.onPress(); }}>
                <View style={{
                    backgroundColor: Colors.grey,
                    marginRight: 10,
                    borderRadius: 10,
                    width: props.iconSize ? (props.iconSize + 20) : 60,
                    height: props.iconSize ? (props.iconSize + 20) : 60,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <IMIcon
                        icon={props.icon}
                        size={props.iconSize || 38}
                        color={props.onPress == undefined ? Colors.white : Colors.tint} />
                </View>
            </TouchableOpacity>

            <TouchableOpacity style={{ flex: 1 }} disabled={props.onPress == undefined} onPress={() => { if (props.onPress) props?.onPress(); }}>
                <View style={{ minHeight: props.minHeight || 80, marginRight: 5 }}>
                    <Text style={[{ marginTop: 0, marginRight: 50, fontWeight: 'bold', flexWrap: 'wrap' }, textStyle]}>{props.text}</Text>
                    <Text style={[{ marginTop: 0, marginBottom: 0, flexWrap: 'wrap' }, text1Style]}>{props.text1}</Text>
                    {props.text2 ? <Text style={{ marginTop: 2, flexWrap: 'wrap' }}>{props.text2}</Text> : <></>}
                    {props.text3 ? <Text style={{ marginTop: 2, flexWrap: 'wrap' }}>{props.text3}</Text> : <></>}
                </View>
            </TouchableOpacity >
        </View >

    )
}
