import * as React from 'react'
import { LanguageContext, View, Screen, Layout, Text, ThemeContext, Colors, SectionList } from '@infominds/react-native-components';
import { Platform, SafeAreaView, StatusBar } from 'react-native';
import { ArticleHistory, DocumentArticleHistory, Serialnumber } from '../../types';
import TitleBar from '../../components/TitleBar';
import DetailArchiveCard from '../../cards/DetailArchiveCard';
import Moment from 'moment'
import DoctypeUtil from '../../utils/DoctypeUtil';
import usePersistenceApi from '../../apis/usePeristenceApi';
import { useContext, useEffect, useState } from 'react';

type SectionData = {
  title: string;
  sum: number;
  data: DocumentArticleHistory[];
}

export default function DetailArchiveScreen(props: { navigation: any, route: any, detail?: ArticleHistory }) {
  const lang = useContext(LanguageContext);

  const colorScheme = useContext(ThemeContext);
  const theme = Colors[colorScheme];

  let [data, setData] = useState<SectionData[]>([]);
  const [serialnumbers, setSerialnumbers] = useState<Serialnumber[]>([])

  // get detail from rout for small devices or from props if large device
  let detail = props.route?.params?.detail as ArticleHistory | undefined;
  if (!detail)
    detail = props.detail;

  useEffect(() => {
    usePersistenceApi.getSerialnumbers((res: Serialnumber[]) => { setSerialnumbers(res) }, () => { });
  }, [])

  useEffect(() => {
    data = [];
    detail?.document.forEach(function (item: DocumentArticleHistory) {
      var docID = getDocIdAsString(item);
      var found = data.find(groupItem => groupItem.title === docID)
      if (found) {
        found.sum += item.quantity;
        found.data.push(item);
      } else {
        data.push({ title: docID, sum: item.quantity, data: [item] })
      }
    });
    setData(data)
  }, [props.detail])

  function findSerialnumber(serialNumber: number, docType: number) {
    // console.log(serialNumber, docType)
    return serialnumbers.find((item: Serialnumber) => {
      return item.serial_nr === serialNumber && item.doc_type === docType
    })
  }

  function getDocIdAsString(item: DocumentArticleHistory) {
    const detailSeriualnumber = findSerialnumber(item.serial_nr, item.doc_type)
    let serialNrDesc = ''
    if (detailSeriualnumber)
      serialNrDesc = detailSeriualnumber?.description;
    return `${DoctypeUtil.getLanguageShortForDocumentsInArchive(item.doc_type, lang)} ${serialNrDesc} ${item.serial_year}/${item.serial_nr}/${item.doc_id}`;  // /${item?.serial_nr}
  }

  const renderItem = ({ item, index }: { item: DocumentArticleHistory, index: number }) => (
    <DetailArchiveCard
      color={theme.card}
      title={getDocIdAsString(item)}
      description={Moment(item.doc_date).format('DD/MM/YYYY')}
      docType={item.doc_type}
      rightComponent={
        <View>
          <Text>{item.quantity} {detail?.measure_unit?.description}</Text>
        </View>}
    />
  )

  const renderSectionHeader = ({ section: { title, sum } }: any) => (
    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
      <Text style={{ marginLeft: 8, fontSize: 18 }}>{title}</Text>
      {sum ? <Text style={{ marginLeft: 8, fontSize: 18, marginRight: 10 }}>{sum} {detail?.measure_unit?.description}</Text> : <></>}
    </View>
  )

  return (
    <Screen backgroundImage={Layout.isSmallDevice ? 'ellipse6' : 'none'} style={{ padding: 0, marginBottom: 0 }} scrollable={false} >
      <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>
        <TitleBar
          title={detail?.description}
          visible={Layout.isSmallDevice}
          disableBackPress={Layout.isLargeDevice}
          onBackPress={() => props.navigation.navigate('TabArchiveScreen')}
        />

        {detail &&
          <View style={{ margin: 8, marginBottom: 0, flex: 1, }}>
            <SectionList
              style={[{ height: '100%' }]}
              stickySectionHeadersEnabled={false}
              sections={data}
              renderItem={renderItem}
              keyExtractor={(item: DocumentArticleHistory, index: any) => index}
              renderSectionHeader={renderSectionHeader}
              renderSectionFooter={() => (<View style={{ height: 15 }}></View>)}
            />
          </View>
        }

        {/* <ArticleDetailModal
          articleId={selectedArticleDetail}
          isVisible={selectedArticleDetail !== undefined}
          close={() => setSelectedArticleDetail(undefined)} /> */}
      </SafeAreaView>
    </Screen >
  )
}