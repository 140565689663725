import AsyncStorage from '@react-native-community/async-storage';
import React, { useContext, useEffect } from 'react'
import { View, Text, Image, StyleSheet, Platform } from 'react-native';
import AppIntroSlider from 'react-native-app-intro-slider';
import { Colors, LanguageContext } from '@infominds/react-native-components';

export interface SliderLanguageData {
    it: SliderData[],
    de: SliderData[],
    en?: SliderData[]
}

export interface SliderData {
    title: string;
    text: string;
    image: any;
}

export default function IntroSlider(props: { data: SliderLanguageData, onDone: () => void }) {

    const lang = useContext(LanguageContext)

    let data = props.data.de;
    if (lang.ID === "de" && props.data.de)
        data = props.data.de;
    if (lang.ID === "it" && props.data.it)
        data = props.data.it;
    if (lang.ID === "en" && props.data.en)
        data = props.data.en;


    useEffect(() => {
        AsyncStorage.getItem('intro').then(intro => { if (intro) props.onDone(); });
    }, []);

    function saveAndClose() {
        AsyncStorage.setItem('intro', 'true');
        props.onDone();
    }

    const RenderItem = ({ item }: { item: SliderData }) => (
        <View style={[introStyles.slide, Platform.OS !== 'web' ? { flex: 1 } : {}, { backgroundColor: Colors.tint }]}>
            <Text style={introStyles.title}>{item.title}</Text>
            <Image source={item.image} style={introStyles.image} resizeMode="contain" />
            <Text style={introStyles.text}>{item.text}</Text>
        </View>
    )

    return (
        <AppIntroSlider
            showSkipButton
            renderItem={RenderItem}
            data={data}
            onSkip={saveAndClose}
            onDone={saveAndClose}
        />
    )
}

const introStyles = StyleSheet.create({
    slide: {
        alignItems: 'center',
        justifyContent: 'center',
        height: window.innerHeight
    },
    image: {
        // width: 320,
        maxWidth: '80%',
        height: '40%',
        marginVertical: 32,
        borderRadius: 15
    },
    text: {
        paddingHorizontal: 40,
        fontSize: 18,
        color: 'rgba(255, 255, 255, 0.8)',
        textAlign: 'center',
    },
    title: {
        fontSize: 22,
        color: 'white',
        textAlign: 'center',
    },
});
