import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Client, ConstructionLot, ConstructionSite, Destination, ErgoTask, HeaderOF, ObjectInfo, Supplier } from '../../../types'
import useApi from '../../../apis/useApi'
import SelectionCard from '../../../cards/SelectionCard';
import { Button, ImValidators, InputDateTime, Label, LanguageContext, Note } from "@infominds/react-native-components";
import Section from "../../../components/Section";
import ErrorMessage from "../../../components/ErrorMessage";
import NewDocumentStorage from "../../../utils/NewDocumentStorage"
import SelectionBox from "../../../cards/SelectionBox";
import Selection from "../../../cards/Selection";


export default function NewHeaderOF(props: { constructionSite?: ObjectInfo, companyId?: number, task: ErgoTask, onSave: any, onSavedDocumentChanged: any, savedDocumentId: string, savedHeader: any }) {
    const lang = useContext(LanguageContext);

    const [errorMessage, setErrorMessage] = useState<string>();

    const [selectedConstructionSite] = useState(props.constructionSite);

    const [date, setDate] = useState<Date | undefined>((props.savedHeader && new Date(props.savedHeader.date)) || new Date());
    const [note, setNote] = useState<string>((props.savedHeader && props.savedHeader.note) || '');

    const [client, setClient] = useState<Client | undefined>(undefined);

    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [supplier, setSupplier] = useState<Supplier | undefined>((props.savedHeader && props.savedHeader.supplier) || undefined);

    const [objectLots, setObjectLots] = useState<ConstructionLot[]>([]);
    const [objectLot, setObjectLot] = useState<ConstructionLot | undefined>((props.savedHeader && props.savedHeader.objectLot) || undefined);

    const [destinations, setDestinations] = useState<Destination[]>([]);
    const [destination, setDestination] = useState<Destination | undefined>((props.savedHeader && props.savedHeader.destination) || undefined);

    useEffect(() => {
        useApi.getSuppliers().then((res: Supplier[]) => { setSuppliers(res) })
        useApi.getCompany(props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId).then((res: Client) => {
            if (res) setClient(res)
        })

        if (props.constructionSite?.object_id) {
            useApi.getOpenObjectLots(props.constructionSite?.object_id).then((res: ConstructionLot[]) => { setObjectLots(res) });
        }
    }, [])

    useEffect(() => {
        if (supplier !== undefined) {
            useApi.getDestinations(supplier?.id).then((res: Destination[]) => { setDestinations(res) });
        }
    }, [supplier])

    //save on every change
    useEffect(() => {
        const newDoc = {
            date: props.savedDocumentId,
            docType: Number(props.task.param_list.DOCTYPE),
            header: {
                supplier: supplier,
                objectLot: objectLot,
                destination: destination,
                date: date,
                note: note,
                companyIdForCount: client?.id
            }
        }
        NewDocumentStorage.save(props.constructionSite?.object_id || props.companyId, props.task?.task_id, newDoc)
        props.onSavedDocumentChanged(newDoc)
    }, [supplier, selectedConstructionSite, objectLot, destination, date, note])

    function save() {
        if (client && (props.companyId === undefined ? selectedConstructionSite : true) && date && supplier) { // objectLot
            let data: HeaderOF = {
                client: supplier, // client has to be the supplier because of ergo logic
                object: selectedConstructionSite,
                objectLot: objectLot,
                destination: destination,
                deliveryDate: date,
                note: note
            }
            props.onSave(data)
            setErrorMessage(undefined)
        } else {
            setErrorMessage(lang.REQUIRED_FIELDS);
        }
    }

    return (
        <View>
            {selectedConstructionSite &&
                <SelectionBox title={lang.CANTIERE}>
                    <Selection
                        title={lang.CANTIERE}
                        icon={'hardHat'}
                        selected={selectedConstructionSite}
                        getId={(item: ConstructionSite) => item.object_id}
                        itemTitle={(item: ConstructionSite) => item.description}
                        searchString={(item: ConstructionSite) => item.description}
                    />
                    <Selection
                        title={lang.LOTTO}
                        icon={'pencilRuler'}
                        hidden={objectLots?.length === 0}
                        selected={objectLot}
                        value={objectLots}
                        getId={(item: ConstructionLot) => item.object_lot_id}
                        itemTitle={(item: ConstructionLot) => item.description}
                        searchString={(item: ConstructionLot) => item.description}
                        onChange={(item: ConstructionLot) => setObjectLot(item)}
                    />
                </SelectionBox>
            }

            <Section>
                <SelectionCard
                    title={lang.SUPPLIER}
                    icon={'truck'}
                    selected={supplier}
                    required
                    showError={!!errorMessage && !supplier}
                    value={suppliers}
                    getId={(item: Client) => item.id}
                    itemTitle={(item: Client) => item.description}
                    searchString={(item: Client) => item.description}
                    onChange={(item: any) => setSupplier(item)}
                />

                <SelectionCard
                    title={lang.DESTINATION_SUPPLIER}
                    icon={'mapMarker'}
                    selected={destination}
                    value={destinations}
                    hidden={destinations.length === 0}
                    disabled={destinations.length === 0}
                    getId={(item: Destination) => item.destination_id}
                    itemTitle={(item: Destination) => item.description}
                    searchString={(item: Destination) => item.description}
                    onChange={(item: Destination) => setDestination(item)}
                />
            </Section>

            <View>
                <Label text={lang.DUE_DATE} required />
                <InputDateTime
                    value={date}
                    style={ImValidators.requiredInput(date, !!errorMessage)}
                    onDelete={() => setDate(undefined)}
                    onConfirm={setDate}
                    text={''}
                    mode='date' />
            </View>

            <View>
                <Label text={lang.NOTE} />
                <Note value={note} onChangeText={(text: string) => setNote(text)} />
            </View>

            <ErrorMessage errorMessage={errorMessage} />

            <Button style={{ marginTop: 20 }} title={lang.NEXT} onPress={() => { save() }} />
        </View>
    )
}