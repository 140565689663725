import * as React from 'react'
import { Layout } from "@infominds/react-native-components";
import { View } from 'react-native';
import { useOrientation } from '../hooks/useOrientation'

export default function Separator({ children }: { children: JSX.Element | JSX.Element[] }) {

    const orientation = useOrientation();

    return (
        <View style={Layout.isLargeDevice && orientation === 'LANDSCAPE' && { flex: 1, width: '100%', flexDirection: 'row' }}>
            {children}
        </View>
    )
}