import { ArticleDetailWork, ArticleHistory, ArticlesFromCatalougueRequest, ArticlesFromCatalougueResponse, InvertQuantityResult, MaterialArticle } from '../types';
import { LicenseGlobals } from '@infominds/react-native-license';
import { GET, POST } from '@infominds/react-native-components';

export default {
    getArticles: () => GET<MaterialArticle[]>(LicenseGlobals, "/articles/all"),

    getArticlesCatalouge: (id: number) => GET<any[]>(LicenseGlobals, "/catalougue/pricelist/" + id),

    getErgoMobileWorkArticlesCatalouge: (id: number) => GET<any[]>(LicenseGlobals, "/ErgoMobileWork/pricelist/" + id),

    getArticlesFromCatalouge: (request: ArticlesFromCatalougueRequest) => POST<ArticlesFromCatalougueResponse[]>(LicenseGlobals, "/catalougue/articles/", { validt_to: true, ...request }),

    getFavoriteArticles: () => GET<MaterialArticle[]>(LicenseGlobals, "/articles/favorites"),

    searchArticleFullText: (searchText: string, docType: number, serialNr: number) => POST<any[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            valid_to: true,
            document_filter: {
                doc_type: docType,
                serial_nr: serialNr
            }
        }),

    searchArticleFullTextDocumentsHistory: (searchText: string, docType: number, numberOfDays: number) => POST<any[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            valid_to: true,
            document: true,
            document_filter: {
                doc_type: docType,
                //_serial_year: 2020,
                number_of_days: numberOfDays,
            }
        }),

    // TODO remove document info as it is not always usefull
    searchArticleFullTextDocumentsHistoryWithoutDocuments: (searchText: string, docType: number, numberOfDays: number, objectId?: number) => POST<any[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            valid_to: true,
            document: false,
            document_filter: {
                doc_type: docType,
                //_serial_year: 2020,
                number_of_days: numberOfDays,
                object_id: objectId ? objectId : 0
            }
        }),

    searchArticleFullTextDocumentsHistoryByObject: (searchText: string, docType: number, numberOfDays: number, objectId: number) => POST<ArticleHistory[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            valid_to: true,
            document: true,
            document_filter: {
                doc_type: docType,
                //_serial_year: 2020,
                number_of_days: numberOfDays,
                object_id: objectId
            }
        }),



    searchArticleFullTextGoodCategorie: (searchText: string) => POST<any[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            good_category: true,
            valid_to: true,
        }),

    searchArticleFullTextGoodCategorieWithPriceList: (searchText: string, priceListId: number) => POST<any[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            good_category: true,
            valid_to: true,
            price_list_id: priceListId
        }),


    getArticleDetail: (taskId: number, articleId: string) => GET<ArticleDetailWork>(LicenseGlobals, `/ErgoMobileWork/articledetail/taskid/${taskId}/articleid/${articleId}`),

    getBarcodeDecode: (barcode: string) => POST<any>(LicenseGlobals, '/searchbarcode/decode', { barcode: barcode }),

    searchArticleByBarcode: (ean: string) => POST<any>(LicenseGlobals, '/searchbarcode', {
        search_article:
        {
            mode: "EAN",
            barcodes: [ean]
        },
        ware_house_id: 1,
        // company_id: 1, 
        is_buy: true, //TODO //3 false //4 true //6
        //batch_id: batchId, 
        search_from_art_package_type: true,
        get_act_stock: false,
        get_price: false
        //create_batch_if_not_exists: false,
        //consider_open_ll: false
    }),

    getArticleInverQuantityFlag: (articleIds: string[]) => POST<InvertQuantityResult[]>(LicenseGlobals, '/ErgoMobileWork/articles/invertQuantityFlag', articleIds),
}