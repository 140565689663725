import * as React from 'react'
import { Colors, LanguageContext, ThemeContext, View, Screen, LoadingSpinnerModal, Text, Layout } from '@infominds/react-native-components';
import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import useApi from '../../apis/useApi';
import { Platform, SafeAreaView, StatusBar, TouchableOpacity, FlatList } from 'react-native';
import TitleBar from '../../components/TitleBar';
import ObjectContext from '../../contexts/ObjectContext';
import { ArticleHistory, DocumentArticleHistory, ErgoTask } from '../../types';
import ArchiveCard from '../../cards/ArchiveCard';
import DoctypeUtil from '../../utils/DoctypeUtil';
import TasksContext from '../../contexts/TasksContext';
import SearchBoxDebounced from '../../components/SearchBoxDebounced';

interface HistoryFilterItem {
  name: string;
  value: string;
  docType: number;
}

export default function TabArchiveScreen(props: { navigation: any, setDetail: any }) {
  const lang = React.useContext(LanguageContext);
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const [loading, setLoading] = useState(false);
  const [openDetailLoading] = useState(false);

  // const [detailModalVisible, setDetailModalVisible] = useState(false);
  // const [selectedArticleID, setSelectedArticleId] = useState<string | undefined>(undefined);

  let [data, setData] = useState<ArticleHistory[]>([]);
  let [selected, setSelected] = useState<ArticleHistory>();

  const FILTERS: HistoryFilterItem[] = [
    { name: 'all', value: DoctypeUtil.getLanguageShort(0, lang), docType: 0 },
    { name: 'OC', value: DoctypeUtil.getLanguageShort(3, lang), docType: 3 },
    { name: 'DDT', value: DoctypeUtil.getLanguageShort(6, lang), docType: 6 },
    // { name: 'DDT', value: DoctypeUtil.getLanguageShort(-6, lang), docType: -6 },
    { name: 'OF', value: DoctypeUtil.getLanguageShort(4, lang), docType: 4 },
    { name: 'Warehouse', value: DoctypeUtil.getLanguageShort(8, lang), docType: 8 },
  ];

  const [selectedFilter, setSelectedFilter] = useState<HistoryFilterItem>(FILTERS[0]);
  const [searchText, setSearchText] = useState<string>('');

  const { object } = React.useContext(ObjectContext)

  const { tasks } = React.useContext(TasksContext)

  function isDoctypeInTasks(docType: number) {
    if (docType === 0)
      return true;
    const res = tasks.find((item: ErgoTask) => {
      return docType === Math.abs(Number(item.param_list.DOCTYPE))
    })
    return res !== undefined
  }

  useFocusEffect(useCallback(() => {
    reload();
  }, []));

  useEffect(() => {
    reload();
  }, [searchText])


  function reload() {
    setLoading(true);
    let requestParams: any[] = []
    // get history by doctype and SerialNr
    for (var i = 0; i < tasks.length; i++) {
      const docTypeFromTask = Math.abs(Number(tasks[i].param_list.DOCTYPE))
      const numberOfDays = Number(tasks[i].param_list.DOCUMENT_NUMBER_OF_DAYS)
      var item: any = {
        doc_type: docTypeFromTask,
        serial_nr: Number(tasks[i].param_list.SERIALNR),
      }
      // only add if present
      if (numberOfDays)
        item.number_of_days = numberOfDays
      requestParams.push(item);
    }

    useApi.getHistory(searchText, object?.object?.object_id, requestParams)
      .then((res => {
        filterData();
        setData(res)

        if (Layout.isLargeDevice && res.length > 0) {
          setSelected(res[0])
          props.setDetail(res[0])
        }
      }))
      .catch(console.error)
      .finally(() => { setLoading(false) })
  }

  const filterData = () => {
    return data.filter((item: ArticleHistory) => {

      if (selectedFilter.name === 'all')
        return true;
      else {
        var res = item.document.filter((docItem: DocumentArticleHistory) => {
          return docItem.doc_type === selectedFilter.docType
        })
        return res.length > 0
      }
    })
  }

  const GroupFilter = (groupProps: { style: any, data: HistoryFilterItem[] }) => {
    return (
      <View style={{}}>
        <View style={[{ flexWrap: 'wrap', justifyContent: 'space-between', flexDirection: 'row' }, groupProps?.style]}>
          {groupProps.data.map((item: HistoryFilterItem) => {
            const isDoctypeInTask = isDoctypeInTasks(item.docType);
            return (
              <TouchableOpacity
                disabled={!isDoctypeInTask}
                onPress={() => {
                  setSelectedFilter(item)
                }}
                key={item.name}
                style={[{
                  flex: Platform.OS === 'web' ? 1 : 0,
                  opacity: isDoctypeInTask ? 1 : 0.4,
                  backgroundColor: isDoctypeInTask ? selectedFilter.name === item.name ? Colors.grey : theme.inputBackground : Colors.red,
                  borderRadius: 15,
                  marginRight: 10,
                  marginBottom: 5,
                  padding: 2,
                  paddingHorizontal: 5
                }, Platform.OS === 'web' && { alignItems: 'center', justifyContent: 'center' }]} >
                <Text style={{ color: selectedFilter.name === item.name ? Colors.white : theme.text, }}>{item.value}</Text>
              </TouchableOpacity>
            )
          })}
        </View >
      </View>
    )
  }

  const onItemClick = (item: ArticleHistory) => {
    if (Layout.isSmallDevice)
      props.navigation.navigate('ArchiveDetailScreen', { detail: item })
    else {
      props.setDetail(item);
      setSelected(item);
    }
  }

  const renderItem = ({ item, index }: { item: ArticleHistory, index: number }) => {
    let sum = 0;
    item.document.forEach(element => {
      sum += element.quantity
    });

    return (
      <ArchiveCard
        color={theme.card}
        title={item.description}
        isSelected={selected === item}
        item={item}
        sum={sum}
        onPress={onItemClick}
      />
    )
  }

  return (
    <Screen backgroundImage={Layout.isSmallDevice ? 'ellipse6' : 'ellipseFull'} style={{ padding: 0, marginBottom: 0 }} scrollable={false} >
      <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>
        <View style={{ height: '100%' }}>
          <TitleBar title={lang.ARCHIVE} />

          <View style={{ margin: 8 }}>
            <GroupFilter data={FILTERS} style={{ marginLeft: 8, marginBottom: 8 }} />
            <SearchBoxDebounced
              value={searchText}
              placeholder={lang.SEARCH}
              onChangeText={(text: string) => setSearchText(text)} />
          </View>
          <FlatList
            style={[{ flex: 1, padding: 8, height: '100%' }]}
            data={filterData()}
            refreshing={loading}
            onRefresh={reload}
            renderItem={renderItem}
            keyExtractor={(item: any, index: any) => index}
            ListFooterComponent={<View style={{ height: 10 }}></View>}
          />
        </View>
        <View style={{ margin: 1 }}></View>

        <LoadingSpinnerModal visible={openDetailLoading} />
      </SafeAreaView>
    </Screen >
  )
}