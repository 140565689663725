import * as React from 'react';
import { Screen, LanguageContext } from '@infominds/react-native-components';
import { View, SafeAreaView, KeyboardAvoidingView, Platform, StatusBar, ScrollView } from 'react-native';
import { ErgoTask, StoredNewDocument } from '../../types'
import ObjectContext from '../../contexts/ObjectContext';
import TitleBar from '../../components/TitleBar'
import NewHeader from './views/NewHeader'
import NewDocumentStorage from '../../utils/NewDocumentStorage';

export default function NewScreen(props: { navigation: any, route: any }) {
    const lang = React.useContext(LanguageContext);

    const { object } = React.useContext(ObjectContext)

    const task = props?.route?.params?.task as ErgoTask;
    let savedDocument = props?.route?.params?.savedDocument as StoredNewDocument | undefined;

    const documentId = savedDocument ? savedDocument?.date : new Date().toISOString();

    return (
        <Screen backgroundImage='ellipse8' style={{ padding: 0, justifyContent: 'flex-start' }} scrollable={false}  >
            <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }} >
                <KeyboardAvoidingView >
                    <ScrollView style={{ height: '100%' }}>
                        <TitleBar
                            title={task ? task?.description : lang.NEW_REQUEST}
                            onBackPress={() => {
                                if (savedDocument === undefined && object)
                                    NewDocumentStorage.removeIfNoPosition((object.object && object.object.object_id) || object.companyId, task.task_id, documentId)
                                props.navigation.navigate('Home')
                            }}
                        />
                        <View style={{ padding: 8, height: '100%' }}>
                            <NewHeader
                                navigation={props.navigation}
                                constructionSite={object?.object}
                                companyId={object?.companyId}
                                savedDocument={savedDocument}
                                task={task}
                                documentId={documentId}
                            />
                        </View>

                    </ScrollView>
                </KeyboardAvoidingView >
            </SafeAreaView>
        </Screen >
    )
}