import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Colors, View, Text, ModalBottom, LanguageContext, Layout, Icon, ThemeContext, LoadingIndicator } from '@infominds/react-native-components'
import { KeyboardAvoidingView, TouchableOpacity, ScrollView, Platform, Image } from 'react-native';
import useArticlesApi from '../../apis/useArticlesApi';
import { ArticleDetailWork, ArticleStock, ErgoTask, Supplier } from '../../types';
import IMIcon from '../../components/IMIcon';
import useApi from '../../apis/useApi';
import DoctypeUtil from '../../utils/DoctypeUtil';
import DescriptionItem from '../../components/DescriptionItem';

export default function ArticleDetailModal(props: {
    isVisible: boolean,
    close: any,
    article: any,
    articleId?: any,
    ergoTask: ErgoTask,
}) {
    const lang = useContext(LanguageContext);
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const [article, setArticle] = useState<ArticleDetailWork | undefined>(undefined);
    const [mainSupplier, setMainSupplier] = useState<Supplier | undefined>(undefined)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let articleId = props.articleId ? props.articleId : props.article.article_id
        if (articleId) {
            setLoading(true);
            useArticlesApi.getArticleDetail(props.ergoTask.task_id, articleId)
                .then((res) => {
                    setArticle(res)
                    console.debug(res)

                    // get supplier
                    if (res?.supplier_article?.supplier_id) {
                        useApi.getSupplier(res?.supplier_article?.supplier_id).then((res: Supplier) => {
                            if (res)
                                setMainSupplier(res)
                        })
                    }
                }).finally(() => setLoading(false))
        }
    }, [props?.article?.article_id, props.articleId])

    const Separator = () => <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.grey, marginBottom: 10 }}></View>


    function getCurrencySymbol(priceobject?: any): string {
        return priceobject?.currency === 1 ? " €" : '';
    }

    function formatPriceWithSymbol(priceobject?: any): string {
        return priceobject?.net_price?.toFixed(2).replace('.', ',') + getCurrencySymbol(priceobject)
    }

    function formatSalePriceWithSymbol(priceobject?: any): string {
        return priceobject?.price?.toFixed(2).replace('.', ',') + getCurrencySymbol(priceobject)
    }

    function formatPriceCombination(priceobject?: any): string {
        try {
            if (priceobject?.price !== 0 && priceobject?.row_discont && priceobject?.row_discont.length > 0 && priceobject?.row_discont[0] !== 0) {
                let result = '( ' + formatSalePriceWithSymbol(priceobject) + ' - ';
                result += priceobject?.row_discont?.filter((discount: number) => {
                    return discount !== 0;
                }).map((discount: number) => { if (discount !== 0) return discount + '% ' }).join(" - ")
                return result + ')';
            }
            return ''

        } catch (exception: any) {
            console.error(exception)
            return '';
        }
    }

    const docType = Number(props.ergoTask.param_list.DOCTYPE);
    // this depends also on the document type 
    const ShowBuyPrice = props.ergoTask.param_list.SHOW_BUY_PRICE === "1" && DoctypeUtil.hasBuyPrice(docType);
    const ShowSellPrice = props.ergoTask.param_list.SHOW_SALE_PRICE === "1" && DoctypeUtil.hasSellPrice(docType);;

    const Info = () => (
        <>
            <DescriptionItem
                label={lang.ARTICLE_ID}
                value={props?.article?.article_id} />

            <View style={{ height: 20 }}></View>

            <DescriptionItem
                label={lang.DESCRIPTION}
                value=''
            />
            <Text>{article?.long_text} </Text>

            <View style={{ height: 20 }}></View>


            {ShowSellPrice &&
                <DescriptionItem
                    label={lang.SALE_PRICE}
                    value={formatPriceWithSymbol(article?.sale_price[0])}
                    value2={formatPriceCombination(article?.sale_price[0])} />
            }

            {ShowBuyPrice &&
                <DescriptionItem
                    label={lang.BUY_PRICE}
                    value={formatPriceWithSymbol(article?.buy_price[0])}
                    value2={formatPriceCombination(article?.buy_price[0])} />
            }


            {/* Show first Total Amount */}
            <View style={{ height: 20 }}></View>
            {article?.article_stock.map((stock: ArticleStock, index: number) => {
                if (stock.is_total_amount)
                    return (
                        <DescriptionItem
                            key={index.toString()}
                            label={stock?.description || ''}
                            value={lang.SUM + ' ' + stock.act_stock} />)
                return (<></>)
            })}

            {/* Show all other stocks */}
            {article?.article_stock.map((stock: ArticleStock, index: number) => {
                if (stock.is_total_amount)
                    return (<></>)
                return (
                    <View key={index.toString()}
                        style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                        <View style={{ marginLeft: 5, marginRight: 10, justifyContent: 'center' }}>
                            <IMIcon color={theme.text} icon={'warehouse'}></IMIcon>
                        </View>
                        <Text style={{ flex: 1, flexWrap: 'wrap', justifyContent: 'flex-start' }}>{stock?.description}</Text>
                        <Text style={{ flex: 1, justifyContent: 'flex-end', textAlign: 'right' }}>{stock?.act_stock}</Text>
                    </View>
                )
            })}

            {article?.article_stock && article?.article_stock?.length > 1 &&
                <Separator />
            }

            <View style={{ height: 20 }}></View>

            <DescriptionItem
                label={lang.VAT}
                value={article?.vat_rate + ' %'} />

            <View style={{ height: 20 }}></View>

            <DescriptionItem
                label={lang.MAIN_SUPPLIER}
                value={mainSupplier?.description} />
            <DescriptionItem
                label={lang.SUPPLIER_ARTICLE}
                value={article?.supplier_article?.article_id} />

            <View style={{ height: 20 }}></View>

            <Image
                style={{ width: 200, height: 200, borderRadius: 8 }}
                source={{ uri: 'data:image/jpg;base64,' + article?.article_image?.image }} />

        </>
    )

    if (!props.isVisible)
        return (<></>)
    return (
        <ModalBottom
            isVisible={props.isVisible}
            swipeDirection={[]}
            close={() => {
                // setArticleConfig({})
                props.close()
            }}
            propagateSwipe
            nestedScrollEnabled
            style={[{
                height: '95%',
                padding: 0,
                maxHeight: '100%',
                width: Layout.isLargeDevice ? '96%' : '100%',
                backgroundColor: theme.background
            }, { height: '95%', paddingBottom: 0 },
            Platform.OS !== 'web' && { marginLeft: Layout.isLargeDevice ? '2%' : '0%' },
            colorScheme === 'dark' && { borderLeftWidth: 1, borderRightWidth: 1, borderColor: Colors.grey }]}
            content={
                <KeyboardAvoidingView behavior={'padding'} style={{ flex: 1 }}>
                    <View style={{ flex: 1, marginBottom: 20, backgroundColor: Colors.grey, borderTopRightRadius: 22, borderTopLeftRadius: 22, }} >
                        <View style={{
                            borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                            padding: 20,
                            paddingBottom: 10,
                            marginBottom: 0,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            backgroundColor: Colors.grey,
                        }}>
                            <Text style={{ fontWeight: 'bold', color: Colors.white }} >{props?.article?.description || ''}</Text>

                            <TouchableOpacity style={{ height: 30, justifyContent: 'flex-end' }} onPress={() => props.close()}>
                                <Icon name='x' size={28} color={Colors.white} />
                            </TouchableOpacity>
                        </View>

                        {loading ?
                            <View style={{ flex: 1, backgroundColor: theme.background }}>
                                <LoadingIndicator isVisible></LoadingIndicator>
                            </View>
                            :
                            <ScrollView style={{ backgroundColor: theme.background, padding: 10 }}>
                                {/* TouchableOpacity required for enebale scroll */}
                                <TouchableOpacity>
                                    <Info />
                                </TouchableOpacity>
                            </ScrollView>
                        }
                    </View>
                </ KeyboardAvoidingView>
            } />
    )
}