import { LicenseGlobals } from '@infominds/react-native-license';
import { GET, POST, PUT } from '@infominds/react-native-components';
import {
    ArticleHistory,
    Client, ClientPost, ConstructionLot, ConstructionSite, Contact, ContactPost, Destination, DeterminePrice, DeterminePriceRequest, DocFilter, DocumentArticleHistory, DocumentCreateResult, DocumentCreateResultItem, DocumentPost, Employee, EmployeePrice, EmployeePriceRequest,
    ErgoTask, Freight, HomeItem, Mandant, ObjectInfo, Serialnumber, Supplier, TransportMean, TransportMode, TransportReason, VerifyDocumentsPost
} from '../types';

export default {

    getMandants: () => GET<Mandant[]>(LicenseGlobals, "/mandants"),

    getEmployees: () => GET<Employee[]>(LicenseGlobals, "/employees/mobileServiceActive"),

    getObjectLots: (id: number) => GET<ConstructionLot[]>(LicenseGlobals, "/objects/" + id + '/lots'),
    getOpenObjectLots: (id: number) => GET<ConstructionLot[]>(LicenseGlobals, "/objects/" + id + '/lots/ignoreclosed/1'),

    getCompanies: () => GET<Client[]>(LicenseGlobals, '/companies'),

    getCompany: (id: any) => GET<Client>(LicenseGlobals, `/companies/${id}`),

    getSuppliers: () => GET<Supplier[]>(LicenseGlobals, `/suppliers/`),

    getSupplier: (id: number) => GET<Supplier>(LicenseGlobals, `/suppliers/${id}`),

    getFreights: () => GET<Freight[]>(LicenseGlobals, `/freights`),

    getFreight: (id: number) => GET<Freight[]>(LicenseGlobals, `/freights/${id}`),

    getCompanyObjects: (id: any) => GET<ConstructionSite[]>(LicenseGlobals, '/companies/' + id + '/objects'),

    getDeterminePrice: (body: DeterminePriceRequest) => POST<DeterminePrice[]>(LicenseGlobals, "/prices/getdetermineprice", body),

    getEmployeePrices: (body: EmployeePriceRequest[]) => POST<EmployeePrice[]>(LicenseGlobals, "/prices/employeesprices", body),

    postClient: (body: ClientPost) => POST(LicenseGlobals, "/companies", body),

    postContact: (body: ContactPost) => POST(LicenseGlobals, "/contacts/company", body),

    putContact: (body: ContactPost, contactid?: number) => PUT(LicenseGlobals, "/contacts/company", { ...body, ...{ contact_id: contactid } }),

    getObjects: () => GET<ConstructionSite[]>(LicenseGlobals, "/objects"),

    getObject: (objectId: number) => GET<ConstructionSite>(LicenseGlobals, "/objects/" + objectId, (result) => { return result.data[0] }),

    getObjectDetail: (objectId: number) => GET<ObjectInfo>(LicenseGlobals, "/objects/" + objectId + "/detail"),

    getObjectContacts: (objectId: number) => GET<Contact[]>(LicenseGlobals, "/objects/" + objectId + "/v2/contacts"),

    getCompanyContacts: (companyId: number) => GET<Contact[]>(LicenseGlobals, "/companies/contacts/" + companyId),

    getDestinations: (companyId: number) => GET<Destination[]>(LicenseGlobals, "/companies/" + companyId + "/destinations/list"),

    getSelectionList: () => GET<HomeItem[]>(LicenseGlobals, '/ErgoMobileWork/home'),

    getTasks: (appName: string) => GET<ErgoTask[]>(LicenseGlobals, '/tasks/v3/appname/' + appName + '/username/' + LicenseGlobals.username),

    getBarcodeDecode: (barcode: string) => POST<any>(LicenseGlobals, '/searchbarcode/decode', { barcode: barcode }),

    createDocument: (providerName: string, body: DocumentPost) => POST<string>(LicenseGlobals, "/ErgoMobileWork/document/providername/" + providerName, body),

    verifyDocuments: (body: VerifyDocumentsPost) => POST<DocumentCreateResult>(LicenseGlobals, "/ErgoMobileWork/verifydocuments", body),

    getTransportReasons: () => GET<TransportReason[]>(LicenseGlobals, '/transport/reasons'),

    getTransportMeans: () => GET<TransportMean[]>(LicenseGlobals, '/transport/means'),

    getTransportModes: () => GET<TransportMode[]>(LicenseGlobals, '/transport/modes'),

    getHistory: async (searchText: string, objectId: number | undefined, docFiltes: DocFilter[]) => {

        var request: any = {
            search_text: searchText,
            valid_to: true,
            document: true,
            document_filter: []
        }

        for (var i = 0; i < docFiltes.length; i++) {
            let position: any = { doc_type: docFiltes[i].doc_type, serial_nr: docFiltes[i].serial_nr, number_of_days: docFiltes[i].number_of_days }
            if (objectId) {
                position['object_id'] = objectId
            }
            request.document_filter.push(position)
        }

        // console.debug('request.document_filter', request.document_filter)

        var apiRes = await POST<any[]>(LicenseGlobals, "/fulltext/historyarticles", request);
        var res: ArticleHistory[] = [];

        for (var docType in apiRes) {
            var articles: ArticleHistory[] = apiRes[docType];
            articles.forEach((article: ArticleHistory) => {
                var foundArticle = res.find((item: ArticleHistory) => item.article_id === article.article_id);
                if (foundArticle === undefined)
                    res.push(article)
                else {
                    // only add non already present documents
                    article.document.forEach((document: DocumentArticleHistory) => {
                        if (foundArticle !== undefined) {
                            var docFound = foundArticle.document.find((ditem: DocumentArticleHistory) =>
                                ditem.doc_date === document.doc_date &&
                                ditem.doc_id === document.doc_id &&
                                ditem.doc_pos_id === document.doc_pos_id &&
                                ditem.doc_type === document.doc_type &&
                                ditem.quantity === document.quantity &&
                                ditem.serial_nr === document.serial_nr &&
                                ditem.serial_year === document.serial_year)
                            if (!docFound)
                                foundArticle.document.push(document);
                        }
                    })
                }
            })
        }

        return res;
    },

    getHistoryByDocTypeAndSerialNr: (searchText: string, numberOfDays: number, objectId: number, docType: number, serailNr: number) => POST<ArticleHistory[]>(LicenseGlobals, "/fulltext/articles",
        {
            search_text: searchText,
            valid_to: true,
            document: true,
            document_filter: {
                doc_type: docType,
                serial_nr: serailNr,
                //_serial_year: 2020,
                number_of_days: numberOfDays,
                object_id: objectId
            }
        }),

    getSerialnumbers: () => GET<Serialnumber[]>(LicenseGlobals, '/serialnumber'),

}