import * as React from 'react'
import { Colors, View, Icon, Title, ThemeContext } from "@infominds/react-native-components";
import { Platform, TouchableOpacity } from 'react-native';
import IMIcon from './IMIcon';

TitleBar.defaultProps = {
    disableBackPress: false,
    disableSettingsButton: false,
    disableInfoButton: false,
    visible: true
}

export default function TitleBar(props: {
    title?: string,
    visible: boolean,
    fontSize?: number,
    onBackPress?: () => void,
    disableBackPress: boolean,
    disableSettingsButton: boolean,
    onSettingsPress?: () => void,
    disableInfoButton: boolean,
    onInfoPress?: () => void
}) {
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <View style={[{
            paddingHorizontal: 8,
            paddingBottom: 8,
            flexDirection: 'row',
            justifyContent: 'flex-start'
        }, Platform.OS === 'web' && { paddingTop: 10 }]}>
            {props.onBackPress && !props.disableBackPress ?
                <TouchableOpacity
                    onPress={() => {
                        if (props.onBackPress)
                            props?.onBackPress()
                    }}>
                    <View style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        padding: 5,
                    }}>
                        <Icon
                            name={'arrow-left'}
                            size={28}
                            color={theme.text}></Icon>
                    </View>
                </TouchableOpacity> : <></>}
            <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            }}>
                <Title style={[{ color: theme.text, fontSize: 22 }, props.fontSize ? { fontSize: props.fontSize } : {}]}>{props?.title}</Title>

                {props.onSettingsPress && !props.disableSettingsButton ?
                    <TouchableOpacity
                        style={{ marginTop: 2, marginRight: 0, padding: 8, alignContent: 'flex-end' }}
                        onPress={() => { if (props.onSettingsPress) props?.onSettingsPress() }}>
                        <IMIcon color={theme.text} size={28} icon={'cog'} />
                    </TouchableOpacity>
                    : <></>
                }

                {props.onInfoPress && !props.disableInfoButton ?
                    <TouchableOpacity
                        style={{ marginTop: 2, marginRight: 0, padding: 8, alignContent: 'flex-end' }}
                        onPress={() => { if (props.onInfoPress) props?.onInfoPress() }}>
                        <IMIcon color={theme.text} size={28} icon={'infoCircle'} />
                    </TouchableOpacity>
                    : <></>
                }
            </View>
        </View >
    )
}
