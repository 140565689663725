import * as React from 'react'
import { Colors, Text, ThemeContext } from "@infominds/react-native-components";
import { View } from 'react-native';
import Separator from './Separator';

export default function DescriptionItem(ItemProps: { label: string, value: any, value2?: any }) {

    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    return (
        <View>
            <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ flex: 1, flexWrap: 'wrap', justifyContent: 'flex-start' }}>{ItemProps.label}</Text>
                <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Text style={{ textAlign: 'right' }}>{ItemProps.value}</Text>
                    {ItemProps.value2 ?
                        <Text style={{ textAlign: 'right', color: theme.textDetail }}>{ItemProps.value2}</Text> : <></>
                    }
                </View>
            </View>
            {ItemProps.value !== '' &&
                <Separator />}
        </View>)
}
