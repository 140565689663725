import React, { useContext, useEffect, useState } from 'react';
import { StyleSheet, TextInputProps, TouchableOpacity, View } from 'react-native';
import { TextInput } from 'react-native';
import { Colors, Icon, Layout, ThemeContext } from '@infominds/react-native-components'
import IMIcon from './IMIcon';
import { debounce } from "lodash";


type Props = {
    onBarcodePress: any,
    barcodeScannerVisible?: boolean
};

export type SearchBoxBarcode = Props & TextInputProps;

export default function SearchBoxBarcode(props: SearchBoxBarcode) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const { style, value, ...otherProps } = props;

    const [searchText, setSearchText] = useState(value);

    const onSearchChangeText = (text: string) => {
        if (props.onChangeText)
            props.onChangeText(text)
    }

    useEffect(() => {
        setSearchText(props.value)
    }, [props.value])

    const onChangeTextDelayed = React.useCallback(debounce(onSearchChangeText, 1000), []);

    return (
        <View style={[styles.searchSection, Layout.defaultComponentsStyles,
        {
            backgroundColor: theme.inputBackground,
            borderColor: theme.inputBorder
        }, style]}>
            <TextInput
                style={[styles.input, { color: theme.text }]}
                placeholder="Search..."
                // onChangeText={(searchString) => { this.setState({ searchString }) }}
                underlineColorAndroid="transparent"
                placeholderTextColor={theme.textPlaceholder}

                value={searchText}

                {...otherProps}
                onChangeText={(text: string) => {
                    setSearchText(text)
                    onChangeTextDelayed(text)
                }}
            />

            {(props.value && props.value !== '') ?
                <Icon style={styles.searchIcon} name="x" size={20} color={theme.textDetail}
                    onPress={() => { props.onChangeText && props.onChangeText('') }} />
                :
                // <Icon onPress={() => props.onBarcodePress()} style={styles.searchIcon} name="camera" size={20} color={theme.textDetail} />
                <>
                    {props.barcodeScannerVisible &&
                        <TouchableOpacity style={styles.searchIcon} onPress={() => props.onBarcodePress()}>
                            <IMIcon size={28} color={theme.text} icon={'barcodeRead'} />
                        </TouchableOpacity>
                    }
                </>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    input: {
        flex: 1,
        padding: 12
    },
    searchSection: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 8,
        paddingRight: 5
    },
    searchIcon: {
        padding: 5,
    },

});