import React, { useContext, useEffect, useState } from 'react';
import { Colors, View, Text, ModalBottom, Layout, ThemeContext, LanguageContext } from '@infominds/react-native-components'
import { KeyboardAvoidingView, ScrollView, Platform, } from 'react-native';
import MediaGrid from '../../components/media/MediaGrid';
import ActionButton from '../../components/ActionButton'
import ArticleConfigView, { ArticleConfigValues } from './ArticleConfigView'
import { ErgoTask } from '../../types';
import DescriptionItem from '../../components/DescriptionItem';

export default function AddMediaModal(props: {
    onSubmit: (selected: any) => void,
    isVisible: boolean,
    close: any,
    selected: any,
    selectedArticleDetail: any,
    addMediaVisible: boolean,
    ergoTask: ErgoTask,
    titleDescriptionVisible: boolean,
    showInfoArticle: boolean,
    title?: string
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];
    const lang = useContext(LanguageContext)

    const [articleConfig, setArticleConfig] = useState<ArticleConfigValues>(props.selected ? {
        title: props.selected?.title,
        note: props.selected?.note,
        quantity: props.selected?.quantity ? props.selected?.quantity : props.selected?.count,
        selPrice: props.selected?.selPrice,
        rabat: props.selected?.rabat,
        buyPrice: props.selected?.buyPrice,
        buyRabat: props.selected?.buyRabat,
        buyTotal: props.selected?.buyTotal,
        hasBuyPrice: props.selected?.hasBuyPrice,
        hasSellPrice: props.selected?.hasSellPrice
    } : {});

    const [mediaData, setMediaData] = useState<any[]>(props.selected ? props.selected?.media : []);

    useEffect(() => {

        if (props.selected) {
            let init = {
                title: props.selected?.title,
                note: props.selected?.note,
                quantity: props.selected?.quantity ? props.selected?.quantity : props.selected?.count,
                selPrice: props.selected?.selPrice,
                rabat: props.selected?.rabat,
                buyPrice: props.selected?.buyPrice,
                buyRabat: props.selected?.buyRabat,
                buyTotal: props.selected?.buyTotal,
                hasBuyPrice: props.selected?.hasBuyPrice,
                hasSellPrice: props.selected?.hasSellPrice
            }
            setArticleConfig(init)
            setMediaData(props.selected.media)
        }
    }, [])

    const ModalActions = () => (
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <ActionButton icon={'times'}
                onPress={() => props.close()}
                hasMarginRight={false}
                iconColor={Colors.modern.red}
                style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    marginRight: 1
                }} />
            <ActionButton icon={'check'}
                iconColor={Colors.tint}
                onPress={() => {
                    if (mediaData !== undefined)
                        props.onSubmit([{ ...articleConfig, media: [...mediaData] }])
                    else
                        props.onSubmit([{ ...articleConfig }])
                    props.close();
                }}
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }} />
        </View>
    )

    if (!props.isVisible)
        return (<></>)
    return (
        <>
            <ModalBottom
                isVisible={props.isVisible}
                swipeDirection={[]}
                close={props.close}
                propagateSwipe
                // keyboardAvoiding
                nestedScrollEnabled
                style={[{
                    height: '95%',
                    padding: 0,
                    maxHeight: '100%',
                    width: Layout.isLargeDevice ? '96%' : '100%',
                    paddingBottom: 0
                },
                Platform.OS !== 'web' && { marginLeft: Layout.isLargeDevice ? '2%' : '0%' }]}
                content={
                    <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"} style={{ flex: 1 }}>
                        <View style={{ flex: 1, marginBottom: 20, backgroundColor: theme.backgroundModal, borderTopRightRadius: 22, borderTopLeftRadius: 22, }} >
                            <View style={{
                                borderTopRightRadius: 19,
                                borderTopLeftRadius: 19,
                                padding: 20,
                                paddingBottom: 10,
                                marginBottom: 0,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                backgroundColor: Colors.grey,
                            }}>
                                <Text style={{ fontWeight: 'bold', color: Colors.white }}>{props.title ? props.title : 'Media'}</Text>
                                <ModalActions></ModalActions>
                            </View>
                            <ScrollView>
                                <View onStartShouldSetResponder={() => true}>
                                    {props.selected && props.selected.article &&
                                        <View style={{ margin: 10 }}>
                                            {/* {props.selected.article.description && <Text>{lang.ARTICLE_ID + ' ' + props.selected.article.description}</Text>}
                                            {props.selected.article.article_id && <Text>{lang.ARTICLE_ID + ' ' + props.selected.article.article_id}</Text>} */}

                                            <DescriptionItem
                                                label={lang.ARTICLE_ID}
                                                value={props.selected.article.article_id} />

                                            <View style={{ height: 20 }}></View>

                                            <DescriptionItem
                                                label={lang.DESCRIPTION}
                                                value={props.selected.article.description}
                                            />
                                        </View>
                                    }

                                    {/* {props.showInfoArticle &&
                                        <Button type={ButtonType.info} title='Info' onPress={() => { setShowArticleDetail(true) }}></Button>} */}

                                    <ArticleConfigView
                                        selectedArticleDetail={props.selectedArticleDetail}
                                        value={articleConfig}
                                        ergoTask={props.ergoTask}
                                        onChange={(res: ArticleConfigValues) => {
                                            setArticleConfig(res);
                                        }} hasTitle={props.titleDescriptionVisible} hasDescription={props.titleDescriptionVisible} />

                                    {props.addMediaVisible &&
                                        <View style={{ flex: 1, backgroundColor: theme.backgroundModal }}>
                                            <MediaGrid
                                                value={mediaData}
                                                onChange={(data: any[]) => setMediaData(data)}
                                            />
                                        </View>
                                    }
                                </View>
                                <View style={{ height: 50 }}></View>
                            </ScrollView>
                        </View>
                    </ KeyboardAvoidingView>
                } />
            {/* {showArticleDetail &&
                <ArticleDetailModal
                    articleId={props.selected.article.article_id}
                    ergoTask={props.ergoTask}
                    // haseModifyPrice
                    isVisible={true}
                    close={() => {
                        setShowArticleDetail(false);
                        // setSelectedArticleDetail(undefined); setSelectedMediaArticleIndex(-1)
                    }}
                    article={props.selected.article}                // selected={materialData[materialDataIndex]}
                />
            } */}
        </>
    )
}