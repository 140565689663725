import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Colors, View, ModalBottom, LanguageContext, Layout, ThemeContext, LoadingSpinnerModal } from '@infominds/react-native-components'
import { KeyboardAvoidingView, TouchableOpacity, Platform, Alert, StyleProp, ViewStyle } from 'react-native';
import useArticlesApi from '../../../apis/useArticlesApi';
import ComboBadge from '../../../components/ComboBadge'
import AnimationRotate from '../../../components/AnimationRotate'
import { ArticlesFromCatalougueRequest, ErgoTask, MaterialArticle } from '../../../types';
import ArticlesList from './ArticlesList'
import ArticlesTree from './ArticlesTree'
import ObjectContext from '../../../contexts/ObjectContext';
import ActionButton from '../../../components/ActionButton'
import DismissKeyboardView from '../../../components/DismissKeyboardView'
import BarcodeUtil from '../../../utils/BarcodeUtil';
import BarcodeScannerModal from '../../BarcodeScannerModal';
import IMIcon from '../../../components/IMIcon';

export default function AddArticlesModal(props: {
    onSubmit: (selected: any) => void,
    isVisible: boolean,
    close: any,
    init: any,
    ergoTask: ErgoTask,
    // savedType: string,
    fastMode: boolean
}) {
    const lang = useContext(LanguageContext);
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];


    const { object } = React.useContext(ObjectContext)

    const LABELS = {
        history: lang.HISTORYARTICLES,
        priceList: lang.CATALOGUE,
        all: lang.ALL
    }

    const [types, setTypes] = useState<string[]>([LABELS.all, LABELS.priceList, LABELS.history]);
    const [selectedType, setSelectedTypeState] = useState(LABELS.priceList);

    const [loading, setLoading] = useState(true);
    let [searchText, setSearchText] = useState('');

    const [barcodeModalVisible, setBarcodeModalVisible] = useState(false);
    const [isLoadingBarcodeResult, setIsLoadingBarcodeResult] = useState(false);

    const [performnanceBoost] = useState<boolean>(props.fastMode);

    const [historyArticles, setHistoryArticles] = useState<MaterialArticle[]>([]);
    const [priceListArticles, setPriceListArticles] = useState<any[]>([]);
    const [all, setAll] = useState<MaterialArticle[]>([]);


    // hase to be a state but dont use set because will render too often
    let [selectedArticles, setSelectedArticleState] = useState<any>({})
    function SetSelectedArticle(item: any, value: number) {
        if (value === 0) {
            delete selectedArticles[item.article_id];
        } else
            selectedArticles[item.article_id] = { count: value, article: item };
    }

    function submit() {
        let tmp: any[] = [];
        Object.keys(selectedArticles).forEach(function (key, index) {
            tmp.push(selectedArticles[key])
        });
        props.onSubmit(tmp)
    }

    useEffect(() => {
        init();
        setSelectedArticleState(selectedArticles)
        loadData('', (selectedType as string))
    }, [props.init])

    function init(): any {
        selectedArticles = {};
        if (props.init) {
            props.init.forEach((item: any) => {
                if (item.count === 0)
                    delete selectedArticles[item.article.article_id];
                else
                    selectedArticles[item.article.article_id] = { count: item.count, article: item.article }
            })
            return selectedArticles;
        }
        return {};
    }

    function mapPriceListCategories(data: any) {
        let tmp: any[] = [];
        Object.keys(data).forEach(function (key: string, index: any) {
            if (key !== '0') {
                const category = data[key];
                tmp.push({
                    id: key,
                    article_id: key,
                    description: category.description,
                    goodCategories: {
                        good_category_id1: category.good_category_id1,
                        good_category_id2: category.good_category_id2,
                        good_category_id3: category.good_category_id3,
                        good_category_id4: category.good_category_id4,
                    } as ArticlesFromCatalougueRequest,
                    children: mapPriceListCategories(category.category_node_list),
                })
            }
        });
        return tmp;
    }

    function loadData(search: string, type: string) {
        setLoading(true);

        if (type === LABELS.all) {
            // FR descides to not load data until there is a search string
            if (searchText !== '') {
                useArticlesApi.searchArticleFullText(search, Math.abs(Number(props.ergoTask.param_list.DOCTYPE)), Number(props.ergoTask.param_list.SERIALNR))
                    .then((res: any[]) => { setAll(res) })
                    .finally(() => setLoading(false));
            }
            else setLoading(false)
        } else if (type === LABELS.priceList)
            useArticlesApi.getErgoMobileWorkArticlesCatalouge(props.ergoTask.task_id)
                .then((res: any) => {
                    if (res.category_list === undefined || res.category_list.length === 0) {
                        setSelectedTypeState(LABELS.all)
                        setTypes([LABELS.all, LABELS.history]);
                    }
                    else
                        setPriceListArticles(mapPriceListCategories(res.category_list));
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        else
            if (object)
                useArticlesApi.searchArticleFullTextDocumentsHistoryWithoutDocuments(search,
                    Number(props.ergoTask.param_list.DOCTYPE),
                    Number(props.ergoTask.param_list.DOCUMENT_NUMBER_OF_DAYS),
                    object.object?.object_id)
                    .then(res => {
                        setHistoryArticles(res);
                    }).finally(() => setLoading(false));
    }

    const initQuantity = (item: any) => selectedArticles[item.article_id]?.count ? selectedArticles[item.article_id]?.count : 0

    const onArticleValueChange = (item: any, newValue: number) => {
        SetSelectedArticle(item, newValue)
    }

    const saveSearchText = (text: string) => {
        searchText = text;
        if (text === '') {
            setSearchText('')
        }
    }

    const RenderListBySelectedType = () => {
        if (selectedType === LABELS.all)
            return (
                <ArticlesList
                    data={all}
                    ergoTask={props.ergoTask}
                    performnanceBoost={performnanceBoost}
                    searchFunction={(text: string) => {
                        return useArticlesApi.searchArticleFullText(text, Math.abs(Number(props.ergoTask.param_list.DOCTYPE)), Number(props.ergoTask.param_list.SERIALNR))
                    }}
                    onSearchTextChanged={(text: string) => { saveSearchText(text) }}
                    onFinishSearchTextInput={(text: string) => { setSearchText(text) }}
                    onBarcodePressed={() => { setBarcodeModalVisible(true) }}
                    saveSearchText={(text: string) => setSearchText(text)}
                    initialSearchText={searchText}
                    initialQuantity={initQuantity}
                    onValueChange={onArticleValueChange}
                    doNotLoadUntilSearchTextHasValue />
            )
        else if (selectedType === LABELS.priceList)
            return (
                <View style={{ flex: 1, marginBottom: 50 }}>
                    <ArticlesTree
                        data={priceListArticles}
                        performnanceBoost={performnanceBoost}
                        ergoTask={props.ergoTask}
                        initialQuantity={initQuantity}
                        initialSearchText={searchText}
                        onBarcodePressed={() => { setBarcodeModalVisible(true) }}
                        onSearchTextChanged={(text: string) => { saveSearchText(text) }}
                        onFinishSearchTextInput={(text: string) => { setSearchText(text) }}
                        onValueChange={onArticleValueChange} />
                </View>
            )
        else
            return (
                <ArticlesList
                    data={historyArticles}
                    performnanceBoost={performnanceBoost}
                    ergoTask={props.ergoTask}
                    searchFunction={(text: string) => {
                        return useArticlesApi.searchArticleFullTextDocumentsHistoryWithoutDocuments(text,
                            Number(props.ergoTask.param_list.DOCTYPE),
                            Number(props.ergoTask.param_list.DOCUMENT_NUMBER_OF_DAYS),
                            object?.object?.object_id)
                    }}
                    onSearchTextChanged={(text: string) => { saveSearchText(text) }}
                    onFinishSearchTextInput={(text: string) => { setSearchText(text) }}
                    initialSearchText={searchText}
                    saveSearchText={(text: string) => setSearchText(text)}
                    initialQuantity={initQuantity}
                    onBarcodePressed={() => { setBarcodeModalVisible(true) }}
                    onValueChange={onArticleValueChange}
                    doNotLoadUntilSearchTextHasValue={false} />
            )
    }

    function close() {
        Alert.alert(
            lang.CLOSE,
            lang.CLOSE_WITHOUT_SAVING,
            [
                { text: lang.CANCEL, onPress: () => console.debug("Cancel Pressed"), style: "cancel" },
                {
                    text: "OK",
                    onPress: () => {
                        saveSearchText('');
                        setSearchText('');
                        props.close();
                    }
                }
            ]
        );
    }

    function save() {
        saveSearchText('');
        setSearchText('');
        submit();
        props.close();
    }

    const RefreshButton = (propsRefreshButton: { style?: StyleProp<ViewStyle> }) => (
        <TouchableOpacity
            style={[{
                height: 35,
                justifyContent: 'center',
                backgroundColor: theme.inputBackground,
                borderRadius: 8,
                padding: 2,
                paddingHorizontal: 10,
                marginRight: 10
            }, propsRefreshButton.style]}
            onPress={() => loadData('', selectedType as string)}>
            <View style={{ margin: 5 }}>
                <AnimationRotate rotating={loading}>
                    <IMIcon size={19} color={theme.text} icon={'sync'} />
                </AnimationRotate>
            </View>
        </TouchableOpacity>
    );

    const ModalActions = () => (
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <RefreshButton
                style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    marginRight: 1
                }} />
            <ActionButton icon={'times'} onPress={() => close()}
                hasMarginRight={false}
                iconColor={Colors.modern.red}
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    marginRight: 1
                }} />
            <ActionButton icon={'check'} onPress={() => { save() }}
                iconColor={Colors.tint}
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }} />
        </View>
    )

    const onBarcodeResult = (res: any) => {
        setIsLoadingBarcodeResult(true)
        setBarcodeModalVisible(false)

        BarcodeUtil.getArticleCode(res)
            .then((articleCode: string) => {
                if (selectedType !== LABELS.all)
                    setSelectedTypeState(LABELS.all)
                setSearchText(articleCode)
            })
            .catch((error) => {
                Alert.alert(
                    "Info",
                    lang.NO_ARTICLE_WITH_THIS_BARCODE,
                    [
                        { text: "OK", onPress: () => console.log("OK Pressed") }
                    ]
                ); console.error(error)
            })
            .finally(() => setIsLoadingBarcodeResult(false))
    };


    if (!props.isVisible)
        return (<></>)
    return (
        <ModalBottom
            isVisible={props.isVisible}
            swipeDirection={[]}
            close={props.close}
            // avoidKeyboard
            propagateSwipe
            nestedScrollEnabled
            style={[{
                height: '95%',
                padding: 0,
                maxHeight: '100%',
                width: Layout.isLargeDevice ? '96%' : '100%',

            },
            { height: '95%', paddingBottom: 0 },
            Platform.OS !== 'web' && { marginLeft: Layout.isLargeDevice ? '2%' : '0%' }
            ]}
            content={
                <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : undefined} keyboardVerticalOffset={50} style={{ flex: 1 }}>
                    <DismissKeyboardView>
                        <View style={{
                            flex: 1, backgroundColor: Colors.grey, borderTopRightRadius: 22,
                            borderTopLeftRadius: 22,
                        }} >
                            <View style={{
                                borderTopRightRadius: 22,
                                borderTopLeftRadius: 22,
                                padding: 15,
                                paddingBottom: 10,
                                marginBottom: 0,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                backgroundColor: Colors.grey,
                            }}>
                                <ComboBadge
                                    data={types}
                                    value={selectedType}
                                    onChange={(title: string) => {
                                        setSelectedTypeState(title);
                                        setSearchText(searchText)
                                        loadData(searchText, title)

                                    }} />

                                <ModalActions />

                            </View>
                            <View style={{ flex: 1, backgroundColor: theme.backgroundModal }}>
                                <View style={{ flex: 1 }} >
                                    <RenderListBySelectedType />
                                </View>
                            </View>

                            <LoadingSpinnerModal visible={isLoadingBarcodeResult} />
                            <BarcodeScannerModal
                                isVisible={barcodeModalVisible}
                                onClose={() => setBarcodeModalVisible(false)}
                                onBarCodeRead={onBarcodeResult} />

                        </ View>
                    </DismissKeyboardView>
                </ KeyboardAvoidingView>
            } />
    )
}