import useArticlesApi from '../apis/useArticlesApi';

const AIM_PREFIX_SI = ']';
const AIM_PREFIX_CODE128 = 'C0';
const AIM_PREFIX_EAN13 = 'E0';
const AIM_PREFIX_EAN8 = 'E4';

// const AIM_PREFIX_CODE39 = 'A0';
// const AIM_PREFIX_EAN128 = 'C1';
// const AIM_PREFIX_DATAMATRIX_D1 = 'd1';
// const AIM_PREFIX_DATAMATRIX_D2 = 'd2';
// const AIM_PREFIX_DATAMATRIX_D3 = 'd3';
// const AIM_PREFIX_CODABAR = 'X0';
// const AIM_PREFIX_EAN8 = 'E4';
// const AIM_PREFIX_ITF = 'I0';
// const AIM_PREFIX_UPCA = '';
// const AIM_PREFIX_QRCODE1 = 'Q1'; //Standard QR-Code FNC1


const getBarcodeCode = async (data: any): Promise<string> => {
    if (!data)
        return '';

    //  RNCamera.Constants.BarCodeType;

    switch (data.type) {
        case 'org.iso.Code128': //iOS
            return AIM_PREFIX_SI + AIM_PREFIX_CODE128 + data.data;
        case 'CODE_128':        //android
            return AIM_PREFIX_SI + AIM_PREFIX_CODE128 + data.data;
        case 'org.gs1.EAN-13':  //iOS
            return AIM_PREFIX_SI + AIM_PREFIX_EAN13 + data.data;
        case 'EAN_13':          //android
            return AIM_PREFIX_SI + AIM_PREFIX_EAN13 + data.data;
        case 'org.gs1.EAN-8':   //iOS
            return AIM_PREFIX_SI + AIM_PREFIX_EAN8 + data.data;
        case 'EAN-8':           //android
            return AIM_PREFIX_SI + AIM_PREFIX_EAN8 + data.data;

        // TODO add other types frome aboth
        default:
            return AIM_PREFIX_SI + AIM_PREFIX_CODE128 + data.data;
    }
}

export default {
    getArticleCode: (data: any) => {
        return new Promise<any>((resolve, reject) => {
            getBarcodeCode(data)
                .then((ean: string) => {
                    console.debug('ean', ean)
                    useArticlesApi.getBarcodeDecode(ean)
                        .then((barcodeRes) => {
                            console.debug('getBarcodeDecode', barcodeRes);
                            useArticlesApi.searchArticleByBarcode(barcodeRes.barcode_data.ean)
                                .then((res) => {
                                    console.debug('searchArticleByBarcode', res)
                                    if (!res)
                                        reject()
                                    if (res.length > 0)
                                        resolve(res[0].article_id)
                                    else
                                        reject()

                                })
                        })
                        .catch(reject)
                })
        })
    }
}