import React from "react";
import { View } from "react-native";
import { ErgoTask, ObjectInfo, StoredNewDocument } from '../../../types'
import DocType from '../../../constants/DocType'
import NewHeaderDDT from './NewHeaderDDT'
import NewHeaderDDTtransfer from './NewHeaderDDTtransfer'
import NewHeaderOF from './NewHeaderOF'
import NewHeaderOC from './NewHeaderOC'
import NewHeaderWarehouse from './NewHeaderWarehouse'
import NewDocumentStorage from '../../../utils/NewDocumentStorage'

export default function NewHeader(props: { navigation: any, constructionSite?: ObjectInfo, companyId?: number, task: ErgoTask, savedDocument?: StoredNewDocument, documentId: string }) {
    const docType = Number(props.task?.param_list?.DOCTYPE);

    let savedDocument = props.savedDocument;
    function setSavedDocument(value: StoredNewDocument) {
        savedDocument = value;
    }

    const documentId = props.documentId;

    const save = async (res: any) => {
        if (savedDocument) {
            savedDocument.positions = await NewDocumentStorage.loadPossition(documentId);
            // console.log('savedDocument.positions', savedDocument.positions)
        }
        props.navigation.navigate('AddMaterials', { task: props.task, header: JSON.stringify(res), savedDocumentId: documentId, savedDocument: JSON.stringify(savedDocument), documentId: documentId, companyId: props.companyId })
    }

    return (
        <View>
            {(docType === DocType.DDT) &&
                <NewHeaderDDT
                    constructionSite={props.constructionSite}
                    companyId={props.companyId}
                    task={props.task}
                    savedDocumentId={documentId}
                    savedHeader={props.savedDocument?.header}
                    onSavedDocumentChanged={setSavedDocument}
                    onSave={save} />
            }

            {(docType === DocType.DDT_TRANSFER) &&
                <NewHeaderDDTtransfer
                    constructionSite={props.constructionSite}
                    companyId={props.companyId}
                    task={props.task}
                    savedDocumentId={documentId}
                    savedHeader={props.savedDocument?.header}
                    onSavedDocumentChanged={setSavedDocument}
                    onSave={save} />
            }

            {(docType === DocType.OC) &&
                <NewHeaderOC
                    constructionSite={props.constructionSite}
                    companyId={props.companyId}
                    task={props.task}
                    savedDocumentId={documentId}
                    savedHeader={props.savedDocument?.header}
                    onSavedDocumentChanged={setSavedDocument}
                    onSave={save} />
            }

            {(docType === DocType.OF) &&
                <NewHeaderOF
                    constructionSite={props.constructionSite}
                    companyId={props.companyId}
                    task={props.task}
                    savedDocumentId={documentId}
                    savedHeader={props.savedDocument?.header}
                    onSavedDocumentChanged={setSavedDocument}
                    onSave={save} />
            }
            {(docType === DocType.WAREHOUSE) &&
                <NewHeaderWarehouse
                    constructionSite={props.constructionSite}
                    companyId={props.companyId}
                    task={props.task}
                    savedDocumentId={documentId}
                    savedHeader={props.savedDocument?.header}
                    onSavedDocumentChanged={setSavedDocument}
                    onSave={save} />
            }
        </View>
    )
}