import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import BottomTabNavigator from './BottomTabNavigator';
import { ColorSchemeName, StatusBar, View } from 'react-native';
import { LoginScreen } from '@infominds/react-native-license';
import NewScreen from '../screens/new/NewScreen';
import NewAddMaterialsScreen from '../screens/new/NewAddMaterialsScreen';
// import MainScreen from '../screens/main/MainScreen';
import MainCompanyScreen from '../screens/main/MainCompanyScreen';
import useLoginApi from '../apis/useLoginApi';
import DemoMode from '../constants/DemoMode';
import MainMasterScreen from '../screens/main/MainMasterScreen';

export default function Navigation(props: { colorScheme: ColorSchemeName }) {

  return (
    <NavigationContainer
      //linking={{ prefixes: [''], enabled: true }}
      theme={props.colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createStackNavigator();
function RootNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="Login"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="Login">
        {props =>
          <View style={{ flex: 1 }}>
            <StatusBar ></StatusBar>
            <LoginScreen {...props}
              customLoginFunction={useLoginApi.login}
              projectCode={'APP-EMWORK'}
              modulCode={'APP'}
              demoData={{ lic: DemoMode.DEMO_LICENSE_KEY, username: DemoMode.DEMO_USERNAME, password: DemoMode.DEMO_PASSWORD }}
              isOffline={false}
              getMandants={true}
              getUserInfo={true}
              // onSuccess={() => { console.log('HAaaaaaaaalloloo'); props.navigation.replace('SelectConstructionSite'); }}
              iconSource={require('../assets/img/icon.png')} />
          </View>
        }

      </Stack.Screen>

      <Stack.Screen name="Home" component={BottomTabNavigator} options={{ headerLeft: undefined }} />
      <Stack.Screen name="New" component={NewScreen} />
      <Stack.Screen name="Root" component={MainMasterScreen} />
      <Stack.Screen name="MainComapnyScreen" component={MainCompanyScreen} />
      <Stack.Screen name="AddMaterials" component={NewAddMaterialsScreen} />
      {/* <Stack.Screen name="ContactDetail" component={ContactsDetailScreen} /> */}
    </Stack.Navigator>
  );
}
