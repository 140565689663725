import React, { useEffect, useState } from "react";
import { Platform, TouchableOpacity } from 'react-native';
import { Colors, View, Text, CardBasic, Input, Layout } from '@infominds/react-native-components'
import IMIcon from '../components/IMIcon'

const Bold = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => <Text style={{ fontWeight: 'bold' }}>{props.children}</Text>

const NumberInput = (props: { value: number, onValueChange: any }) => {

    const [innerValue, setInnerValue] = useState<string>('');

    useEffect(() => {
        setInnerValue(props.value === 0 ? '' : props.value.toString().replace('.', ','));
    }, [props.value])

    return (
        <Input style={{ margin: 0, flex: 1, borderTopLeftRadius: 0, borderBottomLeftRadius: 8, borderTopRightRadius: 0, borderBottomRightRadius: 0, }}
            keyboardType='decimal-pad'
            value={innerValue}
            onChangeText={async (text) => {
                setInnerValue(text);
            }}
            onEndEditing={() => { props.onValueChange(Number.parseFloat(innerValue.replace(',', '.'))) }}
        />
    )
}


export default function AddMaterialCardV3(props: { item: any, onValueChange: any, initialQuantity: number, performanceBoost?: boolean }) {
    const [value, setValue] = useState<number>(() => props.initialQuantity || 0);
    const [visible, setVisible] = useState(props.initialQuantity > 0);

    function onValueChange(value: number) {
        setValue(value);
        props.onValueChange(props.item, value)
    }

    function decrementValue() {
        let newValue = value - 1;
        if (newValue < 0) {
            newValue = 0;
        }
        onValueChange(newValue);
    }

    function incrementValue() {
        let newValue = value + 1;
        onValueChange(newValue);
    }

    if (props.performanceBoost) {
        return (
            <CardBasic style={[{ flexDirection: 'row', alignItems: 'center', padding: 0 }, Platform.OS !== 'web' && { minHeight: 60 }]}>
                <View style={{ flex: 1 }} >
                    <TouchableOpacity style={{ flex: 1 }} onPress={() => { setVisible(true) }}>
                        <View style={{ flex: 5, flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{ flex: 1, padding: 8 }}><Bold>{props?.item?.article_id}</Bold> - {props.item?.description}</Text>
                            <Text style={{ textAlign: 'right', justifyContent: 'flex-end', padding: 8 }}>{props.item?.measure_unit?.description}</Text>
                        </View>
                    </TouchableOpacity>
                    {visible &&
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', padding: 0 }}>
                            <NumberInput
                                value={value}
                                onValueChange={onValueChange} />
                            <TouchableOpacity style={{ backgroundColor: Colors.modern.red, width: 45, justifyContent: 'center', alignItems: 'center' }}
                                onPress={() => { decrementValue(); }}>
                                <IMIcon color={Colors.white} icon={'minus'} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{ backgroundColor: Colors.tint, width: 45, justifyContent: 'center', alignItems: 'center', borderBottomRightRadius: 8 }}
                                onPress={() => { incrementValue(); }}>
                                <IMIcon color={Colors.white} icon={'plus'} />
                            </TouchableOpacity>
                        </View>}
                </View>
            </CardBasic>
        )
    }

    return (
        <CardBasic style={[{ flexDirection: 'row', alignItems: 'center', padding: 0 }, Platform.OS !== 'web' && { minHeight: 60 }]}>
            <View style={{ flex: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text style={{ flex: 1, padding: 8 }}><Bold>{props?.item?.article_id}</Bold> - {props.item?.description}</Text>
                    <Text style={{ textAlign: 'right', justifyContent: 'flex-end', padding: 8 }}>{props.item?.measure_unit?.description}</Text>
                </View>
            </View>
            <View style={{ flex: 0, minWidth: 50, padding: 0 }}>
                <NumberInput
                    value={value}
                    onValueChange={onValueChange}
                />
            </View>
            {Layout.isLargeDevice ?
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', padding: 0, height: '100%' }}>
                    <TouchableOpacity style={{ backgroundColor: Colors.tint, width: 40, justifyContent: 'center', alignItems: 'center' }}
                        onPress={() => { incrementValue(); }}>
                        <IMIcon color={Colors.white} icon={'plus'} />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ backgroundColor: Colors.modern.red, width: 40, justifyContent: 'center', alignItems: 'center', borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
                        onPress={() => { decrementValue(); }}>
                        <IMIcon color={Colors.white} icon={'minus'} />
                    </TouchableOpacity>
                </View>
                :
                <View style={{ padding: 0 }}>
                    <TouchableOpacity style={{ flex: 1, backgroundColor: Colors.tint, width: 30, justifyContent: 'center', alignItems: 'center', borderTopRightRadius: 8 }}
                        onPress={() => { incrementValue(); }}>
                        <Text style={{ fontSize: 20 }} color={Colors.white}>+</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flex: 1, backgroundColor: Colors.modern.red, width: 30, justifyContent: 'center', alignItems: 'center', borderBottomRightRadius: 8 }}
                        onPress={() => { decrementValue(); }}>
                        <Text style={{ fontSize: 20 }} color={Colors.white}>-</Text>
                    </TouchableOpacity>
                </View>
            }
        </CardBasic >
    )
}
