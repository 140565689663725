import { Colors, Icon, ModalBottom, Text, View } from "@infominds/react-native-components";
import React from "react";
import { TouchableOpacity } from "react-native";

export default function ContentModal(props: { isVisible: boolean, close: any, content: any, style?: any, title?: string, onResetSelection?: any }) {

  return (
    <ModalBottom
      isVisible={props.isVisible}
      close={props.close}
      backdropOpacity={0.4}
      style={[{
        padding: 0, boarderRadisTop: 8,
        width: '96%',
        marginLeft: '2%'
      }, props.style]}
      propagateSwipe
      KeyboardAvoiding={false}
      swipeDirection={[]}
      content={
        <View style={{ height: '100%' }}>
          <View style={{ padding: 20, paddingBottom: 10, backgroundColor: Colors.grey, borderTopLeftRadius: 10, borderTopRightRadius: 10, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text color={Colors.white}>{props.title}</Text>
            <View style={{ flexDirection: "row", justifyContent: 'flex-end' }}>
              {props.onResetSelection &&
                <TouchableOpacity onPress={props.onResetSelection} style={{ alignSelf: 'center', justifyContent: 'flex-end', marginRight: 10 }}>
                  <Icon name='trash' size={22} color={Colors.white} style={{ alignSelf: 'flex-end' }}></Icon>
                </TouchableOpacity>
              }
              <TouchableOpacity onPress={props.close} style={{ justifyContent: 'flex-end' }}>
                <Icon name='x' size={28} color={Colors.white} style={{ alignSelf: 'flex-end' }}></Icon>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{
            flex: 1,
            height: '100%',
            padding: 0,
            marginTop: 0,
            paddingBottom: 0,
            marginBottom: 0
          }}>
            {props.content()}
          </View>
        </View>
      }
    />
  )
}
