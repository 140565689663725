import Icon from 'react-native-vector-icons/Feather';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import TabHomeScreen from '../screens/home/TabHomeScreen';
import ArchiveScreen from '../screens/history/ArchiveScreen';
import TabContactsMasterDetailScreen from '../screens/contacts/ContactsScreen';
import { Layout } from '@infominds/react-native-components';
import { Colors } from '../constants/Colors';
import ContactsDetailScreen from '../screens/contacts/DetailContactsScreen';
import DetailArchiveScreen from '../screens/history/DetailArchiveScreen';
import IMIcon from '../components/IMIcon';

const BottomTab = createBottomTabNavigator();
export default function BottomTabNavigator(props: { route: any, navigation: any }) {

  // const detail = props.route?.params?.detail; //here I get the parameter from the previous screen

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      //screenOptions={{ lazy: true }}
      tabBarOptions={{
        activeTintColor: Colors.tint,
        showLabel: true,
      }}>

      <BottomTab.Screen
        name='Home'
        component={TabHomeNavigator}
        // initialParams={{ detail: detail }}
        options={{
          title: '',
          tabBarIcon: ({ color }: any) => <IMIcon
            color={color}
            size={35}
            icon={'homeLg'}
            style={[Layout.isSmallDevice ? { marginBottom: -13 } : { marginBottom: 0, marginLeft: 5, marginRight: -15 }]}
          />
        }}
      />

      <BottomTab.Screen
        name='Contact'
        component={TabContactNavigator}
        options={{
          title: '',
          tabBarIcon: ({ color }: any) =>
            <IMIcon
              color={color}
              size={35}
              icon={'addressBook'}
              style={[Layout.isSmallDevice ? { marginBottom: -13 } : { marginBottom: 0, marginLeft: 5, marginRight: -15 }]}
            />
        }}
      />

      <BottomTab.Screen
        name='Archive'
        component={TabArchiveNavigator}
        options={{
          title: '',
          tabBarIcon: ({ color }: any) => <IMIcon
            color={color}
            size={35}
            icon={'archive'}
            style={[Layout.isSmallDevice ? { marginBottom: -13 } : { marginBottom: 0, marginLeft: 5, marginRight: -15 }]}
          />
        }}
      />
    </ BottomTab.Navigator>
  );
}

const TabHomeStack = createStackNavigator();
function TabHomeNavigator() {
  return (
    <TabHomeStack.Navigator screenOptions={{ headerShown: false }}>
      <TabHomeStack.Screen
        name="TabHomeScreen"
        component={TabHomeScreen}
      />
    </TabHomeStack.Navigator >
  );
}

const TabContactStack = createStackNavigator();
function TabContactNavigator() {
  return (
    <TabContactStack.Navigator screenOptions={{ headerShown: false }}>
      <TabContactStack.Screen
        name="TabContactScreen"
        component={TabContactsMasterDetailScreen}
      />
      <TabContactStack.Screen
        name="ContactsDetailScreen"
        component={ContactsDetailScreen}
      />
    </TabContactStack.Navigator >
  );
}

const TabArchiveStack = createStackNavigator();
function TabArchiveNavigator() {
  return (
    <TabArchiveStack.Navigator screenOptions={{ headerShown: false }}>
      <TabArchiveStack.Screen
        name="TabArchiveScreen"
        component={ArchiveScreen}
      />
      <TabHomeStack.Screen
        name="ArchiveDetailScreen"
        component={DetailArchiveScreen}
      />
    </TabArchiveStack.Navigator>
  );
}