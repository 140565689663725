import * as React from 'react'
import { Colors, LanguageContext, ThemeContext, View, Screen, Icon, Layout } from '@infominds/react-native-components';
import { useEffect, useState } from 'react';
import useApi from '../../apis/useApi';
import ContactCard from '../../cards/ContactCard';
import { Platform, RefreshControl, SafeAreaView, StatusBar, TouchableOpacity, StyleSheet, Linking } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import ObjectContext from '../../contexts/ObjectContext';
import { Contact } from '../../types';
import TitleBar from '../../components/TitleBar';
import SearchBoxDebounced from '../../components/SearchBoxDebounced';

export default function MasterContactsScreen(props: { navigation: any, setDetail: any }) {
  const lang = React.useContext(LanguageContext);
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const { object } = React.useContext(ObjectContext)
  const companyId = React.useContext(ObjectContext).object?.companyId;

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<Contact[]>([]);
  const [selected, setSelected] = useState<Contact>();

  useEffect(() => { load() }, [])

  useEffect(() => { load() }, [searchText])

  function load() {
    setLoading(true);
    let apiCall;
    if (object && object.object)
      apiCall = useApi.getObjectContacts(object.object.object_id)
    else if (companyId) {
      apiCall = useApi.getCompanyContacts(companyId)
    }
    if (apiCall)
      apiCall.then((res: Contact[]) => {
        setData(filterData(res))

        if (Layout.isLargeDevice && res.length > 0) {
          setSelected(res[0])
          props.setDetail(res[0])
        }
      }).catch(console.error)
        .finally(() => setLoading(false));
  }

  function filterData(dataToFilter: Contact[]) {
    return dataToFilter.filter((item: Contact) => {
      return item.description.toUpperCase().includes(searchText.toUpperCase()) || item.first_name.toUpperCase().includes(searchText.toUpperCase()) || item.last_name.toUpperCase().includes(searchText.toUpperCase())
    });
  }

  const onItemClick = (item: Contact) => {
    if (Layout.isSmallDevice)
      props.navigation.navigate('ContactsDetailScreen', { detail: item });
    else {
      props.setDetail(item);
      setSelected(item);
    }
  }

  const renderItem = ({ item, index }: { item: Contact, index: number }) => (
    <ContactCard
      color={theme.card}
      item={item}
      title={item.description}
      description={item.position}
      description2={item.phone}
      isSelected={selected === item}
      iconname={'user'}
      onPress={onItemClick}
    />
  );

  const renderHiddenItem = ({ item }: { item: Contact }) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => { Linking.openURL(`tel:${item.phone}`) }}>
        <Icon family='FontAwesome5' size={28} style={styles.backTextWhite} name="phone" />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={() => Linking.openURL(`mailto:${item.email}`)}>
        <Icon family='FontAwesome5' size={28} style={styles.backTextWhite} name="at" />
      </TouchableOpacity>
    </View>
  );

  const Refresh = (
    <RefreshControl
      refreshing={loading}
      onRefresh={() => load()} colors={[Colors.tint]} tintColor={Colors.tint} />
  )

  const keyExtractor = (_item: Contact, index: number) => index.toString()

  return (
    <Screen backgroundImage={Layout.isSmallDevice ? 'ellipse6' : 'ellipseFull'} style={{ padding: 0, marginBottom: 0 }} scrollable={false} >
      <SafeAreaView style={{ flex: 1, paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0 }}>

        <TitleBar title={lang.CONTACT} />

        <SearchBoxDebounced style={{ marginHorizontal: 10 }}
          value={searchText}
          placeholder={lang.SEARCH}
          onChangeText={(serachString: string) => { setSearchText(serachString) }}></SearchBoxDebounced>

        <SwipeListView
          style={{ padding: 5 }}
          stickySectionHeadersEnabled={false}
          data={data}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          refreshControl={Refresh}
          renderHiddenItem={renderHiddenItem}
          leftOpenValue={0}
          disableRightSwipe={true}
          rightOpenValue={-150}
          recalculateHiddenLayout={true}
          ListFooterComponent={<View style={{ height: 10 }}></View>}
        />

        <View style={{ margin: 1 }}></View>
      </SafeAreaView>
    </Screen >
  )
}

const styles = StyleSheet.create({
  rowBack: {
    marginTop: 5,
    marginBottom: 7,
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#6A7079',
    marginRight: 7,
    marginLeft: 30,
    borderRadius: 13,
  },
  backTextWhite: {
    color: '#FFF'
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
    height: '100%',
  },
  backRightBtnLeft: {
    backgroundColor: '#6A7079',
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: Colors.grey,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    right: 0,
  }
});