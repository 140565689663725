import React, { useContext, useEffect, useState } from "react";
import { View } from "react-native";
import { Client, ConstructionLot, ConstructionSite, ErgoTask, Freight, HeaderDDTtransfer, ObjectInfo, StoredNewDocument, TransportReason } from '../../../types'
import useApi from '../../../apis/useApi'
import { Button, Label, LanguageContext, Note } from "@infominds/react-native-components";
import ErrorMessage from "../../../components/ErrorMessage";
import NewDocumentStorage from "../../../utils/NewDocumentStorage";
import SelectionBox from "../../../cards/SelectionBox";
import Selection from "../../../cards/Selection";


export default function NewHeaderDDTtransfer(props: { constructionSite?: ObjectInfo, companyId?: number, task: ErgoTask, onSave: any, onSavedDocumentChanged: any, savedDocumentId: string, savedHeader: any }) {
    const lang = useContext(LanguageContext);

    const [errorMessage, setErrorMessage] = useState<string>();

    const [selectedConstructionSiteLoad, setSelectedConstructionSiteLoad] = useState((props.savedHeader && props.savedHeader.selectedConstructionSiteLoad) || props.constructionSite);
    const [selectedConstructionSiteUnLoad, setSelectedConstructionSiteUnLoad] = useState((props.savedHeader && props.savedHeader.selectedConstructionSiteUnLoad) || undefined);

    const [constructionSites, setConstructionSites] = useState<ConstructionSite[]>([]);
    const [constructionSitesUnload, setConstructionSitesUnload] = useState<ConstructionSite[]>([]);

    const [note, setNote] = useState<string>((props.savedHeader && props.savedHeader.note) || '');

    const [client, setClient] = useState<Client | undefined>((props.savedHeader && props.savedHeader.client) || undefined);

    const [objectLots, setObjectLots] = useState<ConstructionLot[]>([]);
    const [objectLotsDest, setObjectLotsDest] = useState<ConstructionLot[]>([]);
    const [objectLotLoad, setObjectLotLoad] = useState<ConstructionLot>((props.savedHeader && props.savedHeader.objectLotLoad) || undefined);
    const [objectLotUnLoad, setObjectLotUnLoad] = useState<ConstructionLot>((props.savedHeader && props.savedHeader.objectLotUnLoad) || undefined);

    const [transportReasons, setTransportReasons] = useState<TransportReason[]>([]);
    const [transportReason, setTransportReason] = useState<TransportReason | undefined>((props.savedHeader && props.savedHeader.transportReason) || undefined);

    // const [transportModes, setTransportModes] = useState<TransportMode[]>([]);
    // const [transportMode, setTransportMode] = useState<TransportMode | undefined>((props.savedHeader && props.savedHeader.transportMode) || undefined);

    const [freight, setFreight] = useState<Freight>();
    const [freights, setFreights] = useState<Freight[]>((props.savedHeader && props.savedHeader.freight) || []);

    useEffect(() => {
        useApi.getObjects().then((res: ConstructionSite[]) => {
            // console.log('getObjects', res)
            setConstructionSitesUnload(res.filter(item => item.object_id !== selectedConstructionSiteLoad.object_id))
        });

        useApi.getCompany(props.constructionSite ? props.constructionSite?.company?.company_id : props.companyId).then((res: Client) => {
            if (res) setClient(res)
        })


        if (props.constructionSite?.object_id) {
            // TODOD check if filter needed for customer
            useApi.getCompanyObjects(props.constructionSite?.company.company_id).then((res: ConstructionSite[]) => {
                setConstructionSites(res)
            });
            useApi.getOpenObjectLots(props.constructionSite?.object_id).then((res: ConstructionLot[]) => {
                setObjectLots(res)
            });
        } else {
            useApi.getCompanyObjects(props.companyId).then((res: ConstructionSite[]) => {
                setConstructionSites(res)
            });
        }

        useApi.getTransportReasons().then((res: TransportReason[]) => { setTransportReasons(res) })
        // useApi.getTransportModes().then((res: TransportMode[]) => { setTransportModes(res) })
        useApi.getFreights().then((res: Freight[]) => { console.log(res); setFreights(res) }).catch(console.error)

    }, [])

    // useEffect(() => {
    //     if (selectedConstructionSiteLoad) {
    //         setConstructionSitesUnload(constructionSitesUnload.filter(item => item.object_id !== selectedConstructionSiteLoad.object_id))
    //     }
    // }, [selectedConstructionSiteLoad])

    // TODO test if  => Los bei Zielbaustelle werden jene der Startbaustelle angezeig
    useEffect(() => {
        if (selectedConstructionSiteUnLoad?.object_id) {
            useApi.getOpenObjectLots(selectedConstructionSiteUnLoad?.object_id).then((res: ConstructionLot[]) => {
                setObjectLotsDest(res)
            });
        }
    }, [selectedConstructionSiteUnLoad])

    //save on every change
    useEffect(() => {
        const newDoc: StoredNewDocument = {
            date: props.savedDocumentId,
            docType: Number(props.task.param_list.DOCTYPE),
            header: {
                client: client,
                selectedConstructionSiteLoad: selectedConstructionSiteLoad,
                selectedConstructionSiteUnLoad: selectedConstructionSiteUnLoad,
                objectLotLoad: objectLotLoad,
                objectLotUnLoad: objectLotUnLoad,
                transportReason: transportReason,
                // transportMode: transportMode,
                freight: freight,
                note: note
            }
        }
        NewDocumentStorage.save(props.constructionSite?.object_id || props.companyId, props.task?.task_id, newDoc)
        props.onSavedDocumentChanged(newDoc)
    }, [client, selectedConstructionSiteLoad, selectedConstructionSiteUnLoad, objectLotLoad, objectLotUnLoad, transportReason, freight, note])

    function save() {
        if (client && selectedConstructionSiteLoad && selectedConstructionSiteUnLoad) { // objectLotLoad && objectLotUnLoad && transportReason && transportMode
            let data: HeaderDDTtransfer = {
                client: client,
                object: selectedConstructionSiteLoad,
                objectUnload: selectedConstructionSiteUnLoad,
                objectLot: objectLotLoad,
                objectLotUnload: objectLotUnLoad,
                transportReason: transportReason,
                // transportMode: transportMode,
                freight: freight,
                note: note
            }
            props.onSave(data)
            setErrorMessage(undefined)
        } else {
            setErrorMessage(lang.REQUIRED_FIELDS);
        }
    }

    return (
        <View>
            <SelectionBox title={lang.CONSTRUCTIONSITE_FROM}>
                <Selection
                    title={lang.CONSTRUCTIONSITE_FROM}
                    icon={'hardHat'}
                    selected={selectedConstructionSiteLoad}
                    required
                    showError={!!errorMessage && !selectedConstructionSiteLoad}
                    value={constructionSites}
                    getId={(item: ConstructionSite) => item.object_id}
                    itemTitle={(item: ObjectInfo) => item.description}
                    searchString={(item: ObjectInfo) => item.description}
                    onChange={(item: ObjectInfo) => setSelectedConstructionSiteLoad(item)}
                />

                <Selection
                    title={lang.LOTTO_FROM}
                    icon={'pencilRuler'}
                    hidden={objectLots?.length === 0}
                    selected={objectLotLoad}
                    value={objectLots}
                    getId={(item: ConstructionLot) => item.object_lot_id}
                    itemTitle={(item: ConstructionLot) => item.description}
                    searchString={(item: ConstructionLot) => item.description}
                    onChange={(item: ConstructionLot) => setObjectLotLoad(item)}
                />
            </SelectionBox>

            <SelectionBox title={lang.CONSTRUCTIONSITE_TO}>
                <Selection
                    title={lang.CONSTRUCTIONSITE_TO}
                    icon={'hardHat'}
                    selected={selectedConstructionSiteUnLoad}
                    required
                    showError={!!errorMessage && !selectedConstructionSiteUnLoad}
                    value={constructionSitesUnload}
                    getId={(item: ConstructionSite) => item.object_id}
                    itemTitle={(item: ObjectInfo) => item.description}
                    searchString={(item: ObjectInfo) => item.description}
                    onChange={(item: ObjectInfo) => setSelectedConstructionSiteUnLoad(item)}
                />

                <Selection
                    title={lang.LOTTO_TO}
                    icon={'pencilRuler'}
                    hidden={objectLotsDest?.length === 0}
                    selected={objectLotUnLoad}
                    value={objectLotsDest}
                    getId={(item: ConstructionLot) => item.object_lot_id}
                    itemTitle={(item: ConstructionLot) => item.description}
                    searchString={(item: ConstructionLot) => item.description}
                    onChange={(item: ConstructionLot) => setObjectLotUnLoad(item)}
                />
            </SelectionBox>


            <SelectionBox title={lang.TRANSPORT_DATA}>
                <Selection
                    title={lang.TRANSPORT_REASON}
                    icon={'shippingFast'}
                    selected={transportReason}
                    value={transportReasons}
                    // getId={(item: TransportReason) => item.id}
                    itemTitle={(item: TransportReason) => item.description}
                    searchString={(item: TransportReason) => item.description}
                    onChange={(item: TransportReason) => setTransportReason(item)}
                />

                {/* <Selection
                    title={lang.TRANSPORT_MODE}
                    icon={faShippingFast}
                    selected={transportMode}
                    value={transportModes}
                    itemTitle={(item: TransportMode) => item.description}
                    searchString={(item: TransportMode) => item.description}
                    onChange={(item: TransportMode) => setTransportMode(item)}
                /> */}

                <Selection
                    title={lang.FREIGHT}
                    icon={'truckContainer'}
                    selected={freight}
                    value={freights}
                    getId={(item: Freight) => item.id}
                    itemTitle={(item: Freight) => item.description}
                    searchString={(item: Freight) => item.description}
                    onChange={(item: Freight) => setFreight(item)}
                />
            </SelectionBox>


            <View>
                <Label text={lang.NOTE} />
                <Note value={note} onChangeText={(text: string) => setNote(text)} />
            </View>

            <ErrorMessage errorMessage={errorMessage} />

            <Button style={{ marginTop: 20 }} title={lang.NEXT} onPress={() => { save() }} />

        </View >
    )
}