import React from 'react';
import { TouchableWithoutFeedback, Keyboard, View } from 'react-native';

// @ts-ignore
const DismissKeyboardHOC = (Comp) => {
    // @ts-ignore
    return ({ children, ...props }) => (
        <TouchableWithoutFeedback style={{ flex: 1 }} onPress={Keyboard.dismiss} accessible={false}>
            <Comp style={{ flex: 1 }} {...props}>
                {children}
            </Comp>
        </TouchableWithoutFeedback>
    );
};
export default DismissKeyboardHOC(View)