import * as React from 'react';
import { Icon, ThemeContext, Colors, View, LanguageContext, LoadingSpinnerModal, Text, Layout } from '@infominds/react-native-components';
import { useEffect, useState } from 'react';
import { RefreshControl, TouchableOpacity, StyleSheet, ImageBackground, Platform } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import SettingsModal from '../../modals/SettingsModal';
import useApi from '../../apis/useApi';
import { DetailObject, HomeItem, ObjectInfo } from '../../types';
import SelectConstructionSiteItem from '../../cards/SelectConstructionSiteItem'
import { SafeAreaView } from 'react-native-safe-area-context';
import Storage from '../../hooks/useAsyncStorage';
import TitleBar from '../../components/TitleBar';
import MainCompanyScreen from './MainCompanyScreen';
import MasterDetailVisible from '../../components/MasterDetailVisible';
import usePersistenceApi from '../../apis/usePeristenceApi';
import ObjectContext from '../../contexts/ObjectContext';
import IMIcon from '../../components/IMIcon';
import Images from '../../assets/img/BackgroundImages'
import SearchBoxDebounced from '../../components/SearchBoxDebounced';
import NewDocumentStorage from '../../utils/NewDocumentStorage';

const MAX_LAST_USED = 3;

interface SectionItem {
  title: string;
  data: HomeItem[]
}

enum FilterType {
  "ALL", "CONSTRUCTION_SITE", "CUSTOMER"
}


export default function MainMasterScreen(props: { navigation: any, route: any }) {
  const lang = React.useContext(LanguageContext);
  const colorScheme = React.useContext(ThemeContext);
  const theme = Colors[colorScheme];

  const { setObject } = React.useContext(ObjectContext)

  const [loading, setLoading] = useState(true);
  const [openDetailLoading, setOpenDetailLoading] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const toggleSearchBar = () => { setSearchBarVisible(!searchBarVisible) }

  const [searchText, setSearchText] = useState('');

  const [filter, setFilter] = useState<FilterType>(FilterType.ALL)

  let [data, setData] = useState<SectionItem[]>([]);

  let [all, setAll] = useState<HomeItem[]>([]);
  let [favorites, setFavorites] = useState<HomeItem[]>([]);
  let [lastUsed, setLastUsed] = useState<HomeItem[]>([]);

  let [selected, setSelected] = useState<HomeItem>();

  let [countOfOpenDocuments, setCountOfOpenDocuments] = useState<any>();

  // useFocusEffect(React.useCallback(() => {
  //   NewDocumentStorage.countOpenItems().then((result: any) => {
  //     setCountOfOpenDocuments(result)
  //   })
  // }, []))

  useEffect(() => {
    // TODO validate if objects from storage are still valid objects

    NewDocumentStorage.countOpenItems().then((result: any) => {
      setCountOfOpenDocuments(result)
      Storage('favorites').load().then((res) => { if (res != null && res.length > 0) setFavorites(res) }).catch(() => setFavorites([]))
        .finally(() => {
          Storage('lastUsed').load().then((res) => { if (res != null && res.length > 0) setLastUsed(res) }).catch(() => setLastUsed([]));
        }).finally(() => {
          reload()
        })
    })
  }, [])

  useEffect(() => { if (all.length > 0) loadData(); }, [all])

  useEffect(() => { if (all.length > 0) loadData(); }, [filter])

  useEffect(() => { if (all.length > 0) loadData(); }, [searchText])

  function filterData(dataToFilter: HomeItem[]) {
    const upperSearchText = searchText.toUpperCase();
    if (filter === FilterType.ALL && searchText === '') {
      return dataToFilter
    } else if (filter === FilterType.ALL) {
      return dataToFilter.filter((item: HomeItem) => {
        return item?.title?.toUpperCase().includes(upperSearchText) || item?.comapny_id.toString().includes(upperSearchText) || item?.object_id.toString().includes(upperSearchText)
      });
    } else
      return dataToFilter.filter((item: HomeItem) => {
        return (item?.title?.toUpperCase().includes(upperSearchText) || item?.comapny_id.toString().includes(upperSearchText) || item?.object_id.toString().includes(upperSearchText)) &&
          (
            (filter === FilterType.CUSTOMER ? !item.is_object : false) ||
            (filter === FilterType.CONSTRUCTION_SITE ? item.is_object : false)
          )
      });
  }

  function loadData() {
    data = []

    if (lastUsed.length > 0) data.push({ title: lang.LAST_USED, data: filterData(lastUsed) });
    if (favorites.length > 0) data.push({ title: lang.FAVORITES, data: filterData(favorites) });
    if (all && all.length > 0) data.push({ title: lang.ALL, data: filterData(all) });

    setData(data)
  }

  function reload(forceOnline: boolean = false) {
    setLoading(true)
    usePersistenceApi.getSelectionList(
      (res: HomeItem[]) => {
        setAll(res);
        setLoading(false)
      },
      (err: any) => {
        console.error(err);
        setLoading(false);
      },
      forceOnline
    );
  }

  async function addOrRemoveFavorites(item: HomeItem) {
    const index = favorites.findIndex(x => x.title === item.title);
    if (index === -1) {
      favorites.push(item);
    } else {
      favorites.splice(index, 1)
    }
    setFavorites([...favorites])
    Storage('favorites').save(favorites);
    loadData();
  }

  async function addLastUsed(item: HomeItem) {
    const index = lastUsed.findIndex(x => x.object_id === item.object_id);
    if (index === -1) {
      if (lastUsed.length >= MAX_LAST_USED)
        lastUsed.pop();
      lastUsed.unshift(item);
      // do not set last used to no scroll the list, will be visible the next time the view is rendered.
      Storage('lastUsed').save(lastUsed);
    }
  }

  async function open(item: HomeItem, withInfoOpen: boolean) {
    setOpenDetailLoading(true);
    openDetail(item, withInfoOpen).finally(() => setOpenDetailLoading(false))
  }

  async function openDetail(item: HomeItem, withInfoOpen: boolean) {
    if (item.is_object) {
      let res: ObjectInfo = await useApi.getObjectDetail(item.object_id)
      const info: DetailObject = {
        infoVisible: withInfoOpen,
        companyId: undefined,
        object: res
      };
      setObject(info);
      await addLastUsed(item)
      props.navigation.navigate('Home');
    } else {
      addLastUsed(item)
      setSelected(item);
      if (Layout.isSmallDevice) {
        // setLastUsed([...lastUsed])
        props.navigation.navigate('MainComapnyScreen', { detail: item, countOfOpenDocuments: countOfOpenDocuments });
      }
    }
  }

  // const memoizedRenderItem = React.useCallback(({ item, index }: { item: HomeItem, index: number }) => renderItem({ item, index }), [data])

  const memoizedRenderItem = ({ item, index }: { item: HomeItem, index: number }) => {
    return (
      <SelectConstructionSiteItem
        color={theme.card}
        title={item.title}
        description={item.description1}
        description2={item.description2}
        id={item.is_object ? item?.object_id?.toString() : item?.comapny_id?.toString()}
        count={item.is_object ? countOfOpenDocuments['object' + item.object_id] ? countOfOpenDocuments['object' + item.object_id] : 0 : countOfOpenDocuments[item.comapny_id] ? countOfOpenDocuments[item.comapny_id] : 0}
        isSelected={Layout.isLargeDevice && selected?.comapny_id === item.comapny_id && !item.is_object}
        icon={item.is_object ? 'hardHat' : 'building'}
        onPress={() => { open(item, false); }}
      />
    );
  };
  const renderSectionHeader = ({ section: { title } }: any) => {
    return (
      <View>
        <Text style={styles.listHeader}>{title}</Text>
      </View>
    )
  };

  const renderHiddenItem = ({ item }: { item: HomeItem }) => (
    <View style={styles.rowBack}>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnLeft]}
        onPress={() => { addOrRemoveFavorites(item) }} >
        <Icon size={28} style={styles.backTextWhite} name="star" />
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.backRightBtn, styles.backRightBtnRight]}
        onPress={() => { open(item, true); }} >
        <Icon size={28} style={styles.backTextWhite} name="info" />
      </TouchableOpacity>
    </View>
  );


  const GroupFilter = (groupFilterProps: { style?: any }) => {

    const GroupItem = (groupItemProps: { onPress: any, label: string, selected: boolean }) => (
      <TouchableOpacity
        onPress={groupItemProps.onPress} style={{ paddingTop: 5, paddingBottom: 5 }} >
        <View
          style={{ backgroundColor: groupItemProps.selected ? colorScheme === 'light' ? Colors.grey : Colors.white : theme.inputBackground, borderRadius: 15, marginRight: 10, padding: 2, paddingHorizontal: 5 }}>
          <Text style={{ color: groupItemProps.selected ? (colorScheme === 'light' ? Colors.white : Colors.black) : theme.text }}>{groupItemProps.label}</Text>
        </View>

      </TouchableOpacity>
    )
    return (
      <View style={[{ flex: 1, flexDirection: 'row' }, groupFilterProps?.style]}>
        <GroupItem
          label={lang.ALL}
          selected={filter === FilterType.ALL}
          onPress={() => { setFilter(FilterType.ALL); }} />
        <GroupItem
          label={lang.CLIENT}
          selected={filter === FilterType.CUSTOMER}
          onPress={() => { setFilter(FilterType.CUSTOMER); }} />
        <GroupItem
          label={lang.CANTIERE}
          selected={filter === FilterType.CONSTRUCTION_SITE}
          onPress={() => { setFilter(FilterType.CONSTRUCTION_SITE); }} />
      </View >
    )
  }

  const SearchButton = (searchButtonProps: {}) => (
    <TouchableOpacity
      onPress={() => { toggleSearchBar() }}
      style={{
        backgroundColor: Colors.grey,
        borderRadius: 15,
        marginRight: 10,
        marginVertical: 5,
        padding: 2,
        paddingHorizontal: 5,
        paddingVertical: 0,
        justifyContent: 'center'
      }} >
      <IMIcon style={{ margin: 5 }} color={Colors.white} icon={'search'} />
    </TouchableOpacity >
  )

  const ItemSeparator = () => (<View style={{ height: 5 }}></View>);

  const ListFooter = <View style={Platform.OS !== 'web' && { height: 200 }}></View>

  const RefreshView = (
    <RefreshControl
      refreshing={loading}
      onRefresh={() => {
        NewDocumentStorage.countOpenItems().then((result: any) => {
          setCountOfOpenDocuments(result)
        });
        reload(true)
      }}
      colors={[Colors.tint]}
      tintColor={Colors.tint} />)


  function MasterView() {
    return (
      <View style={[{ flex: 1 }, Layout.isSmallDevice ? { marginHorizontal: 5, } : { marginLeft: 5 }]}>
        <View style={{ marginLeft: 8, marginBottom: 0, flexDirection: 'row', justifyContent: 'space-between' }}>
          <GroupFilter />

          <SearchButton />
        </View>

        {searchBarVisible &&
          <SearchBoxDebounced
            placeholder={lang.SEARCH}
            value={searchText}
            onChangeText={(serachString: string) => { setSearchText(serachString) }} />}

        <SwipeListView
          style={{ flex: 1, padding: 5 }}
          stickySectionHeadersEnabled={false}
          sections={data}
          useSectionList={true}
          renderItem={memoizedRenderItem}
          keyExtractor={(item: any, index: number) => index.toString()}
          refreshing={loading}
          scrollEventThrottle={1000}
          refreshControl={RefreshView}
          ItemSeparatorComponent={ItemSeparator}
          renderSectionHeader={renderSectionHeader}
          renderHiddenItem={renderHiddenItem}
          leftOpenValue={0}
          disableRightSwipe={true}
          rightOpenValue={-150}
          recalculateHiddenLayout={true}
          ListFooterComponent={ListFooter}

          // initialNumToRender={8}
          // maxToRenderPerBatch={2}
          // onEndReachedThreshold={0.5}
          removeClippedSubviews={true} // Unmount components when outside of window 
          initialNumToRender={10} // Reduce initial render amount
          maxToRenderPerBatch={10} // Reduce number in each render batch
          updateCellsBatchingPeriod={100} // Increase time between renders
          windowSize={7} // Reduce the window size
        />
      </View>
    )
  }

  return (
    <ImageBackground
      source={{ uri: Images.ellipse19 }}
      imageStyle={{ opacity: colorScheme === 'dark' ? 0.8 : 1 }}
      resizeMode='stretch'
      style={{ flex: 1, height: '25%', opacity: 1, backgroundColor: theme.background }}>

      <SettingsModal
        isVisible={settingsVisible}
        close={() => setSettingsVisible(false)}
        onSettingsChange={() => reload(true)}
        navigation={props.navigation}
      />

      <SafeAreaView style={[Layout.isLargeDevice && { maxHeight: Layout.window.height }, Platform.OS === 'android' && { flex: 1 }]}>
        <TitleBar
          title={lang.ERGOMOBILEWORK}
          onSettingsPress={() => setSettingsVisible(true)}
        />

        <MasterDetailVisible
          master={<MasterView />}
          detail={<MainCompanyScreen navigation={props.navigation} route={props.route} detail={selected} countOfOpenDocuments={countOfOpenDocuments}></MainCompanyScreen>}
          isDetailVisible={selected?.object_id !== undefined} />
        <LoadingSpinnerModal visible={openDetailLoading} />
      </SafeAreaView>
    </ImageBackground >
  )
}


const styles = StyleSheet.create({
  rowBack: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: '#6A7079',
    marginRight: 6,
    marginLeft: 30,
    borderRadius: 12,
  },
  backTextWhite: {
    color: '#FFF'
  },
  backRightBtn: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    width: 75,
    height: '100%',
  },
  backRightBtnLeft: {
    backgroundColor: '#6A7079',
    right: 75,
  },
  backRightBtnRight: {
    backgroundColor: Colors.grey,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    right: 0,
  },
  listHeader: {
    margin: 10,
    fontSize: 16,
    // fontWeight: 'bold'
  },
});