import { Icon, View, Text, Colors, Layout, ThemeContext } from '@infominds/react-native-components';
import React, { useCallback, useContext, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone'
import { ImageBackground, Platform, TouchableOpacity } from 'react-native';

// import { ImageBackground, Platform, TouchableOpacity } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import base64 from 'react-native-base64'

// import AudioPicker from './AudioPicker';
// import ImagePicker from './ImagePicker';
// import MediaPlayer from './MediaPlayer';

const WIDTH = (Layout.window.width - 100) / 2;

export default function MediaGrid(props: {}) {
    let [data, setData] = useState<any[]>([]);
    const [current, setCurrent] = useState<any>();

    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const onDrop = useCallback((acceptedFiles: any[]) => {
        acceptedFiles.forEach((file: any) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result as string
                // console.log(binaryStr)
                var tmp = {

                    base64: binaryStr,
                    uri: binaryStr, //.replace('data:image/jpeg;base64,', ''),
                    type: 'image'   // TODO implement
                    // width?: number;
                    // height?: number;
                    // fileSize?: number;
                    // type?: string;
                    // fileName?: string;
                    // duration?: number;
                }
                data.push(tmp)
                //@ts-ignore
                setData([...data]);
            }
            reader.readAsDataURL(file)
        })

    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (<>
        <View>
            <FlatGrid
                itemDimension={WIDTH - 20}
                data={data}
                renderItem={({ item, index }) => (
                    <ImageBackground
                        style={{ borderRadius: 10, overflow: 'hidden', height: WIDTH, width: WIDTH - 15 }}
                        resizeMode='cover'
                        source={item}>
                        <TouchableOpacity
                            onPress={() => { setCurrent(undefined); setCurrent(item); }}
                            style={{ backgroundColor: (item.type === 'audio' || (Platform.OS === 'ios' && item.type.includes('video'))) ? Colors.tint : 'transparent', borderRadius: 10, padding: 10 }}>
                            <TouchableOpacity
                                style={{ alignSelf: 'flex-end', backgroundColor: '#fff', borderRadius: 15 }}
                                onPress={() => {
                                    data.splice(index, 1);
                                    setData([...data]);
                                    setCurrent(undefined);
                                }}>
                                <Icon name='x' color={Colors.black} size={25} />
                            </TouchableOpacity>

                            <View style={{ justifyContent: 'center', alignItems: 'center', height: 200, paddingBottom: 60 }}>
                                {(item.type === 'audio') && <Icon name='mic' size={50} />}
                                {item.type.includes('video') && <Icon name='play' size={50} />}
                            </View>
                        </TouchableOpacity>
                    </ImageBackground>
                )}
            />
        </View>

        <View style={{ margin: 10, padding: 10, borderRadius: 8, backgroundColor: theme.inputBackground }}>
            <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }: any) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                    </section>
                )}
            </Dropzone>
        </View>
    </>);
}