import LicenseGlobals from '@infominds/react-native-license';
import useApi from '../apis/useApi'
import { CreateDocument, DocumentPosition, Position, Media, DocumentPost, ErgoTask, StoredNewDocument, Rabat } from '../types';
import DoctypeUtils from './DoctypeUtil'
import CreateDocumentStorage from './CreatedDocumentStorage'
import NewDocumentStorage from './NewDocumentStorage';
import { Alert, Platform } from 'react-native';
import MediaUtils from '../components/media/MediaUtils';
import DocType from '../constants/DocType';

const MAX_MEDIA_SIZE = 20; // megabyte

function getYear() {
    return new Date().getFullYear();
}

function getRabats(currentPosition: Position) {
    let result = [];
    let rabat: Rabat | undefined = undefined

    if (!currentPosition.hasSellPrice && currentPosition.hasBuyPrice)
        rabat = currentPosition.rabat
    else if (currentPosition.hasSellPrice)
        rabat = currentPosition.rabat
    else if (currentPosition.hasBuyPrice)
        rabat = currentPosition.buyRabat

    if (rabat) {
        if (rabat?.value1) result.push(Number(rabat?.value1))
        if (rabat?.value2) result.push(Number(rabat?.value2))
        if (rabat?.value3) result.push(Number(rabat?.value3))
        if (rabat?.value4) result.push(Number(rabat?.value4))
    }
    return result;
}

async function getMedia(currentPosition: Position) {
    let result: Media[] = [];
    for (let i = 0; i < currentPosition.media.length; i++) {
        let media = currentPosition.media[i];
        let base64: string | undefined = undefined;

        // currentPosition.media.forEach(async (media: any) => {
        if (media.base64 === undefined || media.base64 === '') {
            console.debug('convert media because it is note present')
            if (Platform.OS !== 'web') {
                try {
                    base64 = await MediaUtils.readPhoneMedia(media.uri)
                    if (base64 !== undefined) {
                        MediaUtils.removeFileByUri(media.uri)
                    }
                } catch (error) {
                    console.warn('file has been deleted in the mean time', error)
                }
            }
        }

        result.push({ file_name: media.fileName, media_data: base64 ? base64 : '' })
    }
    return result;
}

function getPrice(currentPosition: Position, docType: number) {

    // TODO check other types. This problem is if vK documents do not have selprice but have buy price and the other way round. Check also getSecondPrice

    // Verkaufsdpockumente 
    if (docType === DocType.DDT || docType === DocType.DDT_TRANSFER) {
        if (currentPosition.hasSellPrice && currentPosition.hasBuyPrice)    // if both true set selPrice to price in position and buyPrice to buy_price. In all other cases price will be buyPrice or sellPrice dipendet on doxtype
            return ('selPrice' in currentPosition && currentPosition.selPrice) ? currentPosition.selPrice : undefined
        else if (currentPosition.hasSellPrice)
            return ('selPrice' in currentPosition && currentPosition.selPrice) ? currentPosition.selPrice : undefined
        else if (currentPosition.hasBuyPrice)
            return undefined
        else
            return undefined;
    }

    // Standaard
    else {
        if (currentPosition.hasSellPrice && currentPosition.hasBuyPrice)    // if both true set selPrice to price in position and buyPrice to buy_price. In all other cases price will be buyPrice or sellPrice dipendet on doxtype
            return ('selPrice' in currentPosition && currentPosition.selPrice) ? currentPosition.selPrice : undefined
        else if (currentPosition.hasSellPrice)
            return ('selPrice' in currentPosition && currentPosition.selPrice) ? currentPosition.selPrice : undefined
        else if (currentPosition.hasBuyPrice)
            return ('buyPrice' in currentPosition && currentPosition.buyPrice) ? currentPosition.buyPrice : undefined
        else
            return undefined;
    }
}

function getSecondPrice(currentPosition: Position, docType: number) {
    if (docType === DocType.DDT || docType === DocType.DDT_TRANSFER) {
        if (currentPosition.hasSellPrice && currentPosition.hasBuyPrice)    // if both true set selPrice to price in position and buyPrice to buy_price. In all other cases price will be buyPrice or sellPrice dipendet on doxtype
            return ('buyPrice' in currentPosition && currentPosition.selPrice) ? currentPosition.buyPrice : undefined
        else if (currentPosition.hasSellPrice)
            return undefined
        else if (currentPosition.hasBuyPrice)
            return ('buyPrice' in currentPosition && currentPosition.buyPrice) ? currentPosition.buyPrice : undefined
        else
            return undefined;
    }

    else if (docType === DocType.OF)
        //  same as buyPrice, only difference is that ordinefornitore as second price has sale_price instead of buy_price 
        return (currentPosition.hasSellPrice && currentPosition.hasBuyPrice && 'buyPrice' in currentPosition && currentPosition.buyPrice) ? currentPosition.buyPrice : undefined
    else
        //  calculate buyPrice - rabat because there is no way to pass rabat of buy to EDI, at the moment not implemented
        return (docType !== 4 && currentPosition.hasSellPrice && currentPosition.hasBuyPrice && 'buyPrice' in currentPosition && currentPosition.buyPrice) ? currentPosition.buyPrice : undefined
}

async function create(data: CreateDocument, task: ErgoTask, document: StoredNewDocument, isSecondDocment: boolean = false, id: number): Promise<any> {
    // return new Promise<any>(async (resolve, reject) => {

    const providerName = DoctypeUtils.getProviderName(data.docType);

    const docType = Math.abs(data.docType);

    let totalFileSize = 0;

    var requestPositions: DocumentPosition[] = [];

    //if DDT trasferimento create two documents second with DELNOTE_TRANSFER_OBJECTS_SERIALNR and first with SERIALNR
    var serialNr: number = isSecondDocment ? Number(task.param_list.DELNOTE_TRANSFER_OBJECTS_SERIALNR) : Number(task.param_list.SERIALNR);

    var objectId: number | undefined = (isSecondDocment && ('objectUnload' in data.header)) ? Number(data.header.objectUnload.object_id) : (data.header.object ? data.header.object.object_id : undefined);
    var objectLotId: number = (isSecondDocment && ('objectLotUnload' in data.header)) ?
        (data.header.objectLotUnload ? Number(data.header.objectLotUnload?.object_lot_id) : -1) :
        (('objectLot' in data.header) && data.header.objectLot ? data.header.objectLot.object_lot_id : -1);

    for (var i = 0; i < data.positions.length; i++) {
        const currentPosition = data.positions[i];

        let media = await getMedia(currentPosition)
        for (let i = 0; i < currentPosition.media.length; i++) {
            let media = currentPosition.media[i];
            if (media.fileSize)
                totalFileSize += media.fileSize;

        }

        //@ts-ignore
        var position: DocumentPosition = {
            doc_type: Math.abs(data.docType),
            serial_year: getYear(),
            serail_nr: serialNr,

            object_id: objectId,
            object_lot_id: objectLotId,

            product_id: currentPosition.articleId,
            product_description: currentPosition.title ? currentPosition.title : '',
            product_long_text: currentPosition.description ? currentPosition.description : '',


            quantity: currentPosition.count,

            price: getPrice(currentPosition, docType),
            buy_price: getSecondPrice(currentPosition, docType),
            sale_price: getSecondPrice(currentPosition, docType),

            discount: getRabats(currentPosition),

            media_list: media
            // custom_id: [],
            // custom_str: [],
            // pack_quantity: 0,
            // package_type_id: 0,
            // vat_id: 0,
            // cost_center_id: 0,
            // is_gift: false,
            // due_date: undefined,
        }
        requestPositions.push(position);
    }

    let totalFileSizeInMega = totalFileSize / 1000000
    // console.log('totalFileSize', totalFileSize)
    if (totalFileSizeInMega > MAX_MEDIA_SIZE) {
        console.error('File size of all media is to big')
        Alert.alert(
            'close',
            'File size of all media is to big, delete some images and retry',
            [
                { text: 'cancel', onPress: () => console.debug("Cancel Pressed"), style: "cancel" },
                {
                    text: "OK",
                    onPress: () => {

                    }
                }
            ]
        );
        return new Promise<any>(async (resolve, reject) => {
            reject('File size of all media is to big');
        });
    }

    console.debug('EmployeeId that creates the document = ', LicenseGlobals.employeeID)

    //@ts-ignore
    var request: DocumentPost = {
        mandant_id: LicenseGlobals.mandantId,
        doc_type: docType,
        serial_year: getYear(),
        serial_nr: serialNr,
        doc_date: new Date(),
        customer_id: data.header.client.id,
        destination_id: ('destination' in data.header) && data.header.destination ? data.header.destination.destination_id : -1,
        due_date: ('deliveryDate' in data.header) && data.header.deliveryDate ? data.header.deliveryDate : undefined,
        description: data.header.note,
        ext_refer2_doc: ('docNrSupplier' in data.header && data.header.docNrSupplier) ? data.header.docNrSupplier : undefined,
        ext_refer2_doc_date: ('docDateSupplier' in data.header && data.header.docDateSupplier) ? data.header.docDateSupplier : undefined,
        transport_reason_id: ('transportReason' in data.header) && data.header.transportReason ? data.header.transportReason.id : -1,
        transport_mode_id: ('transportMode' in data.header) && data.header.transportMode ? data.header.transportMode.id : -1,
        order_status_id: 0,
        collector_id: LicenseGlobals.employeeID !== -1 ? LicenseGlobals.employeeID : 0,
        freighter_id: ('freight' in data.header) && data.header.freight ? data.header.freight.id : 0,
        object_id: objectId,
        object_lot_id: objectLotId,
        position: requestPositions,
        signature: data.header.signature ? data.header.signature : '',
        contact_id: -1,   //  what send here not needed for this app 
        // currency_id: 0,
        // pay_mode_id: 0,
        // online_payment_transaction: '',
        // custom_id: [],
        // custom_str: [], 
        // sales_man_id: [],
        // discount: 0,
        // transport_cost: 0,
        // note: data.header.note,
    }

    // values of destination objectid and lot are passed throug refer2Destinatioon
    const TRANSFER_WAREHOUSE = Number(task.param_list.TRANSFER_WAREHOUSE) === 1
    if (TRANSFER_WAREHOUSE && data.docType === -6) {
        request.refer2_destination = {
            object_id: ('objectUnload' in data.header) ? Number(data.header.objectUnload.object_id) : 0,
            object_lot_id: ('objectLotUnload' in data.header) ? Number(data.header.objectLotUnload?.object_lot_id) : 0
        }
    }


    console.debug('POST:', JSON.stringify(request))

    return new Promise<any>(async (resolve, reject) => {
        useApi.createDocument(providerName, request)
            .then((resId: string) => {
                console.debug('POST: result', resId);
                console.debug('save', task.task_id, resId, request, id)
                CreateDocumentStorage.save(task.task_id, resId, request, id)
                    .then(() => {
                        if (resId !== '') {
                            console.log('id', id)
                            NewDocumentStorage.remove(id, task.task_id, document)
                            resolve(resId);
                        } else {
                            NewDocumentStorage.setErrorState(id, task.task_id, document, 'Unable to send data to server')
                            reject();
                        }
                    })
                    .catch((error) => {
                        console.error('CreateDocumentStorage: error', error);
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error('POST: error', error);
                NewDocumentStorage.setErrorState(id, task.task_id, document, error)

                // TODO set state of CreateDocumentStorage doc in not send
                reject(error);
            });
    })
}

export default {

    createDocument: (data: CreateDocument, task: ErgoTask, document: StoredNewDocument, id: number): Promise<any> => {
        const docType = Number(task.param_list.DOCTYPE);
        //if DDT trasferimento create two documents second with DELNOTE_TRANSFER_OBJECTS_SERIALNR and first with SERIALNR
        //also check TRANSFER_WAREHOUSE this will decide if 2 ddt are created from the app or the automation in ergo for documents.
        const TRANSFER_WAREHOUSE = Number(task.param_list.TRANSFER_WAREHOUSE) === 1
        if (docType === -6 && !TRANSFER_WAREHOUSE) {
            return new Promise<any>((resolve, reject) => {
                create(data, task, document, false, id).then((result: any) => {
                    // if first document is created create second document
                    create(data, task, document, true, id).then((innerResult: any) => {
                        resolve(innerResult)
                    }).catch(reject)
                }).catch(reject)
            })
        } else
            return new Promise<any>((resolve, reject) => {
                create(data, task, document, false, id)
                    .then((result: any) => { resolve(result) })
                    .catch(reject)
            });
    }
}