import * as React from 'react'
import { Colors, Icon, LanguageContext, ThemeContext, View } from "@infominds/react-native-components";
import { TouchableOpacity, StyleSheet } from 'react-native';
import NewSearchList from '../../../components/NewSearchList'
import ArticleDetailModal from '../ArticleDetailModal'
import { useCallback, useState } from 'react';
import { ErgoTask } from '../../../types';
import AddMaterialCardV3 from '../../../cards/AddMaterialCardV3';


export default function ArticlesList(props: {
    data: any,
    initialQuantity: any,
    onValueChange: any,
    searchFunction?: any,
    onSearchTextChanged?: any,
    onFinishSearchTextInput?: any,
    initialSearchText?: string,
    saveSearchText?: any,
    ergoTask: ErgoTask,
    onBarcodePressed: any,
    performnanceBoost?: boolean,
    doNotLoadUntilSearchTextHasValue: boolean
}) {
    const lang = React.useContext(LanguageContext);
    const colorScheme = React.useContext(ThemeContext);
    const theme = Colors[colorScheme];

    let [internalSearchText, setInternalSearchText] = useState(props.initialSearchText);

    const [selectedArticleDetail, setSelectedArticleDetail] = useState(undefined)

    const renderHiddenItem = useCallback(({ item }: { item: any }) => (
        <View style={styles.rowBack}>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnLeft]}
                onPress={() => {
                    if (props.onValueChange) props.onValueChange(item, 0);
                    setSelectedArticleDetail(item)
                }}>
                <Icon size={28} style={styles.backTextWhite} name="trash" />
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.backRightBtn, styles.backRightBtnRight]}
                onPress={() => {
                    setSelectedArticleDetail(item)
                }}>
                <Icon size={28} style={styles.backTextWhite} name="info" />
            </TouchableOpacity>
        </View>
    ), []);

    const onValueChange = useCallback((item: any, newValue: number) => {
        if (props.onValueChange) props.onValueChange(item, newValue);
    }, [])

    const renderItem = useCallback(({ item, index }: any) => {
        return (
            <AddMaterialCardV3
                item={item}
                performanceBoost={props.performnanceBoost}
                // index={index}
                // isSelected={selectedArticleDetail. }
                initialQuantity={props?.initialQuantity ? props?.initialQuantity(item) : 0}
                onValueChange={onValueChange} />)
    }, []);

    const renderFooter = (<View style={{ height: 20 }}></View>)

    const getKey = useCallback((item: any, index: number) => item.article_id ? item.article_id : index.toString(), [])

    // console.log('list render')

    if (selectedArticleDetail !== undefined) {
        return (
            <ArticleDetailModal
                // articleId={selectedArticleDetail.article_id}
                article={selectedArticleDetail}
                ergoTask={props.ergoTask}
                isVisible={selectedArticleDetail !== undefined}
                close={() => { setSelectedArticleDetail(undefined); props?.saveSearchText(internalSearchText) }} />
        )
    }

    return (
        <NewSearchList
            style={{ padding: 10, backgroundColor: theme.backgroundModal }}
            onSearchTextChanged={(text: string) => { props.onSearchTextChanged && props.onSearchTextChanged(text); setInternalSearchText(text); }}
            onFinishSearchTextInput={(text: string) => { props.onSearchTextChanged && props.onSearchTextChanged(text); setInternalSearchText(text) }}
            initialSearchText={props.initialSearchText}
            searchFunction={props.searchFunction}
            doNotLoadUntilSearchTextHasValue={props.doNotLoadUntilSearchTextHasValue}
            searchBarOverlay={false}
            searchLabel={lang.SEARCH}
            headerStyle={{ backgroundColor: Colors.grey }}
            data={props.data}
            hasBarcodeScan
            onBarcodePress={() => { props.onBarcodePressed() }}
            searchString={item => item.description}
            renderItem={renderItem}
            footer={renderFooter}
            renderHiddenItem={renderHiddenItem}
            keyExtractor={getKey}
            //@ts-ignore 
            leftOpenValue={0}
            disableRightSwipe={true}
            rightOpenValue={-150}
        />
    )
}


const styles = StyleSheet.create({
    rowBack: {
        marginTop: 5,
        marginBottom: 5,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Colors.modern.red,
        marginRight: 6,
        marginLeft: 30,
        borderRadius: 12,
    },
    backTextWhite: {
        color: Colors.white
    },
    backRightBtn: {
        alignItems: 'center',
        bottom: 0,
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        width: 75,
        height: '100%',
    },
    backRightBtnLeft: {
        backgroundColor: Colors.modern.red,
        right: 75,
    },
    backRightBtnRight: {
        backgroundColor: Colors.grey,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        right: 0,
    }
});