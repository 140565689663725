import * as React from 'react'
import { Colors, View, Text, CardBasic } from "@infominds/react-native-components";
import { TouchableOpacity } from 'react-native';
import { ArticleHistory } from '../types';

export default function ArchiveCard(props: {
    title: string,
    description?: string,
    description2?: string,
    style?: any,
    color?: string,
    item: ArticleHistory,
    onPress?: (item: ArticleHistory) => void,
    isSelected?: boolean,
    sum: number
}) {

    function formatQuantity(value: number) {
        let tmp = value.toFixed(2).replace('.', ',');
        if (tmp.endsWith(',00'))
            return value.toString();
        else return tmp;
    }

    return (
        <CardBasic style={[{
            flex: 1,
            backgroundColor: props.color,
            padding: 0,
            margin: props.isSelected ? 3 : 5,
            elevation: 3
        }, props.isSelected && { borderColor: Colors.grey, borderWidth: 2 }]}>
            <TouchableOpacity
                style={[{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: props.color,
                    borderRadius: 10,
                }]}
                onPress={() => { if (props.onPress) props.onPress(props.item); }} >
                <View style={{ flex: 1, padding: 12 }}>
                    {!!props.title && <Text style={{ flexWrap: 'wrap' }}>{props.title}</Text>}
                    {!!props.description && <Text style={{ marginBottom: 0, paddingBottom: 0, flexWrap: 'wrap' }}>{props.description}</Text>}
                    {!!props.description2 && <Text style={{ marginTop: 0, paddingTop: 0, flexWrap: 'wrap' }}>{props.description2}</Text>}
                </View>

                <View style={{ justifyContent: 'flex-end' }}>
                    <View style={{ marginRight: 5 }}>
                        <Text>{formatQuantity(props.sum)} {props.item?.measure_unit?.description}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        </CardBasic >
    )
}
