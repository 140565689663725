
async function saveMedia(uri: string, type: 'photo' | 'video', album: string) {
    // TODO implement 
    return true
};


const MediaUtils = {
    saveToPhoneMedia: (uri: string, type: 'photo' | 'video', album: string = '') => {
        return saveMedia(uri, type, album)
    },
    removePhoneMedia: (uri: string) => {
        // TODO implement 
        return true
    },
    readMedia: (uri: string) => {
        // TODO implement 
        return true;
    }
}
export default MediaUtils;