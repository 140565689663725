import * as React from 'react'
import { Colors, Text } from "@infominds/react-native-components";
import { View } from 'react-native';

export default function ErrorMessage(props: { errorMessage?: string }) {

    return (
        <View>
            {props.errorMessage &&
                <View style={{ backgroundColor: Colors.modern.red, borderRadius: 8, margin: 5, padding: 10 }}>
                    <Text color={Colors.white}>{props.errorMessage}</Text>
                </View>
            }
        </View>
    )
}