import * as React from 'react'
import { Colors, Input, View } from "@infominds/react-native-components";
import { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import IMIcon from './IMIcon';

type GrowingInputValues = {
    value1?: string;
    value2?: string;
    value3?: string;
    value4?: string;
}

const INPUT_WIDTH = 60;

export type { GrowingInputValues };

export default function GrowingInput(props: { value: GrowingInputValues, onValueChange: (result: GrowingInputValues) => void, editable?: boolean }) {

    const [value1, setValue1] = useState<string | undefined>(props?.value?.value1);
    const [value2, setValue2] = useState<string | undefined>(props?.value?.value2);
    const [value3, setValue3] = useState<string | undefined>(props?.value?.value3);
    const [value4, setValue4] = useState<string | undefined>(props?.value?.value4);

    const [numberOfInputs, setNumberOfInputs] = useState(1);

    useEffect(() => {
        if (props.value) {
            setValue1(props.value.value1)
            setValue2(props.value.value2)
            setValue3(props.value.value3)
            setValue4(props.value.value4)
        }
        if (props.value?.value4) setNumberOfInputs(4)
        else if (props.value?.value3) setNumberOfInputs(3)
        else if (props.value?.value2) setNumberOfInputs(2)
        else if (props.value?.value1) setNumberOfInputs(1)
    }, [props.value])

    useEffect(() => {
        props.onValueChange({ value1: value1, value2: value2, value3: value3, value4: value4 })
    }, [value1, value2, value3, value4])

    return (
        <View style={{ flex: 1, flexDirection: 'row' }}>
            {numberOfInputs >= 1 &&
                <>
                    <Input editable={props.editable} keyboardType={'number-pad'} style={{ width: INPUT_WIDTH }} value={value1} onChangeText={setValue1} />
                    {/* <Text style={{ marginTop: 10, textAlign: 'center', justifyContent: 'center', fontSize: 20 }}>%</Text> */}
                </>
            }
            {
                numberOfInputs >= 2 &&
                <Input editable={props.editable} keyboardType={'number-pad'} style={{ width: INPUT_WIDTH }} value={value2} onChangeText={setValue2} />
            }
            {
                numberOfInputs >= 3 &&
                <Input editable={props.editable} keyboardType={'number-pad'} style={{ width: INPUT_WIDTH }} value={value3} onChangeText={setValue3} />
            }
            {
                numberOfInputs >= 4 &&
                <Input editable={props.editable} keyboardType={'number-pad'} style={{ width: INPUT_WIDTH }} value={value4} onChangeText={setValue4} />
            }
            <TouchableOpacity style={{ justifyContent: 'center', backgroundColor: Colors.grey, padding: 10, margin: 5, borderRadius: 8 }}
                disabled={!props.editable}
                onPress={() => {
                    if (numberOfInputs <= 4)
                        setNumberOfInputs(numberOfInputs + 1)
                }}>
                <IMIcon color={Colors.white} icon={'plus'}></IMIcon>
            </TouchableOpacity>
        </View >
    )
}
