import * as React from 'react'
import { Colors, ThemeContext, View, Text, Icon, CardBasic } from "@infominds/react-native-components";
import { useContext } from "react";
import { TouchableOpacity, StyleSheet, Image } from 'react-native';
import IMIcon from '../components/IMIcon';

export default function MediaCard(props: {
    title: string,
    description?: string,
    quantity: string,
    media: any[],

    onSubmit: () => void,
    onDelete: () => void,
    deletemode: boolean,

    style?: any
}) {
    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    const thumbnail_size = 80;

    return (
        <TouchableOpacity onPress={() => { if (!props.deletemode) props.onSubmit() }}>
            <CardBasic style={{ paddingVertical: 0, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 10 }}>
                <View style={{ margin: 5, maxWidth: '75%', minHeight: 50 }}>
                    {!!props.title && <Text style={{ fontWeight: 'bold' }}>{props.title}</Text>}
                    {!!props.description && <Text>{props.description}</Text>}
                    <View style={{ marginVertical: 10, flexDirection: 'row' }}>
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }} >
                            {props.media && props.media.map((item: any) => (
                                <>
                                    {item.type.includes('image') &&
                                        // <ImageTumb item={item} />
                                        <Image
                                            style={{ width: thumbnail_size, height: thumbnail_size, marginBottom: 10, borderRadius: 8, marginRight: 10 }}
                                            // source={item?.base64 ? { uri: 'data:image/jpg;base64,' + item?.base64 } : { uri: item.uri }} />
                                            // source={item?.base64 ? { uri: 'data:image/jpg;base64,' + item?.base64 } : { uri: item.uri }} />
                                            source={{ uri: item.uri }} />
                                    }
                                    {item.type.includes('video') &&
                                        <View style={{ width: thumbnail_size, height: thumbnail_size, marginBottom: 10, backgroundColor: Colors.tint, justifyContent: 'center', marginRight: 10, borderRadius: 8 }} >
                                            <View style={{ alignItems: 'center', }}>
                                                <IMIcon size={50} icon={'play'} />
                                            </View>
                                        </View>
                                    }
                                    {item.type.includes('audio') &&
                                        <View style={{ width: thumbnail_size, height: thumbnail_size, marginBottom: 10, backgroundColor: Colors.tint, justifyContent: 'center', marginRight: 10, borderRadius: 8 }} >
                                            <View style={{ alignItems: 'center' }}>
                                                <IMIcon size={50} icon={'fileAudio'} />
                                            </View>
                                        </View>
                                    }
                                </>
                            ))}
                        </View>
                    </View>
                </View>

                {props.deletemode ?
                    <TouchableOpacity onPress={props.onDelete} style={styles.deletebutton}>
                        <Icon size={30} name={'trash'} color={theme.background} />
                    </TouchableOpacity>
                    :
                    <Text style={{ alignSelf: 'center', textAlign: 'right', fontWeight: 'bold', fontSize: 25, flex: 2 }}>{props.quantity !== "0" ? props?.quantity?.toString().replace('.', ',') : ''}</Text>
                }
            </CardBasic>
        </TouchableOpacity >
    )
}

const styles = StyleSheet.create({
    deletebutton: {
        backgroundColor: Colors.modern.red,
        margin: -1,
        marginRight: -13,
        width: 70,
        borderTopEndRadius: 10,
        borderBottomEndRadius: 10,
        justifyContent: 'center',
        alignItems: 'center'
    }
});
