
import * as React from 'react'
import { useContext, useEffect, useState } from "react";
import { Colors, LoadingSpinnerModal, SearchBox, ThemeContext, View } from '@infominds/react-native-components';
import SearchBoxBarcode from './SearchBoxBarcode'
import { FlatList, TouchableOpacity, Text, Platform, NativeSyntheticEvent, TextInputEndEditingEventData, RefreshControl } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import SearchBoxDebounced from './SearchBoxDebounced';

NewSearchList.defaultProps = {
    searchBarOverlay: true,
    hasBarcodeScan: false
}

export default function NewSearchList(props: {
    data: any,
    renderItem: ({ item, index }: any) => JSX.Element | any,
    searchString?: (item: any) => string,
    style?: any,
    scrollEnabled?: boolean,
    searchLabel?: string,
    searchBarOverlay?: boolean,
    headerStyle?: any,
    footer?: JSX.Element,
    customerHeader?: JSX.Element,
    renderHiddenItem: any,
    refreshing?: boolean,
    refreshControl?: JSX.Element,
    hasBarcodeScan: boolean,
    onBarcodePress?: any,
    onSearchTextChanged?: any,
    onFinishSearchTextInput?: any,
    searchFunction?: any,
    keyExtractor: any,
    initialSearchText?: string
    doNotLoadUntilSearchTextHasValue: boolean
}) {

    const colorScheme = useContext(ThemeContext);
    const theme = Colors[colorScheme];

    let [searchtext, setSearchText] = useState<string>('');
    const [loading, setLoading] = useState(true);

    const { data, keyExtractor, ...otherProps } = props;

    const [dataToShow, setDataToShow] = useState(props.data)

    useEffect(() => { search() }, [searchtext, props.data])

    useEffect(() => { props.initialSearchText && setSearchText(props.initialSearchText) }, [props.initialSearchText])

    async function search() {
        setLoading(true);
        searchFilterFunction();
    }

    async function searchFilterFunction() {

        if (searchtext === '') {
            setDataToShow(props.data);
            setLoading(false)
        } else if (props.searchFunction) {
            // FR decide to not show any data until inserted a search text
            // console.log('searchtext', searchtext)
            if (!props.doNotLoadUntilSearchTextHasValue || searchtext !== '')
                props.searchFunction(searchtext).then((res: any) => setDataToShow(res)).finally(() => setLoading(false));
        } else {
            const newData = props.data.filter((item: any) => {
                const itemData = props.searchString ? props.searchString(item).toUpperCase() : '';
                const textData = searchtext.toUpperCase();
                return itemData.includes(textData);
            });
            setDataToShow(newData);
            setLoading(false)
        }
    }

    // const getKey = (item: any, index: any) => props.keyExtractor ? props.keyExtractor(item, index) : index.toString()
    // const getKey = (item: any, index: any) => index.toString()

    const onChangeText = (text: string) => {
        setSearchText(text);
        if (props.onSearchTextChanged)
            props.onSearchTextChanged(text)
    }

    const RefreshView = (
        <RefreshControl
            refreshing={loading}
            // onRefresh={() => reload()}
            colors={[Colors.tint]}
            tintColor={Colors.tint} />
    )

    return (
        <>
            <View style={[
                props.searchBarOverlay ? { backgroundColor: 'transparent' } : { backgroundColor: theme.backgroundModal },
                { paddingHorizontal: 10, paddingTop: 0, paddingBottom: 5 },
                props.headerStyle]}>
                {props.searchString &&
                    <>
                        {props.hasBarcodeScan ?
                            <SearchBoxBarcode style={{ marginBottom: 10 }}
                                placeholder={props?.searchLabel}
                                value={searchtext}
                                barcodeScannerVisible
                                onBarcodePress={props.onBarcodePress}
                                onChangeText={onChangeText}
                                onEndEditing={(e: NativeSyntheticEvent<TextInputEndEditingEventData>) => props.onFinishSearchTextInput(e.nativeEvent.text)}
                            />
                            :
                            <SearchBoxDebounced
                                style={{ marginBottom: 10 }}
                                placeholder={props?.searchLabel}
                                value={searchtext}
                                onChangeText={onChangeText}
                                onEndEditing={(e: NativeSyntheticEvent<TextInputEndEditingEventData>) => props.onFinishSearchTextInput(e.nativeEvent.text)}
                            />}
                    </>}
            </View>

            {/* {(props.doNotLoadUntilSearchTextHasValue && searchtext === '') &&
                <View style={{ flex: 1, justifyContent: 'center' }}>
                    <Text style={{ color: theme.textDetail, textAlign: 'center' }}>Insert a text to start searching</Text>
                </View>
            } */}

            {Platform.OS === 'web' ?
                <FlatList
                    scrollEnabled={props.scrollEnabled}
                    // stickyHeaderIndices={[0]}
                    style={props.style}
                    refreshing={props.refreshing !== undefined ? props.refreshing : loading}
                    refreshControl={props.refreshControl}
                    data={dataToShow}
                    // getItemLayout={getItemLayout}

                    ListFooterComponent={props?.footer}
                    keyExtractor={props.keyExtractor}
                    // initialNumToRender={100}
                    // disableHiddenLayoutCalculation
                    // removeClippedSubviews
                    {...otherProps}

                    //performance props
                    initialNumToRender={10}
                    maxToRenderPerBatch={10}
                    windowSize={10}
                // getItemLayout={getItemLayout}
                />
                :
                // <FlashList
                //     scrollEnabled={props.scrollEnabled}
                //     style={props.style}
                //     refreshing={props.refreshing}
                //     refreshControl={props.refreshControl}
                //     data={dataToShow}
                //     ListFooterComponent={props?.footer}
                //     keyExtractor={props.keyExtractor}

                //     {...otherProps}
                // />
                <SwipeListView
                    scrollEnabled={props.scrollEnabled}
                    style={props.style}
                    refreshing={props.refreshing !== undefined ? props.refreshing : loading}
                    refreshControl={props.refreshControl !== undefined ? props.refreshControl : RefreshView}
                    data={dataToShow}
                    ListFooterComponent={props?.footer}
                    keyExtractor={props.keyExtractor}
                    recalculateHiddenLayout={true}

                    // Performance settings  
                    removeClippedSubviews={false} // Unmount components when outside of window 
                    initialNumToRender={8} // Reduce initial render amount
                    maxToRenderPerBatch={8} // Reduce number in each render batch
                    updateCellsBatchingPeriod={100} // Increase time between renders
                    windowSize={7} // Reduce the window size

                    {...otherProps}
                />
            }
            {/* <LargeList
                // style={styles.container}
                data={data}
                // heightForSection={() => 50}
                // renderSection={this._renderSection}
                heightForIndexPath={() => 50}
                renderIndexPath={({ section: section, row: row }) => {
                    return (
                        <TouchableOpacity >
                            <Text>
                                Section {section} Row {row}
                            </Text>
                            <View />
                        </TouchableOpacity>
                    );
                }}
            /> */}


            {/* <FlatList
                scrollEnabled={props.scrollEnabled}
                // stickyHeaderIndices={[0]}
                style={props.style}
                refreshing={props.refreshing}
                refreshControl={props.refreshControl}
                data={dataToShow}
                // getItemLayout={getItemLayout}

                ListFooterComponent={props?.footer}
                keyExtractor={props.keyExtractor}
                // initialNumToRender={100}
                // disableHiddenLayoutCalculation
                // removeClippedSubviews
                {...otherProps}

                //performance props
                initialNumToRender={10}
                maxToRenderPerBatch={10}
                windowSize={10}
            // getItemLayout={getItemLayout}
            /> */}

            {/* <LoadingSpinnerModal visible={loading}></LoadingSpinnerModal> */}

        </>
    )
}